import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleWares = [thunk];
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleWares), sentryReduxEnhancer));

export default store;
