import { useMemo, useState, ReactElement } from 'react';
import FilterModal from './FilterModal';
import PendingPinsModal from './PendingPinsModal';

import { isMobileDevice } from '../../helpers/mobileDetection';
import {
  Grid,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Button,
  Menu,
  Fade,
  Badge,
  Typography,
  Box,
  Link,
  useTheme
} from '@mui/material';
import { BillingState } from 'store/billing/types';
import { MetricsState } from 'store/metrics/types';
import { validateMetricsLimits } from '../../helpers/validations';
import { Add, HiveOutlined, FolderOpenOutlined, InsertDriveFileOutlined, FilterAltOutlined } from '@mui/icons-material';
import { getBorderedBoxStyles, neutral } from '../../themes/colors';

const isSupportsWebKitDirectory = (): boolean => {
  const userAgentString = navigator.userAgent;
  let chromeAgent = userAgentString.indexOf('Chrome') > -1;
  let IExplorerAgent = userAgentString.indexOf('MSIE') > -1;
  let safariAgent = userAgentString.indexOf('Safari') > -1;
  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }

  if (IExplorerAgent || safariAgent) {
    return false;
  } else {
    return true;
  }
};

interface TableNavProps {
  selectedPinStatus: string;
  setSelectedPinStatus: (status: 'pinned' | 'unpinned' | 'all') => void;
  setFileUploadModalOpen: (open: boolean) => void;
  setFolderUploadModalOpen: (open: boolean) => void;
  submitTableFilters: () => void;
  setHashContains: (hashContains: string) => void;
  hashContains: string;
  nameContains: string;
  setNameContains: (nameContains: string) => void;
  pinStartDate: Date | undefined;
  pinEndDate: Date | undefined;
  unpinStartDate: Date | undefined;
  unpinEndDate: Date | undefined;
  setPinStartDate: (pinStartDate: Date) => void;
  setPinEndDate: (pinEndDate: Date) => void;
  setUnpinStartDate: (unpinStartDate: Date) => void;
  setUnpinEndDate: (unpinEndDate: Date) => void;
  clearFilters: () => void;
  setPinByHashOpen: (open: boolean) => void;
  pinJobs: any;
  setAlert: (message: string | ReactElement, type: string) => void;
  gateways: any;
  handleGatewayToUse: (event: any) => void;
  gatewayToUse: string;
  getGatewayValue: (gateway: string) => string;
  submarined: boolean;
  setSubmarined: (submarined: boolean) => void;
  folderDetails: any;
  metrics: MetricsState;
  billing: BillingState;
  setCurrentPage: Function;
}

const TableNav = ({
  selectedPinStatus,
  setSelectedPinStatus,
  setFileUploadModalOpen,
  setFolderUploadModalOpen,
  submitTableFilters,
  setHashContains,
  hashContains,
  nameContains,
  setNameContains,
  pinStartDate,
  pinEndDate,
  unpinStartDate,
  unpinEndDate,
  setPinStartDate,
  setPinEndDate,
  setUnpinStartDate,
  setUnpinEndDate,
  clearFilters,
  setPinByHashOpen,
  pinJobs,
  setAlert,
  gateways,
  handleGatewayToUse,
  gatewayToUse,
  getGatewayValue,
  submarined,
  setSubmarined,
  folderDetails,
  metrics,
  setCurrentPage,
  billing
}: TableNavProps) => {
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [pinJobsModalOpen, setPinJobsModalOpen] = useState<boolean>(false);

  const [uploadModalAnchorEl, setUploadModalAnchorEl] = useState<any>(null);
  const isUploadModalOpen = Boolean(uploadModalAnchorEl);

  const supportsWebKitDirectory = useMemo(() => isSupportsWebKitDirectory(), []);
  const isMobile = useMemo(() => isMobileDevice(), []);
  const theme = useTheme();

  const handlePinStatusChange = (e: any): void => {
    setCurrentPage(0);
    setSelectedPinStatus(e.target.value);
  };

  const updateFilter = (): void => {
    setFiltersApplied(true);
    setFilterModalOpen(false);
    submitTableFilters();
  };

  const clearAppliedFilters = (): void => {
    setFiltersApplied(false);
    clearFilters();
    setSelectedPinStatus('pinned');
  };

  const isAnyMetricIsInvalid = useMemo(() => validateMetricsLimits(metrics?.metrics, billing), [metrics?.metrics]);

  const showInvalidMetricAlert = () => {
    setAlert(
      <span>
        This action was blocked due to plan limitations. <a href="/billing">See plan options</a>
      </span>,
      'error'
    );
  };

  const handleFolderUploadModal = () => {
    setUploadModalAnchorEl(null);
    if (isAnyMetricIsInvalid) {
      showInvalidMetricAlert();
      return;
    }
    if (supportsWebKitDirectory) {
      setFolderUploadModalOpen(true);
    } else {
      setAlert("Your browser doesn't support directory uploads", 'error');
    }
  };

  const handleFileUploadModal = () => {
    setUploadModalAnchorEl(null);
    if (isAnyMetricIsInvalid) {
      showInvalidMetricAlert();
      return;
    }
    setFileUploadModalOpen(true);
  };

  const handlePinModal = () => {
    setUploadModalAnchorEl(null);
    if (isAnyMetricIsInvalid) {
      showInvalidMetricAlert();
      return;
    }
    setPinByHashOpen(true);
  };

  const backOutOfFolder = () => {
    clearFilters();
    setSelectedPinStatus('pinned');
  };

  const renderGatewayOptions = useMemo(() => {
    if (gateways?.gateways?.rows?.length) {
      return gateways.gateways.rows.map((g: any, index: number) => {
        return (
          <MenuItem key={`g-${index}`} value={getGatewayValue(g)}>
            {getGatewayValue(g)}
          </MenuItem>
        );
      });
    }
  }, [gateways?.gateways?.rows?.length]);

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <Typography variant="h3">Files</Typography>
            {!folderDetails && pinJobs?.length > 0 && (
              <Badge
                className="d-block"
                badgeContent={pinJobs.length}
                color="primary"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setPinJobsModalOpen(true)}
                  color="info"
                  size={'small'}
                >
                  Pin By CID Queue
                </Button>
              </Badge>
            )}
          </Box>
          {folderDetails && (
            <Link onClick={backOutOfFolder} href="#">
              <i className="fas fa-arrow-left" />
              {`Files/${folderDetails?.name}`}
            </Link>
          )}
        </Grid>
        {!folderDetails && (
          <Grid
            item
            xs={12}
            md={8}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              flexWrap: {
                xs: 'wrap'
              }
            }}
          >
            {
              !submarined && (
                <FormControl
                  sx={{
                    width: {
                      xs: '100%',
                      md: '144px'
                    },
                    m: {
                      xs: theme.spacing(2, 0),
                      md: theme.spacing(0, 0.75)
                    }
                  }}
                >
                  <InputLabel id="pin-filter">Pin Status</InputLabel>
                  <Select
                    size="small"
                    labelId="pin-filter"
                    onChange={handlePinStatusChange}
                    value={selectedPinStatus}
                    label="Pin Status"
                    sx={{ backgroundColor: theme.palette.mode === 'light' ? neutral[50] : '' }}
                  >
                    <MenuItem value={'pinned'}>Pinned</MenuItem>
                    <MenuItem value={'unpinned'}>Unpinned</MenuItem>
                    <MenuItem value={'all'}>All</MenuItem>
                  </Select>
                </FormControl>
              )
            }
            {gateways?.gateways?.rows?.length > 0 && (
              <FormControl
                sx={{
                  width: {
                    xs: '100%',
                    md: '144px'
                  },
                  m: {
                    xs: theme.spacing(2, 0),
                    md: theme.spacing(0, 0.75)
                  }
                }}
              >
                <InputLabel id="gateway-filter">Gateway to Use</InputLabel>
                <Select
                  size="small"
                  labelId="gateway-filter"
                  onChange={handleGatewayToUse}
                  value={gatewayToUse}
                  label="Gateway to use"
                  sx={{ backgroundColor: theme.palette.mode === 'light' ? neutral[50] : '' }}
                >
                  {renderGatewayOptions}
                </Select>
              </FormControl>
            )}
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: 'auto' },
                m: { [theme.breakpoints.down('sm')]: theme.spacing(2, 0) }
              }}
            >
              {filtersApplied ? (
                <Button
                  sx={{
                    m: theme.spacing(0, 0.75)
                  }}
                  variant="outlined"
                  fullWidth
                  onClick={clearAppliedFilters}
                >
                  Clear Filters
                </Button>
              ) : (
                <Button
                  startIcon={<FilterAltOutlined sx={{ color: theme.palette.primary.main }} />}
                  variant="outlined"
                  onClick={() => setFilterModalOpen(true)}
                  sx={{
                    flexGrow: 1,
                    m: theme.spacing(0, 0.75),
                    ...getBorderedBoxStyles(theme)
                  }}
                  size={'small'}
                >
                  Filters
                </Button>
              )}
              <>
                <Button
                  sx={{
                    flexGrow: 1,
                    m: theme.spacing(0, 0.75)
                  }}
                  variant="contained"
                  color="primary"
                  onClick={(event) => setUploadModalAnchorEl(event.currentTarget)}
                  id="fade-button"
                  aria-controls={isUploadModalOpen ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={isUploadModalOpen ? 'true' : undefined}
                  startIcon={<Add />}
                  size={'small'}
                >
                  Add Files
                </Button>
                <Menu
                  id="fade-menu"
                  anchorEl={uploadModalAnchorEl}
                  open={isUploadModalOpen}
                  onClose={() => setUploadModalAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                  TransitionComponent={Fade}
                >
                  {!isMobile && (
                    <MenuItem onClick={handleFolderUploadModal}>
                      <FolderOpenOutlined sx={{ fontSize: 15, mr: 1 }} /> Folder
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleFileUploadModal}>
                    <InsertDriveFileOutlined sx={{ fontSize: 15, mr: 1 }} /> File
                  </MenuItem>
                  <MenuItem onClick={handlePinModal}>
                    <HiveOutlined sx={{ fontSize: 15, mr: 1 }} /> CID
                  </MenuItem>
                </Menu>
              </>
            </Box>
          </Grid>
        )}
      </Grid>
      {filterModalOpen && (
        <FilterModal
          pinStatus={selectedPinStatus}
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
          hashContains={hashContains}
          setHashContains={setHashContains}
          nameContains={nameContains}
          setNameContains={setNameContains}
          pinStartDate={pinStartDate}
          pinEndDate={pinEndDate}
          unpinStartDate={unpinStartDate}
          unpinEndDate={unpinEndDate}
          setUnpinStartDate={setUnpinStartDate}
          setUnpinEndDate={setUnpinEndDate}
          setPinStartDate={setPinStartDate}
          setPinEndDate={setPinEndDate}
          updateFilter={updateFilter}
          submarined={submarined}
          setSubmarined={setSubmarined}
          setAlert={setAlert}
        />
      )}
      {pinJobsModalOpen && (
        <PendingPinsModal
          pinJobs={pinJobs}
          pinJobsModalOpen={pinJobsModalOpen}
          setPinJobsModalOpen={setPinJobsModalOpen}
          setAlert={setAlert}
        />
      )}
    </Box>
  );
};

export default TableNav;
