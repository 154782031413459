import { useState, useEffect, useMemo } from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Button } from '@mui/material';
import type { GatewayRow } from '../../store/gateways/types';

interface GatewayVirtualListProps {
  gateways: GatewayRow[];
  loadGateways: (itemNum: number) => Promise<void>;
  totalGateways: number;
  gatewaysLoading: boolean;
  handleGatewaySelect: (gateway: GatewayRow) => Promise<void>;
}

export default function GatewayVirtualList({
  gateways,
  loadGateways,
  totalGateways,
  gatewaysLoading,
  handleGatewaySelect
}: GatewayVirtualListProps) {
  const [localGateways, setLocalGateways] = useState<any[]>([]);

  const isItemLoaded = (index: number) => !hasNextPage || index < localGateways.length;

  useEffect(() => {
    setLocalGateways([...localGateways, ...gateways]);
  }, [gateways]);

  const hasNextPage = useMemo(() => {
    return localGateways.length < totalGateways;
  }, [localGateways]);

  const itemCount = useMemo(() => {
    return hasNextPage ? localGateways.length + 1 : localGateways.length;
  }, [localGateways]);

  const loadMoreItems = (req: any): any => {
    return gatewaysLoading ? () => {} : loadGateways(req);
  };

  const handleGatewayClick = (gatewayIndex: number) => {
    handleGatewaySelect(localGateways[gatewayIndex]);
  };

  const Row = (props: { index: number; style: any }) => {
    const { index, style } = props;
    return (
      <div style={style}>
        {index < localGateways.length ? (
          <p className={'gateway-select-container'}>
            {localGateways[index].domain}
            <Button size="small" color="info" onClick={() => handleGatewayClick(index)} disabled={gatewaysLoading}>
              Select
            </Button>
          </p>
        ) : (
          'Loading...'
        )}
      </div>
    );
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <List
          width="100%"
          className="List"
          height={150}
          itemSize={50}
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Row}
        </List>
      )}
    </InfiniteLoader>
  );
}
