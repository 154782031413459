import { Box, Grid } from '@mui/material';
import {NoEncryptionOutlined} from '@mui/icons-material';

const NoContent = ({ title }: { title: string }) => (
  <Box
    sx={{
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}
  >
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <NoEncryptionOutlined sx={{ fontSize: '4rem' }} />
      </Grid>
      <Grid item xs={12}>
        No {title ?? 'Content'}
      </Grid>
    </Grid>
  </Box>
);
export default NoContent;
