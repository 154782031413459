import { Box, Button, Grid, Typography } from '@mui/material';
import { validateMetricsLimits } from '../../helpers/validations';
import type { BillingState } from '../../store/billing/types';
import type { Metrics } from '../../store/metrics/types';
import { Add } from '@mui/icons-material';
import { planTypes } from 'helpers/enums';

interface TableNavProps {
  setWizardModalOpen: (value: boolean) => void;
  metrics: Metrics;
  setAlert: (message: any, type: string) => void;
  billing: BillingState;
}

const TableNav = ({ setWizardModalOpen, metrics, setAlert, billing }: TableNavProps) => {
  const validateMetrics = () => {
    const validMetrics = validateMetricsLimits(metrics, billing);
    if (validMetrics) {
      setAlert(
        <span>
          This action was blocked due to plan limitations. <a href="/billing">See plan options</a>
        </span>,
        'error'
      );
      return;
    }
    setWizardModalOpen(true);
  };
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        onClick={() => validateMetrics()}
        id="gateways-create-item"
        color="primary"
        disabled={billing.activePricingPlan?.type === planTypes.NEW_FREE.type}
        startIcon={<Add />}
        size={"small"}
      >
        Create Gateway
      </Button>
    </Box>
  );
};

export default TableNav;
