import { useState } from 'react';
import {
  Card,
  CardContent,
  Tooltip,
  CardHeader,
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  Typography,
  Zoom,
  Menu,
  MenuItem,
  Grid
} from '@mui/material';
import {
  NoEncryption,
  MoreVert,
  VisibilityOutlined,
  VisibilityOffOutlined,
  ContentCopyOutlined
} from '@mui/icons-material';
import ConfirmationModal from '../../Gateway/ConfirmationModal';
import type { GatewayRow } from 'store/gateways/types';
import { makeDatePretty } from 'helpers/pretty';

interface AccessTokenCardProps {
  selectedGateway: GatewayRow;
  token: { id: string; value: string; createdAt: string; name: string };
  removeTokenAction: (id: string) => void;
}

function AccessTokenCard(props: AccessTokenCardProps) {
  const { selectedGateway, token, removeTokenAction } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [copyTooltipOpen, setCopyTooltipOpen] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState<boolean>(false);

  const isMenuOpen = Boolean(menuAnchor);

  function handleTokenRemove(id: string) {
    removeTokenAction(id);
    setIsRevokeModalOpen(false);
    console.log(`%c${id}, removed`, 'color: #8000DB');
  }

  return (
    <>
      <Card sx={{ minWidth: 300, m: 1, boxShadow: 'none' }}>
        <CardHeader
          sx={{ pt: 1, pb: 0 }}
          action={
            <>
              <IconButton
                onClick={(e) => {
                  setMenuAnchor(e.currentTarget);
                }}
                aria-label="settings"
              >
                <MoreVert fontSize="small" />
              </IconButton>
              {menuAnchor && (
                <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={() => setMenuAnchor(null)}>
                  <MenuItem
                    dense
                    onClick={() => {
                      setMenuAnchor(null);
                      setIsRevokeModalOpen(true);
                    }}
                  >
                    Revoke
                  </MenuItem>
                </Menu>
              )}
            </>
          }
        />
        <CardContent sx={{ '&:last-child': { pb: 0 }, display: 'flex', flexDirection: 'column', py: 0 }}>
          <Grid
            container
            sx={{
              '& > .MuiGrid-item': {
                pt: 0
              }
            }}
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid container sx={{ py: 0 }} item xs={8}>
              <Grid
                item
                justifyContent="center"
                alignItems="center"
                alignSelf="center"
                sx={{
                  mx: (theme) => theme.spacing(1),
                  backgroundColor: '#8000DB',
                  borderRadius: 0.25,
                  width: '34px',
                  height: '34px'
                }}
              >
                <NoEncryption sx={{ display: 'block', fontSize: '1rem', height: '100%', color: '#fff', m: '0 auto' }} />
              </Grid>
              <Grid item flexDirection="column">
                <Typography variant="body2" color="textSecondary" component="p" sx={{ m: 0 }}>
                  {makeDatePretty(token.createdAt)}
                </Typography>

                <Typography variant="body1" color="textSecondary" component="p" sx={{ m: 0 }}>
                  {token.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={4} sx={{ py: 0 }} item alignSelf="flex-end">
              <IconButton onClick={() => setIsVisible(!isVisible)} aria-label="check it">
                {isVisible ? (
                  <VisibilityOffOutlined color="primary" fontSize="small" />
                ) : (
                  <VisibilityOutlined color="primary" fontSize="small" />
                )}
              </IconButton>

              <ClickAwayListener onClickAway={() => setCopyTooltipOpen(false)}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={() => setCopyTooltipOpen(false)}
                  open={copyTooltipOpen}
                  TransitionComponent={Zoom}
                  title="Copied!"
                >
                  <IconButton
                    onMouseLeave={() => setCopyTooltipOpen(false)}
                    onClick={() => {
                      navigator.clipboard.writeText(token.value);
                      setCopyTooltipOpen(true);
                    }}
                    aria-label="copy"
                  >
                    <ContentCopyOutlined color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </Grid>
          </Grid>

          <FormControl
            sx={{
              mx: (theme) => theme.spacing(1)
            }}
          >
            <Input
              sx={{
                '&: before': {
                  display: 'none'
                },
                border: 'none'
              }}
              type={isVisible ? 'text' : 'password'}
              disabled
              value={token.value}
            />
          </FormControl>
        </CardContent>
      </Card>
      {isRevokeModalOpen && (
        <ConfirmationModal
          title="Revoke Access Token?"
          content={`Are you sure you want to revoke access token (${token.value}) for this gateway (${selectedGateway?.domain})?`}
          modalOpen={isRevokeModalOpen}
          toggleModal={setIsRevokeModalOpen}
          action={() => handleTokenRemove(token.id)}
          loading={false}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
    </>
  );
}

export default AccessTokenCard;
