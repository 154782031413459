import { makeDatePretty } from 'helpers/pretty';
import { useState } from 'react';
import DeleteKeyModal from './DeleteKeyModal';
import NewKeyModal from './NewKeyModal';
import {
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Button,
  Pagination,
  TableContainer,
  useTheme
} from '@mui/material';
import {AllInclusive, DeleteForeverOutlined} from '@mui/icons-material';
import {getBorderedBoxStyles} from "../../themes/colors";

interface KeyTableProps {
  keys: any;
  pageLimit: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalKeys: number;
  setAlert: any;
  revokeApiKey: any;
}

const KeyTable = ({ keys, pageLimit, totalKeys, setCurrentPage, revokeApiKey }: KeyTableProps) => {
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState(false);
  const [keyInfo, setKeyInfo] = useState<any>(null);
  const [keyInfoModalOpen, setKeyInfoModalOpen] = useState(false);
  const theme = useTheme();

  const handlePageChange = (pageToChangeTo: number) => {
    setCurrentPage(pageToChangeTo);
  };

  const openKeyInfoModal = (keyInfo: any) => {
    setKeyInfo(keyInfo);
    setKeyInfoModalOpen(true);
  };

  const closeKeyInfoModal = () => {
    setKeyInfo(null);
    setKeyInfoModalOpen(false);
  };

  const openDeleteModal = (keyInfo: any) => {
    setKeyInfo(keyInfo);
    setDeleteKeyModalOpen(true);
  };

  const confirmDeleteKey = () => {
    revokeApiKey(keyInfo.key);
    setKeyInfo(null);
    setDeleteKeyModalOpen(false);
  };

  const renderRow = (
    row: { name: string; key: any; createdAt: any; max_uses: number; scopes: any; id: any },
    key: any
  ) => {
    return (
      <TableRow key={key}>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.key}</TableCell>
        <TableCell>{makeDatePretty(row.createdAt)}</TableCell>
        <TableCell>{row.max_uses === 0 || !row.max_uses ? <AllInclusive color={'primary'} /> : row.max_uses}</TableCell>
        <TableCell>
          {row.scopes.admin ? (
            <span>Admin</span>
          ) : (
            <span>
              <span
                onClick={() => openKeyInfoModal(row)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                id={`toolTipTop_${row.id}`}
              >
                Custom
              </span>
            </span>
          )}
        </TableCell>
        <TableCell align={'right'} sx={{pr: 0}}>
          <Button
            onClick={() => openDeleteModal(row)}
            size={"small"}
            sx={{...getBorderedBoxStyles(theme)}}
            startIcon={<DeleteForeverOutlined sx={{color: theme.palette.error.main}} />}
            variant={"outlined"}
          >Revoke</Button>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell scope="col">Name</TableCell>
              <TableCell scope="col">Key</TableCell>
              <TableCell scope="col">Date Issued</TableCell>
              <TableCell scope="col">Max Uses</TableCell>
              <TableCell scope="col">Permissions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{keys.map((k: any, key: any) => renderRow(k, key))}</TableBody>
        </Table>

        {keyInfo && (
          <DeleteKeyModal
            keyName={keyInfo.name}
            setDeleteKeyModalOpen={setDeleteKeyModalOpen}
            deleteKeyModalOpen={deleteKeyModalOpen}
            confirmDeleteKey={confirmDeleteKey}
          />
        )}
        {setKeyInfoModalOpen && (
          <NewKeyModal
            setNewKeyModalOpen={setKeyInfoModalOpen}
            newKeyModalOpen={keyInfoModalOpen}
            existingKey={true}
            keyInfo={keyInfo}
          />
        )}
      </TableContainer>
      <Pagination
        count={Math.ceil(totalKeys / pageLimit)}
        onChange={(evt, pageNumber) => handlePageChange(pageNumber)}
        color={'primary'}
        sx={{ marginTop: 2 }}
      />
    </>
  );
};

export default KeyTable;
