import { useState, useEffect, useMemo, ChangeEvent, useRef } from 'react';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  Paper
} from '@mui/material';
import EditAccountDetails from './EditAccountDetails';
import ConfirmationModal from '../Gateway/ConfirmationModal';
import { planTypes } from '../../helpers/enums';
import { BillingState, Plan } from '../../store/billing/types';
import { User, UserState } from '../../store/user/types';
import { useAuth } from 'hooks/useAuth';
import useSignOut from 'hooks/useSignOut';
import { useAnalytics } from 'use-analytics';
import { ANALYTICS } from 'common/constants';

interface AccountManagementProps {
  user: UserState;
  setAlert: any;
  changeEmail: (newMail: string) => void;
  currentPlan: Plan | null;
  cancelUserAccount: () => void;
  restoreCanceledAccount: () => void;
  loadUserInfo: (user: User) => void;
  billing: BillingState;
}

const FeedbackContent = ({ cancellingAccount, feedbackFormObject, handleFormChange, setFeedbackFormObject }: any) => (
  <Box>
    {!cancellingAccount ? (
      <FormGroup>
        <FormControlLabel
          checked={feedbackFormObject.missing_feature}
          control={<Checkbox onChange={handleFormChange} name="missing_feature" />}
          label="Missing features I need"
        />
        <FormControlLabel
          checked={feedbackFormObject.switch_product}
          control={<Checkbox onChange={handleFormChange} name="switch_product" />}
          label="Switching to another product"
        />
        <FormControlLabel
          checked={feedbackFormObject.too_expensive}
          control={<Checkbox onChange={handleFormChange} name="too_expensive" />}
          label="Too expensive"
        />
        <FormControlLabel
          checked={feedbackFormObject.technical_issues}
          control={<Checkbox onChange={handleFormChange} name="technical_issues" />}
          label="Technical issues"
        />
        <FormControlLabel
          checked={feedbackFormObject.no_needed}
          control={<Checkbox onChange={handleFormChange} name="no_needed" />}
          label="No longer needed"
        />
        <FormControlLabel
          checked={feedbackFormObject.other_reason}
          control={<Checkbox onChange={handleFormChange} name="other_reason" />}
          label="Other"
        />
        <TextareaAutosize
          id="cancel-account-reason"
          minRows={3}
          placeholder="Other reason? Help us improve by telling us why you are cancelling your account. (at least 5 characters)"
          onChange={(e) => setFeedbackFormObject({ ...feedbackFormObject, reason: e.currentTarget.value })}
          maxLength={225}
          disabled={!feedbackFormObject.other_reason}
        />
      </FormGroup>
    ) : (
      <Box>
        <Typography>Thanks four your feedback!</Typography>
      </Box>
    )}
  </Box>
);

const initialFormObject = {
  missing_feature: false,
  switch_product: false,
  too_expensive: false,
  technical_issues: false,
  no_needed: false,
  other_reason: false,
  reason: ''
};

const AccountManagementCard = (props: AccountManagementProps) => {
  const [editing, setEditing] = useState(false);
  const [cancelAccountConfirmationOpen, setCancelAccountConfirmationOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [cancellingAccount, setCancellingAccount] = useState(false);
  const [feedbackFormObject, setFeedbackFormObject] = useState(initialFormObject);
  const { user, setAlert, changeEmail, currentPlan, billing, cancelUserAccount, restoreCanceledAccount, loadUserInfo } =
    props;
  const { loggedInUser, getMFAOptions } = useAuth();
  const { track } = useAnalytics();
  const { signOut } = useSignOut();

  useEffect(() => {
    checkOnMFA();
  }, []);

  const checkOnMFA = async () => {
    try {
      await getMFAOptions();
    } catch (error) {
      console.log('Error during fething MFAOptions: ', error);
    }
  };

  const logFeedback = async () => {
    track(ANALYTICS.PROCESS.CANCELATION_FEEDBACK, feedbackFormObject);
  };

  const confirmAccountCancel = async () => {
    try {
      setCancellingAccount(true);
      await logFeedback();
      await cancelUserAccount();
    } catch (error) {
      console.log(error);
    } finally {
      setCancelAccountConfirmationOpen(false);
      setFeedbackDialogOpen(false);
      setFeedbackFormObject(initialFormObject);
      if (user?.user) {
        loadUserInfo(user.user);
      }
      setCancellingAccount(false);
      if (currentPlan?.type === planTypes.FREE.type) {
        await signOut();
      }
    }
  };

  const accountCancelationModalContent = useMemo(
    () => (
      <div className="d-flex flex-column justify-content-between">
        {currentPlan?.type === planTypes.FREE.type ? (
          <Typography variant="body1">This cannot be undone, and by cancelling, the following will occur:</Typography>
        ) : (
          <Typography variant="body1">
            By cancelling, the following will occur at the end of your current billing cycle:
          </Typography>
        )}
        <List dense>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="Account will be deleted" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="You will no longer be able to log in" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="All data will be unpinned from Pinata IPFS storage nodes" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText disableTypography primary="Private data will be deleted (if applicable)" />
          </ListItem>
        </List>
      </div>
    ),
    []
  );

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newObject = {
      ...feedbackFormObject,
      [event.target.name]: event.target.checked
    };
    setFeedbackFormObject(newObject);
  };

  const canSubmit = () => {
    if (feedbackFormObject.other_reason) {
      return feedbackFormObject.reason !== '' && feedbackFormObject.reason.length > 5;
    }
    return Object.values(feedbackFormObject).some((feedbackVal) => feedbackVal);
  };

  const handleRestoreAccount = async () => {
    try {
      await restoreCanceledAccount();
      if (user?.user) {
        loadUserInfo(user.user);
      }
    } catch (error) {
      setAlert('error', error);
    }
  };

  return (
    <>
      <Paper sx={{ p: 4 }}>
        {editing ? (
          <EditAccountDetails
            changeEmail={changeEmail}
            setEditing={setEditing}
            setAlert={setAlert}
            user={user}
            loggedInUser={loggedInUser}
          />
        ) : (
          <Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Account Info
            </Typography>
            <Typography variant="body1">
              Current Plan:{' '}
              {currentPlan?.type !== planTypes.ENTERPRISE.type ? currentPlan?.nickname : currentPlan?.name}
            </Typography>
            {user.user?.scheduledToBeCancelledAt ? (
              <Box>
                <Typography variant="h6">
                  Plan expiration date:{' '}
                  <Typography component="span" variant="caption">
                    {dayjs(billing.nextBillingDate).format('YYYY-MM-DD')}
                  </Typography>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleRestoreAccount}>Restore Plan</Button>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={() => setCancelAccountConfirmationOpen(true)}>Cancel Plan</Button>
              </Box>
            )}
          </Box>
        )}
      </Paper>
      {cancelAccountConfirmationOpen && (
        <ConfirmationModal
          title="Are you sure you want to cancel your account?"
          modalOpen={cancelAccountConfirmationOpen}
          modalContent={accountCancelationModalContent}
          toggleModal={setCancelAccountConfirmationOpen}
          loadingText="We are sad to see you go &#128542; Please wait, your account is being canceled right now."
          action={() => {
            setFeedbackDialogOpen(true);
            setCancelAccountConfirmationOpen(false);
          }}
          confirmButtonText="Cancel Account"
          cancelButtonText="Keep my account"
          confirmButtonColor={'error'}
          cancelButtonColor={'primary'}
        />
      )}
      {feedbackDialogOpen && (
        <ConfirmationModal
          title="Help us improve! Why are you cancelling?"
          modalOpen={feedbackDialogOpen}
          modalContent={
            <FeedbackContent
              cancellingAccount={cancellingAccount}
              feedbackFormObject={feedbackFormObject}
              handleFormChange={handleFormChange}
              setFeedbackFormObject={setFeedbackFormObject}
            />
          }
          toggleModal={setFeedbackDialogOpen}
          loadingText="We are sad to see you go &#128542; Please wait, your account is being canceled right now."
          action={confirmAccountCancel}
          confirmButtonText="Cancel Account"
          confirmButtonColor={'error'}
          excludeCancelButton={true}
          isDisabled={cancellingAccount || !canSubmit()}
        />
      )}
    </>
  );
};

export default AccountManagementCard;
