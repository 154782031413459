import {useContext, useEffect, useMemo, useState} from 'react';
import {Button, Container, Paper, Typography, useMediaQuery, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppLogo from 'components/Common/AppLogo';
import Header from 'components/HorizontalLayout/Header';
import PrimaryMenu from 'components/PrimaryMenu';
import GlobalNavigation from './GlobalNavigation';
import PrimaryNavigation from './PrimaryNavigation';
import { ThemeContext } from 'services/globalContext';
import { neutral } from '../../themes/colors';
import useWindowSize from '../../hooks/useWindowSize';
import pinataSub from '../../assets/images/Submarine.svg';
import pinnieIcon from '../../assets/images/Pinnie.svg';
import {useFlags} from "launchdarkly-react-client-sdk";
import {isMobileDevice} from "../../helpers/mobileDetection";

const drawerWidth = 236;
const mobileMenuHeight = 56;

type Props = {
  globalNavigationContent?: React.ReactElement;
  primaryNavigationContent?: React.ReactElement;
  brandContent?: React.ReactElement;
  children: React.ReactElement;
};

const Layout = ({ children }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { changeTheme } = useContext(ThemeContext);
  const { height } = useWindowSize();
  const spacing = isMobile ? 4 : 8;
  const {submarine} = useFlags();

  useEffect(() => {
    changeTheme({
      ...theme,
      // overwrite default spacing for mobile and non-mobile
      spacing
    });
  }, [isMobile]);

  return (
    <Box sx={{display: !isMobile ? 'flex' : 'block'}}>
      {
        submarine && !isMobile && <Box sx={{minWidth: 68}}>
        <Box sx={{backgroundColor: theme.palette.mode === 'light' ? neutral[200] : 'none', width: '100%', height: '100%'}}>
          <Box sx={{position: 'fixed', top: 0, left: 0, m: 1.5}}>
            <Box
              onClick={() => {window.location.replace(`${process.env.REACT_APP_SUBMARINE_ORIGIN || ''}?signinRedirect=false`)}}
              sx={{padding: 0.5, borderRadius: theme.spacing(0.8), minWidth: '20px',
                      backgroundColor: theme.palette.mode === 'dark' ? neutral[900] : 'black',
                      cursor: 'pointer', width: '40px', height: '40px', mb: 1
                    }}
              >
              <img src={pinataSub} alt="submarine" style={{width: '100%', height: '100%'}}/>
            </Box>
            <Box
              onClick={() => {window.location.replace('/')}}
              sx={{border: '2px solid', padding: 0.5, borderRadius: theme.spacing(0.8), minWidth: '20px',
                marginBottom: 1, borderColor: 'white', backgroundColor: theme.palette.mode === 'dark' ? neutral[900] : 'black',
                cursor: 'pointer', width: '40px', height: '40px'
              }}
            >
              <img src={pinnieIcon} alt="submarine" style={{width: '100%', height: '100%'}}/>
            </Box>
		      </Box>
		      </Box>
        </Box>
      }
      <Box sx={{flexGrow: 1}}>
      <GlobalNavigation>
        <Header />
      </GlobalNavigation>
      <Box sx={{ display: 'flex', paddingBottom: isMobile ? `${mobileMenuHeight}px` : 'auto' }} component="main">
        {!isMobile && (
          <Box component="aside" sx={{ minWidth: drawerWidth }}>
            <Drawer
              open={true}
              variant="permanent"
              anchor="left"
              PaperProps={{
                sx: {
                  width: drawerWidth,
                  position: 'sticky',
                  backgroundColor: theme.palette.mode === 'dark' ? neutral[900] : neutral[100]                  
                }
              }}
              sx={{
                width: drawerWidth,
                position: 'sticky',
                marginTop: '-73px',
                top: 0,
                bottom: 0,
                height: height || window.innerHeight || '100%',
              }}
            >
              {/* Logo, primary menu and other children's section as a column for -> DESKTOP */}
              <PrimaryNavigation menuComponent={<PrimaryMenu />} brandComponent={<AppLogo />} />
            </Drawer>
          </Box>
        )}
        <Container maxWidth={useMediaQuery(theme.breakpoints.down('xl')) ? 'lg' : 'xl'} disableGutters sx={{ py: theme.spacing(5) }}>
          {children}
        </Container>
      </Box>
      {isMobile && (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1 }} elevation={3}>
          <PrimaryMenu />
        </Paper>
      )}
      </Box>
    </Box>
  );
};

export default Layout;
