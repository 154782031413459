import { planTypes } from 'helpers/enums';
import {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import {PinataState} from "../../store/types";
import {User} from "../../store/user/types";
import axios from "axios";
import {loadUserInfo} from "../../store/user/user.actions";
import {Box, CircularProgress, Container, Typography} from "@mui/material";

let handlingRedirection = false;
const cannyEndpoint = process.env.REACT_APP_CANNY_ENDPOINT || "";

const CannySSO = (props: {loadUserInfo: any}) => {
  const history = useHistory();
  const location = useLocation();
  const { handleSession } = useAuth();

  const handleRedirection = async (user: User) => {
    if (user) {
      let params = new URLSearchParams(location.search);
      const redirectPinataURL = params.get("redirect");
      const companyID = params.get("companyID");
      const data = new URLSearchParams({
        name: `${user["custom:firstName"]} ${user["custom:lastName"]}`,
        email: user.email,
        avatar: user.avatar,
        id: user.email
      }).toString();
      const ssoTokenRes = await axios.post(
        cannyEndpoint,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      const ssoToken = ssoTokenRes.data;
      const redirectURL = 'https://canny.io/api/redirects/sso?companyID=' + companyID + '&ssoToken=' + ssoToken + '&redirect=' + redirectPinataURL;
      window.location.assign(redirectURL);
    } else {
      console.log("USER NOT AUTHENTICATED")
    }
  }

  const handleUserAuth = async () => {
    if (!handlingRedirection) {
      handlingRedirection = true;
      const userSession = await handleSession();
      if (userSession) {
        console.log("current user", userSession.user);
        // @ts-ignore
        handleRedirection(userSession.user.attributes);
      } else {
        // setHandlingRedirection('UNSTARTED');
        history.push(`/signin${location.search}&cannySSO=true`);
      }
    }
  }

  useEffect(() => {
    handleUserAuth();
  }, [])

  return <Container>
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
      <CircularProgress />
      <Typography variant={"h5"}>In a moment you'll be redirected to Canny</Typography>
    </Box>
  </Container>;
};

const mapStateToProps = (state: PinataState) => {
  return {
    user: state.user.user
  }
};

export default withRouter(connect(mapStateToProps, {loadUserInfo})(CannySSO));
