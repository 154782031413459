import { Box, Grid, Typography } from '@mui/material';
import TotalItem from './TotalItem';
import LinearProgress from '@mui/material/LinearProgress';
import { useGetMetrics } from 'hooks';

function TotalAccountUsage() {
  const { isLoadingGetMetrics, errorGetMetrics, dataGetMetrics } = useGetMetrics();

  if(isLoadingGetMetrics) return (
    <Box sx={{ width: '100%'}}>
      <LinearProgress />
    </Box>
  );

  return (
    <Box>
      <Typography variant="h4" sx={{marginBottom: 2}}>Total Account Usage</Typography>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <TotalItem 
              title="Bandwidth" 
              currentValue={dataGetMetrics?.data?.transferBytes}
              maxValue={dataGetMetrics?.data?.transferBytesLimit}
              unit={true}
            />
          </Grid>
          <Grid item md={6} xs={12}>
          <TotalItem 
              title="Requests" 
              currentValue={dataGetMetrics?.data?.reqCount}
              maxValue={dataGetMetrics?.data?.reqCountLimit}
              unit={false}
            />
          </Grid>
        </Grid>  
    </Box>
  )
}

export default TotalAccountUsage;
