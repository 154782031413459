import { Button, Dialog, DialogContent, Grid } from '@mui/material';
import { PinataDialogTitle } from '../../components/Common/MuiComponents';

interface KeyInfoModalProps {
  setKeyInfoModalOpen: (open: boolean) => void;
  keyInfoModalOpen: boolean;
  keyData: any;
  setAlert: (message: string, type: 'success' | 'danger' | 'warning') => void;
}

const KeyInfoModal = ({ setKeyInfoModalOpen, keyInfoModalOpen, keyData, setAlert }: KeyInfoModalProps) => {
  const copyKeyInfo = () => {
    navigator.clipboard.writeText(
      `API Key: ${keyData.pinata_api_key}\n API Secret: ${keyData.pinata_api_secret}\n JWT: ${keyData.JWT}`
    );
    setAlert('Key Info Copied!', 'success');
  };
  return (
    <Dialog open={keyInfoModalOpen} onClose={() => setKeyInfoModalOpen(!keyInfoModalOpen)}>
      <PinataDialogTitle onClose={() => setKeyInfoModalOpen(false)}>API Key Info</PinataDialogTitle>
      <DialogContent dividers>
        <div>
          <h3>This info will only be shown once</h3>
          <p>Make sure you store the info somewhere safe</p>
        </div>
        <Grid container>
          <Grid item xs={4}>
            <h5>API Key</h5>
          </Grid>
          <Grid item xs={8}>
            <p style={{ lineBreak: 'anywhere' }}>{keyData.pinata_api_key}</p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <h5>API Secret</h5>
          </Grid>
          <Grid item xs={8}>
            <p style={{ lineBreak: 'anywhere' }}>{keyData.pinata_api_secret}</p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <h5>JWT</h5>
            <h6>(Secret access token)</h6>
          </Grid>
          <Grid item xs={8}>
            <p style={{ lineBreak: 'anywhere' }}>{keyData.JWT}</p>
          </Grid>
        </Grid>
        <Button onClick={copyKeyInfo}>Copy All</Button>
        <Button onClick={() => setKeyInfoModalOpen(false)} variant={'text'}>
          Done
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default KeyInfoModal;
