import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function UpgradePlanButton() {
  return (
    <Button
      id="#upgrade-plan-button"
      component={Link}
      to="/billing#upgrade"
      sx={{
        borderRadius: 3,
        padding: '8px 12px',
        margin: 0,
        fontSize: (theme) => theme.typography.pxToRem(14),
        background: 'linear-gradient(90deg, #8B03F2 31.41%, #02BBDD 156.92%)'
      }}
    >
      Upgrade plan
    </Button>
  );
}

export default UpgradePlanButton;
