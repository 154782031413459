import { useEffect, useState } from 'react';

import {Switch, BrowserRouter as Router, Route, Redirect, useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import {useFlags, useLDClient, withLDProvider} from 'launchdarkly-react-client-sdk';
// hooks
import { fetchSession, useAuth } from './hooks/useAuth';
import { useAnalytics as internalAnalytics } from 'hooks/useAnalytics';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';

//  User actions
import { loadUserInfo } from './store/user/user.actions';

// layouts Format
import VerticalLayout from './components/VerticalLayout3';
import NonAuthLayout from './components/NonAuthLayout';

// pages
import PinManager from 'pages/PinManager';
import Keys from 'pages/APIKeys/index';
import Account from 'pages/Account/index';
import Billing from 'pages/Billing/index';
import Authenticate from 'pages/Authentication/Authenticate';
import Gateway from 'pages/Gateway';
import Usage from 'pages/Gateway/Usage/index';
import { Analytics } from 'pages';
import Register from 'pages/Authentication/Register';
import SignIn from 'pages/Authentication/SignIn';
import V2APIKeys from 'pages/V2APIKeys';
import BillingAddressModal from 'pages/Billing/BillingAddressModal';
import Developers from './pages/Developers';
import MainLayout from './pages/PinataAuth';

import { getUsageMetrics } from './store/metrics/metrics.actions';
import { getAllBillingPlans, retrieveStripeCustomer } from './store/billing/billing.actions';
import { Box, CircularProgress, CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BillingState } from './store/billing/types';
import { MetricsState } from './store/metrics/types';
import { handleIfUserIsMarketingSignUp } from 'helpers/handleMarketingSignUp';
import { ThemeContext } from './services/globalContext';
import { Themes } from './themes';
import createTheme, { Theme, ThemeOptions } from '@mui/material/styles/createTheme';
import Components from './pages/Components/Components';
import { UserState } from './store/user/types';
import { GatewaysState } from './store/gateways/types';
import MainAuthForm from 'pages/PinataAuth/MainAuthForm';
import CannySSO from './pages/Authentication/CannySSO';
import * as Sentry from '@sentry/react';
import { getAllGateways } from './store/gateways/gateway.actions';
import { useAnalytics } from 'use-analytics';
import { ANALYTICS } from './common/constants';
import { AfterUpdatePlanDialog } from './components/Common/AfterUpdatePlanDialog';
import { planTypes } from 'helpers/enums';
import * as FullStory from "@fullstory/browser";

interface AppProps {
  user: UserState;
  billing: BillingState;
  data: any;
  gateways: GatewaysState;
  apiKeys: any;
  loadUserInfo: any;
  metrics: MetricsState;
  retrieveStripeCustomer: any;
  getUsageMetrics: any;
  getAllBillingPlans: () => void;
  getAllGateways: (page: number) => void;
}

const AuthenticatedRoutes = (props: {user: UserState, billing: BillingState}) => {
  const { user, billing } = props;
  const ldClient = useLDClient();
  const { analyticsApi, submarine } = useFlags();
  const {search} = useLocation();

  useEffect(() => {
    if (user?.user?.email && billing.activePricingPlan) {
      const ldIdentifyObj = {
        kind: 'user',
        key: user?.user?.email,
        email: user?.user?.email,
        currentPlan: billing.activePricingPlan?.nickname || '',
        currentPlanType: billing.activePricingPlan?.type,
        lastName: user?.user?.['custom:lastName'],
        firstName: user?.user?.['custom:firstName'],
      }
      ldClient?.identify(ldIdentifyObj, undefined, (res) => {
        console.log('launch darkly initialized', res)
      });

      //Load Fullstory
      if (process.env.NODE_ENV === 'production') {
        const isFSInited = FullStory.isInitialized();
        if (!isFSInited) {
          FullStory.init({ orgId: '17GRP9' }, () => {
            FullStory.identify(user?.user?.sub!, {
              displayName: `${user?.user?.['custom:firstName']} ${user?.user?.['custom:lastName']}`,
              email: props.user?.user?.email,
              currentPlan: props?.billing?.activePricingPlan?.nickname,
              currentPlanType: props?.billing?.activePricingPlan?.type
            });
          });
        }
      }
    }
    return () => {
      ldClient?.close();
    };
  }, [user?.user, billing.activePricingPlan]);


  return <VerticalLayout><>
    <Route exact path="/">
      <Redirect to={'/pinmanager'} />
    </Route>
    <Route path="/pinmanager" component={PinManager} />
    <Route path="/keys" component={Keys} />
    <Route path="/v2" component={V2APIKeys} />
    <Route path="/keys">
      <Redirect to={'/developers/api-keys'} />
    </Route>
    <Route path="/v2">
      <Redirect to={'/developers/v2-api-keys'} />
    </Route>
    <Route path="/profile" component={Account} />
    <Route path="/billing" component={Billing} />
    <Route exact path="/gateway" component={Gateway} />
    {analyticsApi && <Route exact path="/analytics" component={Analytics} />}
    <Route path="/authenticate" component={Authenticate} />
    <Route path="/cannysso" component={CannySSO} />
    <Route path="/gateway/:name" component={Usage} />
    <Route path="/developers" component={Developers} />
    <Route exact path="/signin" render={() => <Redirect to="/pinmanager" />} />
    <Route exact path="/register" render={() => <Redirect to="/pinmanager" />} />
    <Route path="/components" component={Components} />
  </></VerticalLayout>
}

const AuthenticatedStateToProps = (state: any) => {
  return {
    user: state.user,
    billing: state.billing,
  };
};

const AuthenticatedApp = connect(AuthenticatedStateToProps, {})(AuthenticatedRoutes);

const LDProviderAuthenticated = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || ''
})(AuthenticatedApp);

const App = (props: AppProps) => {
  const {
    user,
    billing,
    data,
    gateways,
    apiKeys,
    loadUserInfo,
    metrics,
    retrieveStripeCustomer,
    getUsageMetrics,
    getAllBillingPlans,
    getAllGateways
  } = props;
  const [openBillingAddressModal, setOpenBillingAddressModal] = useState(false);
  const { isAuthenticated, loggedInUser, handleSession }: any = useAuth();
  const { updateIntercom } = internalAnalytics({ user, billing, data, gateways, apiKeys, metrics });
  const [currentThemeName, setCurrentThemeName] = useState('dark');
  const [theme, setTheme] = useState<Theme>(Themes.light);
  const { track, identify, page } = useAnalytics();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [identifyCalled, setIdentifyCalled] = useState(false);

  // enable analytics only for non-dev env
  process.env.NODE_ENV === 'production' && useGoogleAnalytics();

  // Track location in Rudderstack
  useEffect(() => {
    page().catch();
  }, [window.location.pathname]);

  // Reset intercom when metrics are ok
  useEffect(() => {
    if (metrics?.metricsLimitData.title === 'under-limit' && window.Intercom) {
      window.Intercom('shutdown');
      const reloadTimeout = setTimeout(() => {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_ID
        });
      }, 1500);
      return () => clearTimeout(reloadTimeout);
    }
  }, [metrics?.metricsLimitData]);

  useEffect(() => {
    handleIfUserIsMarketingSignUp();
    if (isAuthenticated) {
      let params = new URLSearchParams(location.search);
      const cannySSO = params.get('cannySSO');
      if (cannySSO === 'true' && location.pathname !== '/cannysso') {
        console.log('calling canny sso');
        window.location.assign(`cannysso${location.search}`);
        return;
      }

      if (window?.Intercom) {
        window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_ID
        });
      }
      loadUserInfo(loggedInUser?.attributes);
    }
  }, [isAuthenticated]);

  // update intercom once one of these props change and not trigger this function during loglout
  useEffect(() => {
    if (window?.Intercom && window?.Intercom?.booted && isAuthenticated) {
      updateIntercom();
    }
  }, [
    user?.user?.email,
    billing?.activePricingPlan?.nickname,
    apiKeys?.keys?.count,
    metrics?.metricsLimitData?.title,
    metrics?.metrics?.metricsAccount?.fileCount,
    metrics?.metrics?.metricsAccount?.gatewayCount,
    metrics?.metrics?.metricsMonthly?.transferBytes
  ]);

  // Stripe Customer Billing Address Modal handler
  useEffect(() => {
    if (
      billing.activePricingPlan &&
      (!(billing?.stripe_customer?.address?.country.length > 0) ||
        !(billing?.stripe_customer?.address?.postalCode.length > 0)) &&
      billing?.activePricingPlan?.nickname !== 'Free'
    ) {
      // setOpenBillingAddressModal(true);
    }
  }, [billing?.stripe_customer?.address, billing.activePricingPlan?.nickname]);

  const initializePricing = async () => {
    const sessionData = await fetchSession();
    if (sessionData?.accessToken) {
      await retrieveStripeCustomer();
      await getUsageMetrics();
    }
  };

  useEffect(() => {
    if (user?.user?.email) {
      initializePricing();
      getAllBillingPlans();
      // only trigger the identify call once
      // we have the plans otherwise it triggers twice
      // once on initial load and again after the plans fetch api call response
      if (identifyCalled || !billing.activePricingPlan?.nickname) {
        return
      }
      identify(user?.user?.sub, {
        first_login: false,
        email: user?.user?.email,
        firstName: user?.user?.['custom:firstName'],
        lastName: user?.user?.['custom:lastName'],
        currentPlan: billing.activePricingPlan?.nickname,
        currentPlanType: billing.activePricingPlan?.type
      });
      track(ANALYTICS.AUTH.LOGIN);
      setIdentifyCalled(true);
      // call this once when user loads application
    }
  }, [
    user?.user?.email,
    user?.user?.sub,
    user?.user?.['custom:firstName'],
    user?.user?.['custom:lastName'],
    billing.activePricingPlan?.nickname
  ]);

  useEffect(() => {
    if (user?.user?.email) {
      getAllGateways(0);
    }
  }, [user?.user]);

  //Effect to be used once the platform is fully loaded and the user is NEW
  //Should trigger only once right after the user signs up.
  useEffect(() => {
    const newUser = localStorage.getItem('newUser');
    if (newUser === 'true' && user?.user?.email && gateways.gateways.count > 0 && billing.activePricingPlan) {
      localStorage.setItem('newUser', 'false');
      if (billing.activePricingPlan.type === planTypes.NEW_FREE.type) {
        setShowWelcomeModal(true);
      }
    }
  }, [user?.user?.email, gateways, billing.activePricingPlan]);

  const onFocus = () => {
    handleSession();
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    onFocus();
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  useEffect(() => {
    if (currentThemeName === 'light') {
      setTheme(Themes.light);
    } else if (currentThemeName === 'dark') {
      setTheme(Themes.dark);
    }
  }, [currentThemeName]);

  useEffect(() => {
    //TODO: Create a localstorage manager from which all the values can be set/get
    if (isAuthenticated) {
      // check localstorage only oif user logged in, otherwise -> keep dark theme
      const theme = localStorage.getItem('theme');
      setCurrentThemeName(theme || 'dark');
    }
  }, [isAuthenticated]);

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        '#root': {
          height: '100vh'
        },
        html: {
          fontSize: '16px',
          [theme.breakpoints.down('sm')]: {
            fontSize: 13
          }
        },
        a: {
          textDecoration: 'none'
        },
        '.intercom-lightweight-app-launcher.intercom-launcher': {
          bottom: '60px !important'
        }
      }}
    />
  );

  return (
    <Sentry.ErrorBoundary>
      <ThemeContext.Provider
        value={{
          themeName: currentThemeName,
          setThemeName: setCurrentThemeName,
          changeTheme: (options: ThemeOptions): any => {
            const newTheme = createTheme(options);
            setTheme(newTheme);
          }
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {inputGlobalStyles}
          <Router>
            <Switch>
              {isAuthenticated && (
                  <>
                    <LDProviderAuthenticated />
                    {openBillingAddressModal && (
                      <BillingAddressModal
                        setModalOpen={setOpenBillingAddressModal}
                        modalIsOpen={openBillingAddressModal}
                      />
                    )}
                    {showWelcomeModal && (
                      <AfterUpdatePlanDialog
                        createGateway={() => {}}
                        newPlan={billing.activePricingPlan!}
                        gateways={gateways.gateways}
                        onClose={() => {
                          setShowWelcomeModal(false);
                        }}
                      />
                    )}
                  </>
              )}

              {!isAuthenticated && (
                <MainLayout>
                  <Switch>
                    <Route exact path="/" component={() => <MainAuthForm />} />
                    <Route path="/signin" component={() => <MainAuthForm />} />
                    <Route path="/register" component={() => <MainAuthForm />} />
                    <Route path="/authenticate" component={Authenticate} />
                    <Route path="/cannysso" component={CannySSO} />
                    <Route path="*" component={() => <MainAuthForm />} />
                  </Switch>
                </MainLayout>
              )}
            </Switch>
          </Router>
        </ThemeProvider>
      </ThemeContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    billing: state.billing,
    data: state.data,
    gateways: state.gateways,
    apiKeys: state.apiKeys,
    metrics: state.metrics
  };
};

export default connect(mapStateToProps, {
  loadUserInfo,
  getUsageMetrics,
  retrieveStripeCustomer,
  getAllBillingPlans,
  getAllGateways
})(App);
