import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Typography, Button, Link, CircularProgress } from '@mui/material';
import { AuthContext, AuthPages, RESEND_STATUSES } from './common';
import { codeFieldRules, emailFieldRules, passwordFieldRules } from 'helpers/formFieldValidations';
import { getErrorMessage } from 'helpers/error.helper';
import { forgotPasswordSubmit, sendPasswordReset } from 'hooks/useAuth';

export function ForgotPassword({ setAlert }: { setAlert: (message: string, type: string) => void }) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showResetPasswordCodeForm, setShowResetPasswordCodeForm] = useState<boolean>(false);
  const [resendStatus, setResendStatus] = useState<RESEND_STATUSES>(RESEND_STATUSES.UNINITIALIZED);
  const { setAuthAlert, setCurrentPage } = useContext(AuthContext);
  const { handleSubmit, register, formState, getFieldState, getValues } = useForm({
    mode: 'onChange'
  });

  const emailState = getFieldState('email', formState);
  const codeState = getFieldState('code', formState);
  const passwordState = getFieldState('password', formState);
  const confirmPasswordState = getFieldState('confirmPassword', formState);

  const submit = async (data: any) => {
    try {
      setSubmitting(true);
      // refactor soon: move to the useEffect or make it in one style for each auth form
      setAuthAlert(null);
      if (!showResetPasswordCodeForm) {
        const { email } = data;
        await sendPasswordReset(email);
        setAlert("If there's an email associated with this account, we'll send the code there", 'success');
        setShowResetPasswordCodeForm(true);
      } else {
        const { email, code, password } = data;
        await forgotPasswordSubmit(email, code, password);
        setAlert('Password successfully updated!', 'success');
        setCurrentPage(AuthPages.AUTH);
      }
    } catch (error) {
      let message = getErrorMessage(error);
      if (message.includes('User does not exist')) {
        message = 'User does not exist';
      }
      setAuthAlert({ type: 'error', message });
    } finally {
      setSubmitting(false);
    }
  };

  const handleResend = async () => {
    try {
      await sendPasswordReset(getValues('email'));
      setResendStatus(RESEND_STATUSES.SENT);
      setAuthAlert({ type: 'success', message: 'Code sent!' });
    } catch (error) {
      const message = getErrorMessage(error);
      setAuthAlert({ type: 'error', message });
    }
  };

  const canBeSubmitted = !!Object.keys(formState.errors).length || getValues(['code', 'password', 'confirmPassword']).some(val => val === '');

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& .MuiTextField-root': {
          mb: 1
        },
        '& .MuiFormHelperText-root': {
          margin: 0,
          fontSize: (theme) => theme.typography.pxToRem(11)
        },
        '& .MuiTypography-body2': {
          color: 'rgba(55, 55, 55, 0.7)'
        }
      }}
      component="form"
      onSubmit={handleSubmit(submit)}
    >
      <Typography variant="h2">Reset Password</Typography>
      <Box>
        <TextField
          fullWidth
          placeholder="Enter email"
          autoComplete="email"
          variant="standard"
          id="d2-auth-recovery-email"
          sx={{ mt: 3 }}
          disabled={showResetPasswordCodeForm}
          error={emailState?.invalid}
          helperText={emailState?.invalid && emailState?.error?.message}
          inputProps={{
            ...register('email', emailFieldRules)
          }}
        />
        {showResetPasswordCodeForm && (
          <>
            <TextField
              fullWidth
              placeholder="Enter code"
              autoComplete="one-time-code"
              variant="standard"
              id="d2-auth-recovery-code"
              sx={{ my: 2 }}
              error={codeState?.invalid}
              helperText={codeState?.invalid && codeState?.error?.message}
              inputProps={{
                ...register('code', codeFieldRules)
              }}
            />
            <TextField
              fullWidth
              placeholder="Enter new password"
              autoComplete="new-password"
              variant="standard"
              type="password"
              id="d2-auth-recovery-password"
              sx={{ my: 2 }}
              error={passwordState?.invalid}
              helperText={
                passwordState?.invalid
                  ? passwordState?.error?.message
                  : 'Password should include at least 8 characters, 1 number, 1 uppercase, 1 lowercase, and 1 special character.'
              }
              inputProps={{
                ...register('password', { ...passwordFieldRules, deps: 'confirmPassword' })
              }}
            />
            <TextField
              fullWidth
              placeholder="Confirm new password"
              autoComplete="new-password"
              variant="standard"
              type="password"
              id="d2-auth-recovery-confirmPassword"
              sx={{ my: 2 }}
              error={confirmPasswordState?.invalid}
              helperText={confirmPasswordState?.invalid && confirmPasswordState?.error?.message}
              inputProps={{
                ...register('confirmPassword', {
                  ...passwordFieldRules,
                  deps: 'password',
                  validate: (val) => {
                    if (val.length) {
                      return val === getValues('password') || 'Passwords do not match';
                    }
                  }
                })
              }}
            />
            {resendStatus !== RESEND_STATUSES.SENT && (
              <Typography sx={{ textAlign: 'center', fontSize: 11 }} variant="body2">
                Haven’t received the code?{' '}
                <Link href="#" onClick={handleResend} id="d2-auth-recovery-sendAgain" sx={{textDecoration: 'underline !important'}}>
                  Send
                </Link>{' '}
                code again
              </Typography>
            )}
          </>
        )}
      </Box>

      <Button type="submit" size="large" fullWidth sx={{ mt: 3 }} id="d2-auth-recovery-submit" disabled={canBeSubmitted}>
        {submitting ? <CircularProgress /> : showResetPasswordCodeForm ? 'Reset password' : 'Send Email Recovery'}
      </Button>
    </Box>
  );
}
