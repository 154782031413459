import { ReactNode } from 'react';
import { Box, Button, DialogTitle, IconButton, Paper, Avatar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logoImg from '../../assets/images/logo.svg';

export const CardsBox = styled(Box)(({ theme }) => ({
  '& > *': {
    marginBottom: 20
  }
}));

interface PinataDialogTitleProps {
  onClose: () => void;
  children: ReactNode;
}

export const PinataDialogTitle = (props: PinataDialogTitleProps) => {
  return (
    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 0 }}>
      {props.children}
      <IconButton sx={{ padding: 0 }} aria-label="close" onClick={props.onClose}>
        <span aria-hidden="true">&times;</span>
      </IconButton>
    </DialogTitle>
  );
};

export const PinataBox = styled(Paper)(({ theme }) => {
  return {
    display: 'flex',
    padding: '1.875rem 3.125rem',
    borderRadius: theme.shape.borderRadius * 1.6
  };
});

interface PinataAuthCardHeaderProps {
  children?: ReactNode;
}

export const PinataAuthCardHeader = (props: PinataAuthCardHeaderProps) => (
  <Box
    sx={{
      p: 1.5,
      backgroundColor: 'primary.main',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
  >
    <Typography variant="h6" sx={{ m: 0, color: 'primary.contrastText' }}>
      {props.children}
    </Typography>
    <Link to="/">
      <Avatar sx={{ backgroundColor: 'white', width: '75px', height: '75px' }}>
        <img src={logoImg} alt="pinata-image" style={{ width: '75%', height: '100%' }} />
      </Avatar>
    </Link>
  </Box>
);
