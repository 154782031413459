import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {Alert, Box, Button, CircularProgress, Link, TextField, Typography, useTheme} from '@mui/material';
import { emailFieldRules, namesFieldRules, passwordFieldRules } from 'helpers/formFieldValidations';
import { signUpUser } from 'hooks/useAuth';
import { getErrorMessage } from 'helpers/error.helper';
import { AuthContext, AuthPages } from './common';
import {ANALYTICS} from "../../common/constants";
import {useAnalytics} from "use-analytics";

export const RegisterForm = ({
  formData,
  setFormData,
  isBuilder = false,
  setAlert
}: {
  formData: any;
  setFormData: any;
  isBuilder: boolean;
  setAlert: (message: string, type: string) => void;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { setCurrentPage, setAuthAlert } = useContext(AuthContext);
  const { handleSubmit, control, getFieldState, getValues, formState, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: formData.email ?? '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: ''
    }
  });

  const [email, password] = watch(['email', 'password']);

  useEffect(() => {
    setFormData((state: any) => ({ ...state, email, password }));
    setAuthAlert(null);
  }, [email, password]);

  const onSubmit = async (data: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    isBuilder?: boolean;
  }) => {
    try {
      setSubmitting(true);
      const { email, password, firstName, lastName } = data;
      localStorage.setItem('newUser', 'true');

      const { sub } = await signUpUser(email, password, firstName, lastName, isBuilder);
      identify(sub, { email, firstName, lastName, first_login: true });
      track(ANALYTICS.AUTH.SIGN_UP);
      setAlert('Registration verification code sent to your email!', 'success');
      setCurrentPage(AuthPages.EMAIL_CODE);
    } catch (error) {
      const message = getErrorMessage(error);
      setAuthAlert({ type: 'error', message });
    } finally {
      setSubmitting(false);
    }
  };

  const { errors } = formState;

  const canBeSubmitted = !!Object.keys(errors).length || getValues(['firstName', 'lastName', 'password']).some(val => val === '');

  const firstNameState = getFieldState('firstName', formState);
  const lastNameState = getFieldState('lastName', formState);
  const emailState = getFieldState('email', formState);
  const passwordState = getFieldState('password', formState);
  const confirmPasswordState = getFieldState('confirmPassword', formState);
  const theme = useTheme();
  const { track, identify } = useAnalytics();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
      <Typography variant="h2">Sign Up</Typography>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& .MuiTextField-root': {
            mb: 1
          },
          '& .MuiFormHelperText-root': {
            margin: 0,
            fontSize: (theme) => theme.typography.pxToRem(10),
            lineHeight: '1rem'
          }
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box>
          <Controller
            control={control}
            name="email"
            rules={emailFieldRules}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ my: 2 }}
                type="email"
                label="Email"
                variant="standard"
                id="d2-auth-register-email"
                fullWidth
                helperText={emailState.error?.message}
                error={Boolean(emailState.error)}
              />
            )}
          />
          <Controller
            name="firstName"
            control={control}
            rules={namesFieldRules}
            render={({ field }) => (
              <TextField
                sx={{ my: 2 }}
                {...field}
                label="First Name"
                variant="standard"
                id="d2-auth-register-firstName"
                fullWidth
                helperText={firstNameState?.invalid && firstNameState?.error?.message}
                error={firstNameState?.invalid}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={namesFieldRules}
            render={({ field }) => (
              <TextField
                sx={{ my: 2 }}
                {...field}
                label="Last Name"
                variant="standard"
                id="d2-auth-register-lastName"
                fullWidth
                helperText={lastNameState?.invalid && lastNameState?.error?.message}
                error={lastNameState?.invalid}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              ...passwordFieldRules,
              deps: ['confirmPassword']
            }}
            render={({ field }) => (
              <TextField
                sx={{ my: 2 }}
                {...field}
                variant="standard"
                label="Password"
                fullWidth
                type="password"
                id="d2-auth-register-password"
                autoComplete="new-password"
                error={Boolean(passwordState?.error)}
                helperText={
                  passwordState?.error
                    ? passwordState?.error?.message
                    : 'Password should include at least 8 characters, 1 number, 1 uppercase, 1 lowercase, and 1 special character.'
                }
              />
            )}
          />
          {/*<Controller*/}
          {/*  name="confirmPassword"*/}
          {/*  control={control}*/}
          {/*  rules={{*/}
          {/*    deps: ['password'],*/}
          {/*    validate: (val) => {*/}
          {/*      if (val.length) {*/}
          {/*        return val === getValues('password') || 'Passwords do not match';*/}
          {/*      }*/}
          {/*    }*/}
          {/*  }}*/}
          {/*  render={({ field }) => (*/}
          {/*    <TextField*/}
          {/*      sx={{ mb: 2 }}*/}
          {/*      {...field}*/}
          {/*      variant="standard"*/}
          {/*      label="Confirm password"*/}
          {/*      fullWidth*/}
          {/*      type="password"*/}
          {/*      id="d2-auth-register-repeatPassword"*/}
          {/*      autoComplete="new-password"*/}
          {/*      error={Boolean(confirmPasswordState?.error)}*/}
          {/*      helperText={confirmPasswordState?.error && confirmPasswordState?.error?.message}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*/>*/}
        </Box>
        <Button sx={{ my: 2 }} fullWidth type="submit" id="d2-auth-register-submit" disabled={canBeSubmitted}>
          {submitting ? <CircularProgress sx={{color: theme.palette.primary.contrastText}} size={'1.7rem'}/> : 'Send confirmation code'}
        </Button>
        <Typography
          component="span"
          align="center"
          sx={{ color: 'rgba(55, 55, 55, 0.7)', fontSize: (theme) => theme.typography.pxToRem(10) }}
        >
          By registering you agree to{' '}
          <Link
            variant="inherit"
            sx={{ textDecoration: 'underline !important', color: 'inherit' }}
            href="https://pinata.cloud/terms"
            target="_blank"
            rel="noopenner noreferrer"
          >
            Pinata's Terms of Use
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
