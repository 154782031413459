import { Dialog, DialogContent } from '@mui/material';
import Upload from 'pages/PinManager/Upload';
import { PinataDialogTitle } from 'components/Common/MuiComponents';

interface FolderUploadModalProps {
  folderUploadOpen: boolean;
  setFolderUploadModalOpen: (isOpen: boolean) => void;
}

const FolderUploadModal = ({ folderUploadOpen, setFolderUploadModalOpen }: FolderUploadModalProps) => {
  const closeModal = () => {
    setFolderUploadModalOpen(false);
  };
  return (
    <Dialog open={folderUploadOpen} onClose={() => setFolderUploadModalOpen(false)} fullWidth sx={{ p: 2 }}>
      <PinataDialogTitle onClose={() => setFolderUploadModalOpen(false)}>Upload Folder</PinataDialogTitle>
      <DialogContent>
        <Upload uploadType="folder" closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  );
};

export default FolderUploadModal;
