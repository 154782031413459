/* global rudderanalytics */
// eslint:ignore
const config = {
  /* Your rudderstack writeKey */
  writeKey: null,
  /* Your rudderstack dataPlaneUrl */
  dataPlaneUrl: null,
  integrations: {}
  /* Override the Segment snippet url, for loading via custom CDN proxy */
};

interface Config {
  writeKey: string;
  dataPlaneUrl: string;
  integrations?: any;
}

/**
 * Rudderstack analytics plugin
 * @link https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/quick-start-guide/
 * @param {object}  pluginConfig - Plugin settings
 * @param {string}  pluginConfig.writeKey - Your rudderstack writeKey
 * @param {string}  pluginConfig.dataPlaneUrl - Your rudderstack dataPlaneUrl
 * @param {boolean} [pluginConfig.disableAnonymousTraffic] - Disable loading rudderstack for anonymous visitors
 * @param {boolean} [pluginConfig.customScriptSrc] - Override the Rudderstack snippet url, for loading via custom CDN proxy
 * @param {object}  [pluginConfig.integrations] - Enable/disable Rudderstack destinations
 * @return {object} Analytics plugin
 * @example
 *
 * rudderstackPlugin({
 *   writeKey: '123-xyz',
 *   dataPlaneUrl: 'https://{workspace}.dataplane.rudderstack.com'
 * })
 */
function rudderstackPlugin(pluginConfig: Config) {
  return {
    name: 'rudderstack',
    config: {
      ...config,
      ...pluginConfig
    },
    /* Custom methods to add .group call */
    methods: {
      /* Group https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#group */
      group(groupId: string, traits = {}, options = {}, callback: (() => void) | undefined) {
        // If no rudderstack, return early
        if (typeof window.rudderanalytics === 'undefined') {
          return;
        }
        // Make group call to rudderstack
        window.rudderanalytics.group(groupId, traits, options, callback);
      }
    },
    bootstrap: ({ config, instance }: any) => {
      /* Load segment script after userId exists */
      if (config.disableAnonymousTraffic && !instance.user('userId')) {
        instance.once('identifyStart', ({ plugins }: any) => {
          const self = plugins['rudderstack'];
          if (!self.loaded()) {
            // instance.loadPlugin('segment')
            initialize({ config, instance });
          }
        });
      }
    },
    /* Load rudderstack analytics.js on page */
    initialize: initialize,
    /* Trigger rudderstack page view */
    page: ({ payload, config }: any) => {
      if (typeof window.rudderanalytics === 'undefined') return;
      const properties = payload.properties || {};
      const name = properties.name || properties.title;
      const category = properties.category;

      window.rudderanalytics.page(category, name, properties, {
        integrations: config.integrations,
        ...payload.options
      });
    },
    /* Track rudderstack event */
    track: ({ payload, config }: any) => {
      if (typeof window.rudderanalytics === 'undefined') return;

      window.rudderanalytics.track(payload.event, payload.properties, {
        integrations: config.integrations,
        ...payload.options
      });
    },
    /* Identify rudderstack user */
    identify: ({ payload, config }: any) => {
      if (typeof window.rudderanalytics === 'undefined') return;

      const { userId, traits, options } = payload;

      if (typeof userId === 'string') {
        window.rudderanalytics.identify(userId, traits, {
          integrations: config.integrations,
          ...options
        });
      } else {
        window.rudderanalytics.identify(traits, {
          integrations: config.integrations,
          ...options
        });
      }
    },
    /* Remove rudderstack cookies on analytics.reset */
    reset: () => {
      if (typeof window.rudderanalytics === 'undefined') return;
      window.rudderanalytics.reset();
    },
    /* Sync id when ready */
    ready: ({ instance, config }: any) => {
      if (!config.syncAnonymousId || typeof window.rudderanalytics === 'undefined') return;
      const user = window.rudderanalytics.getUserId();
      if (user) {
        const anonId = window.rudderanalytics.getAnonymousId();
        const analyticsAnonId = instance.user('anonymousId');
        // If has rudderstack anonymous ID && doesn't match analytics anon id, update
        if (anonId && anonId !== analyticsAnonId) {
          instance.setAnonymousId(anonId);
        }
      }
    },
    /* Check if rudderstack loaded */
    loaded: () => {
      return window.rudderanalytics && !!window.rudderanalytics.initialized;
    }
  };
}

function initialize({ config }: any) {
  const { writeKey, customScriptSrc, dataPlaneUrl } = config;
  if (!writeKey) {
    throw new Error('No rudderstack writeKey');
  }
  if (!dataPlaneUrl) {
    throw new Error('No rudderstack dataPlaneUrl');
  }
  /* eslint-disable */
  (function () {
    var rudderanalytics: any = (window.rudderanalytics = window.rudderanalytics || []);

    function isScriptLoaded() {
      const scripts = document.getElementsByTagName('script');
      const scriptMatch = customScriptSrc || 'cdn.rudderlabs.com/v1.1';
      return !!Object.keys(scripts).filter((key: any) => {
        const scriptInfo = scripts[key] || {};
        const src = scriptInfo.src || '';
        return src.indexOf(scriptMatch) > -1;
      }).length;
    }

    if (!rudderanalytics.initialized) {
      if (!isScriptLoaded()) {
        rudderanalytics.invoked = !0;
        rudderanalytics.methods = [
          'load',
          'page',
          'track',
          'identify',
          'alias',
          'group',
          'ready',
          'reset',
          'getAnonymousId',
          'setAnonymousId',
          'getUserId',
          'getUserTraits',
          'getGroupId',
          'getGroupTraits',
          'startSession',
          'endSession'
        ];
        for (var i = 0; i < rudderanalytics.methods.length; i++) {
          var method = rudderanalytics.methods[i];
          rudderanalytics[method] = (function (methodName) {
            return function () {
              rudderanalytics.push([methodName].concat(Array.prototype.slice.call(arguments)));
            };
          })(method);
        }
        const load = function () {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = customScriptSrc || 'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js';
          n.id = 'rudder-analytics';
          var a = document.getElementsByTagName('script')[0];
          if (a && a.parentNode) {
            a.parentNode.insertBefore(n, a);
          }
        };
        window.rudderanalytics.load(writeKey, dataPlaneUrl, { logLevel: config.debug ? 'DEBUG' : undefined });
        window.rudderanalytics.initialized = true;
        load();
      }
    }
  })();
  window.rudderanalytics.ready(() => {
    console.log('rudderanalytics All set!');
  });
  /* eslint-enable */
}

export default rudderstackPlugin;
