import { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Chip, Divider, Grid, Link, Typography } from '@mui/material';
import TotalAccountUsage from './Totals/TotalAccountUsage/TotalAccountUsage';
import GatewayUsage from './Totals/GatewayUsage/GatewayUsage';
import Filters from './Filters/Filters';
import DashboardTabs from './Dashboard/DashboardTabs/DashboardTabs';
import dashboardsConfiguration from './Dashboard/dashboardsConfiguration.json';

dayjs.extend(utc);

const FEEDBACK_LINK = 'https://pinnie.typeform.com/to/UvHfWibr';
const INITIAL_PERIOD_DAYS = 7;

const today = dayjs.utc().endOf('day').toDate();
const startDay = dayjs.utc().subtract(INITIAL_PERIOD_DAYS, 'day').startOf('day').toDate();
function Analytics() {
  const [dateStartFilter, setDateStartFilter] = useState(startDay);
  const [dateEndFilter, setDateEndFilter] = useState(today);
  const [gatewayFilter, setGatewayFilter] = useState("");
  const [gatewaysList, setGatewaysList] = useState([]);
  
  return (
    <Grid container spacing={1} direction='column'>
      <Grid item>
        <Typography variant="h3" sx={{marginBottom: 1, display: 'inline-block'}}>Analytics</Typography>
        <Chip size="small" label="Alpha" sx={{marginLeft: 1, marginBottom: 1}}/>
        <br />
        <Typography variant="caption">
          <span>
            Share  your feedback
          </span>
          <span>
            &nbsp;on any major issues, bugs or improvements you'd like to see. Submit your feedback
          </span>
          <span>
          &nbsp;<Link href={FEEDBACK_LINK} target='__blank'>here</Link>!
          </span>
        </Typography>
      </Grid>
      <Grid item sx={{marginTop: 4}}>
        <TotalAccountUsage />
        <Divider sx={{marginTop: 4}}/>
      </Grid>
      <Grid item>
        <GatewayUsage
          gatewaysList={gatewaysList}
          days={7}
        />
        <Divider sx={{marginBottom: 5, marginTop: 5}}/>
      </Grid>      
      <Grid item>
        <Filters 
          setDateStartFilter={setDateStartFilter}
          setDateEndFilter={setDateEndFilter}
          dateStartFilter={dateStartFilter}
          dateEndFilter={dateEndFilter}
          gatewayFilter={gatewayFilter}
          setGatewayFilter={setGatewayFilter}
          setGatewaysList={setGatewaysList}
        />        
      </Grid>
      <Grid item sx={{marginTop: 4}}>
        {
          gatewayFilter && dateStartFilter && dateEndFilter && (
            <DashboardTabs
              dateStartFilter={dateStartFilter}
              dateEndFilter={dateEndFilter}
              gatewayFilter={gatewayFilter}
              analyticsTabDataArray = {dashboardsConfiguration}
            />
          )
        }
      </Grid>
    </Grid>
  )
}

export default Analytics;
