import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import { PinataDialogTitle } from '../../components/Common/MuiComponents';

interface DeleteKeyModalProps {
  confirmDeleteKey: () => void;
  keyName: string;
  deleteKeyModalOpen: boolean;
  setDeleteKeyModalOpen: (open: boolean) => void;
}

const DeleteKeyModal = ({
  confirmDeleteKey,
  keyName,
  deleteKeyModalOpen,
  setDeleteKeyModalOpen
}: DeleteKeyModalProps) => {
  return (
    <Dialog open={deleteKeyModalOpen} onClose={() => setDeleteKeyModalOpen(!deleteKeyModalOpen)}>
      <PinataDialogTitle onClose={() => setDeleteKeyModalOpen(false)}>Revoke API Key</PinataDialogTitle>
      <DialogContent dividers>
        <div>
          <Typography variant={'h6'}>
            Are you sure you want to revoke the key named <strong>"{keyName}"</strong>?
          </Typography>
          <p>This cannot be undone.</p>
        </div>

        <Button onClick={confirmDeleteKey} color={'error'}>
          Revoke Key
        </Button>
        <Button onClick={() => setDeleteKeyModalOpen(false)} variant={'text'}>
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteKeyModal;
