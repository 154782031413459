import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  Link,
  Tooltip,
  Typography
} from '@mui/material';
import { Plan } from '../../store/billing/types';
import { Gateways } from '../../store/gateways/types';
import { uniqueNamesGenerator, NumberDictionary, colors, animals } from 'unique-names-generator';
import { useTheme, styled } from '@mui/material/styles';
import picnicImg from '../../assets/images/dialogs/picnicplan.png';
import fiestaImg from '../../assets/images/dialogs/fiestaplan.png';
import carnivalImg from '../../assets/images/dialogs/carnivalplan.png';
import paloozaImg from '../../assets/images/dialogs/paloozaplan.png';
import { useHistory } from 'react-router-dom';
import { HighlightOff, HelpOutline } from '@mui/icons-material';
import { planTypes } from '../../helpers/enums';

const BASE_GATEWAY = process.env.REACT_APP_PINATA_GATEWAY_BASE || 'mypinata.cloud';

const PinataDialogTitleImage = styled(DialogTitle)<DialogTitleProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '240px'
}));

enum NewGatewayStatus {
  CREATING,
  CREATED,
  ERROR,
  NO_NEED
}

interface AfterUpdatePlanDialogProps {
  createGateway: (gatewayInfo: any, update: boolean) => any;
  newPlan: Plan;
  gateways: Gateways;
  onClose: () => any;
}

const adjectives = [
  'able',
  'above',
  'absent',
  'absolute',
  'abstract',
  'abundant',
  'academic',
  'acceptable',
  'accepted',
  'accessible',
  'accurate',
  'accused',
  'active',
  'actual',
  'acute',
  'added',
  'additional',
  'adequate',
  'adjacent',
  'administrative',
  'adorable',
  'advanced',
  'adverse',
  'advisory',
  'big',
  'binding',
  'biological',
  'bitter',
  'bizarre',
  'blank',
  'bright',
  'brilliant',
  'broad',
  'calm',
  'capable',
  'capitalist',
  'careful',
  'casual',
  'causal',
  'cautious',
  'central',
  'certain',
  'changing',
  'characteristic',
  'charming',
  'cheap',
  'cheerful',
  'chemical',
  'dear',
  'decent',
  'decisive',
  'deep',
  'defeated',
  'defensive',
  'defiant',
  'definite',
  'deliberate',
  'delicate',
  'effective',
  'efficient',
  'elaborate',
  'elderly',
  'eldest',
  'electoral',
  'electric',
  'electrical',
  'electronic',
  'elegant',
  'eligible',
  'faithful',
  'familiar',
  'famous',
  'fancy',
  'fantastic',
  'far',
  'fascinating',
  'fashionable',
  'fast',
  'general',
  'generous',
  'genetic',
  'gentle',
  'genuine',
  'geographical',
  'giant',
  'gigantic',
  'given',
  'glad',
  'glamorous',
  'hidden',
  'high',
  'hilarious',
  'historic',
  'historical',
  'hollow',
  'holy',
  'imaginative',
  'immediate',
  'immense',
  'imperial',
  'implicit',
  'important',
  'impossible',
  'impressed',
  'impressive',
  'improved',
  'junior',
  'just',
  'keen',
  'key',
  'kind',
  'known',
  'labour',
  'large',
  'late',
  'lazy',
  'leading',
  'left',
  'legal',
  'legislative',
  'mad',
  'magic',
  'magnetic',
  'magnificent',
  'main',
  'major',
  'managerial',
  'managing',
  'manual',
  'many',
  'naval',
  'near',
  'nearby',
  'neat',
  'necessary',
  'negative',
  'neighbouring',
  'obedient',
  'objective',
  'obliged',
  'obvious',
  'occasional',
  'occupational',
  'odd',
  'official',
  'passive',
  'past',
  'patient',
  'payable',
  'peaceful',
  'peculiar',
  'perfect',
  'permanent',
  'persistent',
  'personal',
  'petite',
  'quick',
  'quickest',
  'quiet',
  'rainy',
  'random',
  'rapid',
  'rare',
  'rational',
  'raw',
  'ready',
  'real',
  'realistic',
  'rear',
  'reasonable',
  'recent',
  'secondary',
  'secret',
  'secure',
  'select',
  'selected',
  'selective',
  'solid',
  'sophisticated',
  'tiny',
  'tired',
  'top',
  'total',
  'tough',
  'traditional',
  'tragic',
  'tremendous',
  'tricky',
  'tropical',
  'urban',
  'urgent',
  'used',
  'useful',
  'voluntary',
  'wonderful',
  'wooden',
  'working',
  'worldwide',
  'worried',
  'worrying',
  'worthwhile',
  'worthy',
  'written',
  'wrong'
];

const Images: Record<number, any> = {
  0: '',
  1: picnicImg,
  2: fiestaImg,
  3: carnivalImg,
  6: paloozaImg
};

export const AfterUpdatePlanDialog = (props: AfterUpdatePlanDialogProps) => {
  const { newPlan, gateways, onClose } = props;
  const [open, setOpen] = useState(false);
  const [newGatewayName, setNewGatewayName] = useState('');
  const [newGatewayStatus, setNewGatewayStatus] = useState<NewGatewayStatus>(NewGatewayStatus.NO_NEED);
  const history = useHistory();
  const theme = useTheme();

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const handleNewGateway = async () => {
    setNewGatewayStatus(NewGatewayStatus.CREATING);
    const numberDictionary = NumberDictionary.generate({ min: 1, max: 999 });
    const lowerCaseName: string = uniqueNamesGenerator({
      dictionaries: [colors, adjectives, animals, numberDictionary],
      style: 'lowerCase',
      separator: '-'
    });

    const newGatewayRes = await props.createGateway({ subdomain: lowerCaseName }, false);

    if (newGatewayRes) {
      setNewGatewayName(lowerCaseName);
      setNewGatewayStatus(NewGatewayStatus.CREATED);
    } else {
      setNewGatewayStatus(NewGatewayStatus.ERROR);
    }
  };

  useEffect(() => {
    setOpen(true);
    if (newPlan.type === planTypes.NEW_FREE.type && gateways.count > 0) {
      setNewGatewayName(gateways.rows[0].domain);
      setNewGatewayStatus(NewGatewayStatus.CREATED);
      return;
    }

    if (newPlan.gateway_count_limit > 0 && gateways.count === 0) {
      handleNewGateway();
    } else if (gateways.count > 0) {
      setNewGatewayStatus(NewGatewayStatus.NO_NEED);
    }
  }, []);

  const planImg = Images[newPlan?.type || 1];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
      <PinataDialogTitleImage
        sx={{ padding: 0, backgroundImage: `url(${planImg}), linear-gradient(180deg, #D3E5FF 0%, #CCF1DF 100%)` }}
        component={'div'}
      >
        <Box sx={{ px: 12, color: theme.palette.common.black }}>
          <Typography>New Plan</Typography>
          <Typography variant="h1">{newPlan?.nickname}</Typography>
        </Box>
        <HighlightOff
          sx={{ alignSelf: 'start', ml: 'auto', mr: 3, mt: 3, cursor: 'pointer' }}
          aria-label="close"
          onClick={handleClose}
          color={'primary'}
        />
      </PinataDialogTitleImage>
      <DialogContent sx={{ paddingX: 12, marginTop: 2 }}>
        {newPlan?.type === planTypes.NEW_FREE.type ? (
          <>
            <Typography variant="body2" sx={{ lineHeight: 3 }}>
              Congrats on signing up for a free Pinata plan!
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
              Your free plan comes with a
              <Typography fontWeight={900} component={'span'}>
                {' '}
                limited dedicated gateway{' '}
              </Typography>
              which allows you to retrieve files faster than a public gateway.
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 3 }}>
              We have
              <Typography fontWeight={900} component={'span'}>
                {' '}
                automatically created a gateway{' '}
              </Typography>
              for you to use!
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2" sx={{ lineHeight: 3 }}>
              Congrats! You have been successfully upgraded to the {newPlan?.nickname} Plan.
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 3 }}>
              You can now pin up to{' '}
              <Typography fontWeight={900} component={'span'}>
                {newPlan?.pin_total_limit} files{' '}
              </Typography>
              and store up to{' '}
              <Typography fontWeight={900} component={'span'}>
                {newPlan?.storage_limit_gb} GB.
              </Typography>
            </Typography>
          </>
        )}
        {newGatewayStatus === NewGatewayStatus.CREATED && (
          <>
            {newPlan?.type !== 5 && (
              <>
                <Typography variant="body2">
                  We have{' '}
                  <Typography fontWeight={900} component={'span'}>
                    automatically created a gateway
                  </Typography>{' '}
                  for you which includes{' '}
                  <Typography fontWeight={900} component={'span'}>
                    {newPlan?.bandwidth_limit_gb} GB.
                  </Typography>{' '}
                  of bandwidth!
                </Typography>
              </>
            )}
            <Typography sx={{ marginTop: newPlan.type === planTypes.NEW_FREE.type ? 1 : 4 }}>
              Your dedicated gateway name
              <Tooltip
                title={'You can customize your gateways under the gateway menu section.'}
                placement={'top'}
                arrow
              >
                <HelpOutline sx={{ cursor: 'pointer' }} />
              </Tooltip>
            </Typography>
            <Box
              sx={{
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[500],
                borderRadius: '16px',
                marginBottom: 4
              }}
            >
              <Typography fontWeight={900}>
                {newGatewayName}.{BASE_GATEWAY}
              </Typography>
            </Box>
          </>
        )}{' '}
        {newGatewayStatus === NewGatewayStatus.CREATING && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="body2">We're creating a gateway for you</Typography>
            <CircularProgress color="primary" />
          </Box>
        )}
        {newGatewayStatus === NewGatewayStatus.NO_NEED && (
          <Typography variant="body2">
            You also can have up to{' '}
            <Typography fontWeight={900} component={'span'}>
              {newPlan?.gateway_count_limit}
            </Typography>{' '}
            dedicated gateways, which includes{' '}
            <Typography fontWeight={900} component={'span'}>
              {newPlan?.bandwidth_limit_gb} GB
            </Typography>{' '}
            of bandwidth!
          </Typography>
        )}
        {newGatewayStatus === NewGatewayStatus.ERROR && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography variant="body2">Whoops! We couldn't auto-create gateway at this time</Typography>
            <Button onClick={handleNewGateway}>Try Again</Button> or <Link href={'/gateway'}>Go to gateways page</Link>
          </Box>
        )}
        <Typography variant={'caption'}>Want to learn more about your plan? Curious about our gateways? </Typography>
        <Typography variant={'caption'}>
          Check out the Pinata <Link href={'https://docs.pinata.cloud/gateways'}>docs</Link>!{' '}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 4 }}>
        <Button
          onClick={() => {
            handleClose();
            history.push('/pinmanager');
          }}
        >
          Start Exploring
        </Button>
      </DialogActions>
    </Dialog>
  );
};
