import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Container } from '@mui/material';
import AccountSecurityCard from './AccountSecurityCard';
import AccountDetailsCard from './AccountDetailsCard';
import AccountManagementCard from './AccountManagement';
import AccountSettingsCard from './AccountSettingsCard';
import AccountProfilePicture from './AccountProfilePicture';
import { CardsBox } from '../../components/Common/MuiComponents';
import { cancelUserAccount, changeEmail, loadUserInfo, restoreCanceledAccount } from '../../store/user/user.actions';
import { regionPermissions, userPinPolicy } from '../../actions/pin.actions';
import { setAlert } from '../../store/alert/alert.actions';
import { MetricsState } from '../../store/metrics/types';
import { BillingState } from '../../store/billing/types';
import { PinataState } from '../../store/types';
import { AccountUISettingsCard } from './AccountUISettings';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface AccountProps {
  changeEmail: any;
  user: any;
  setAlert: (message: string, type: string) => void;
  regionPermissions: any;
  data: any;
  userPinPolicy: any;
  metrics: MetricsState;
  billing: BillingState;
  cancelUserAccount: any;
  restoreCanceledAccount: any;
  loadUserInfo: any;
}

const Account = ({
  changeEmail,
  user,
  setAlert,
  regionPermissions,
  billing,
  cancelUserAccount,
  restoreCanceledAccount,
  loadUserInfo
}: AccountProps) => {
  const { profilePictureFlag } = useFlags();
  
  useEffect(() => {
    regionPermissions();
  }, []);
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" sx={{ mb: 2 }}>
        Profile
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Grid item>
            <AccountDetailsCard changeEmail={changeEmail} setAlert={setAlert} user={user.user} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <AccountSecurityCard setAlert={setAlert} />
        </Grid>
        {
          profilePictureFlag ? (
            <>
              <Grid item xs={12} md={6}>
                <AccountProfilePicture setAlert={setAlert} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AccountSettingsCard setAlert={setAlert} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AccountUISettingsCard />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <AccountUISettingsCard />
              </Grid>
              <Grid item xs={12} md={6}>
                <AccountSettingsCard setAlert={setAlert} />
              </Grid>
            </>
          )
        }
        <Grid item xs={12} md={6}>
          <AccountManagementCard
            setAlert={setAlert}
            user={user}
            currentPlan={billing.activePricingPlan}
            cancelUserAccount={cancelUserAccount}
            restoreCanceledAccount={restoreCanceledAccount}
            loadUserInfo={loadUserInfo}
            billing={billing}
            changeEmail={changeEmail}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    user: state.user,
    data: state.data,
    metrics: state.metrics,
    billing: state.billing
  };
};

export default connect(mapStateToProps, {
  changeEmail,
  setAlert,
  regionPermissions,
  userPinPolicy,
  cancelUserAccount,
  restoreCanceledAccount,
  loadUserInfo
})(Account);
