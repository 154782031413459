import { useState } from 'react';
import {
  Button,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
  Box,
  Typography,
  Link,
  TableContainer, useTheme
} from '@mui/material';
import DeleteKeyModal from './DeleteKeyModal';
import { makeDatePretty } from 'helpers/pretty';
import {DeleteForeverOutlined, FileCopy} from '@mui/icons-material';
import {getBorderedBoxStyles} from "../../themes/colors";

interface KeyTableProps {
  keys: any;
  pageLimit: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalKeys: number;
  setAlert: any;
  deleteV2Key: any;
}

const KeyTable = ({
  keys,
  pageLimit,
  totalKeys,
  currentPage,
  setCurrentPage,
  setAlert,
  deleteV2Key
}: KeyTableProps) => {
  const [deleteKeyModalOpen, setDeleteKeyModalOpen] = useState(false);
  const [keyInfo, setKeyInfo] = useState<any | null>(null);
  const theme = useTheme();

  const handlePageChange = (pageToChangeTo: number) => {
    setCurrentPage(pageToChangeTo);
  };

  const openDeleteModal = (keyInfo: any) => {
    setKeyInfo(keyInfo);
    setDeleteKeyModalOpen(true);
  };

  const confirmDeleteKey = async () => {
    setDeleteKeyModalOpen(false);
    setAlert('Revoking key...', 'info');
    await deleteV2Key(keyInfo.id);
    setKeyInfo(null);
  };

  const copyKey = (key: string) => {
    navigator.clipboard.writeText(key);
    setAlert('Copied!');
  };

  const renderRow = (row: { key: any; created_at: any }, key: any) => {
    return (
      <TableRow key={key}>
        <TableCell>
          <Link onClick={() => copyKey(row.key)}>
            {row.key} <FileCopy fontSize="small" sx={{ verticalAlign: 'middle' }} />
          </Link>
        </TableCell>
        <TableCell>{makeDatePretty(new Date(row.created_at * 1000).toISOString())}</TableCell>
        <TableCell align={"right"} sx={{pr: 0}}>
          <Button
            onClick={() => openDeleteModal(row)}
            size={"small"}
            sx={{...getBorderedBoxStyles(theme)}}
            startIcon={<DeleteForeverOutlined sx={{color: theme.palette.error.main}} />}
            variant={"outlined"}
          >
            Revoke
          </Button>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell scope="col">Key</TableCell>
            <TableCell scope="col">Date Issued</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{keys.map((k: any, key: any) => renderRow(k, key))}</TableBody>
      </Table>
      {keyInfo && (
        <DeleteKeyModal
          keyInfo={keyInfo}
          setDeleteKeyModalOpen={setDeleteKeyModalOpen}
          deleteKeyModalOpen={deleteKeyModalOpen}
          confirmDeleteKey={confirmDeleteKey}
        />
      )}
    </TableContainer>
  );
};

export default KeyTable;
