import { useState } from 'react';
import GatewayVirtualList from './GatewayVirtualList';
import { Button, Dialog, DialogActions, DialogContent, Typography, Box } from '@mui/material';
import { PinataDialogTitle } from 'components/Common/MuiComponents';
import { getErrorMessage } from '../../helpers/error.helper';
import type { GatewayRow, GatewaysState } from '../../store/gateways/types';

interface SelectGatewayModalProps {
  modalOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
  pinInfo: any;
  gateways: GatewaysState;
  getAllGateways: any;
  setRootContent: any;
}

const SelectGatewayModal = (props: SelectGatewayModalProps) => {
  const { modalOpen, toggleModal, pinInfo, gateways, getAllGateways, setRootContent } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [gatewaysLoading, setGatewaysLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [gatewayUpdateStatus, setGatewayUpdateStatus] = useState<string | null>(null);

  const loadGateways = async (itemNum: number) => {
    if (itemNum > 0) {
      setGatewaysLoading(true);
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      // call encpoint to fetch new gateways for virtual list
      await getAllGateways(newPage);
      setGatewaysLoading(false);
    }
  };

  const handleModalClose = async () => {
    if (loading) {
      return;
    }
    setCurrentPage(1);
    setGatewayUpdateStatus(null);
    toggleModal(false);
  };

  const handleGatewaySelect = async (gateway: GatewayRow) => {
    setGatewayUpdateStatus('UPDATING');
    try {
      await setRootContent(gateway.id, pinInfo.id);
      setGatewayUpdateStatus('SUCCESS');
    } catch (e) {
      setGatewayUpdateStatus(getErrorMessage(e));
    } finally {
      setGatewaysLoading(false);
      toggleModal(false);
    }
  };

  return (
    <Dialog fullWidth open={modalOpen} onClose={handleModalClose}>
      <PinataDialogTitle onClose={handleModalClose}>Select Gateway</PinataDialogTitle>
      <DialogContent dividers={!!gatewayUpdateStatus}>
        {gatewayUpdateStatus ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5">{gatewayUpdateStatus}</Typography>
          </Box>
        ) : (
          <GatewayVirtualList
            gateways={gateways?.gateways?.rows || []}
            loadGateways={loadGateways}
            totalGateways={gateways?.gateways?.count}
            gatewaysLoading={gatewaysLoading}
            handleGatewaySelect={handleGatewaySelect}
          />
        )}
      </DialogContent>
      <DialogActions>
        {gatewayUpdateStatus === 'SUCCESS' && (
          <Button fullWidth onClick={handleModalClose} className={'btn btn-primary'} disabled={loading}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SelectGatewayModal;
