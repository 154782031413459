type RegExpType = RegExp;

type FieldValidations = {
  [key: string]:
    | { value: RegExpType | boolean | number; message: string }
    | boolean
    | ((val: string) => boolean | string)
    | { [key: string]: (val: string) => boolean | string };
};

export const validationsRegex: Record<string, RegExpType> = {
  email:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  oneTimeCode: /^[0-9]{6}$/,
  // any encoding letters allowed
  name: /^[a-zA-Z\s]+$/,
  password:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
};

export const emailFieldRules: FieldValidations = {
  required: {
    value: true,
    message: "Email is required",
  },
  pattern: {
    value: validationsRegex.email,
    message: "Email is invalid",
  },
};

export const namesFieldRules: FieldValidations = {
  required: {
    value: true,
    message: "Field is required",
  },
  pattern: {
    value: validationsRegex.name,
    message: "Only letters are allowed",
  },
  minLength: {
    value: 1,
    message: "Must Contain at least 1 character",
  },
};

export const passwordFieldRules: FieldValidations = {
  required: {
    value: true,
    message: "Password should include at least 8 characters, 1 number, 1 uppercase, 1 lowercase, and 1 special character.",
  },
  minLength: {
    value: 7,
    message: "Password can't be less than 8 characters",
  },
  pattern: {
    value: validationsRegex.password,
    message:
      "Password should include at least 8 characters, 1 number, 1 uppercase, 1 lowercase, and 1 special character.",
  },
};

export const codeFieldRules: FieldValidations = {
  required: {
    value: true,
    message: "Code is required",
  },
  minLength: {
    value: 6,
    message: "Must Contain 6 Characters",
  },
  maxLength: {
    value: 6,
    message: "Must Contain 6 Characters",
  },
  pattern: {
    value: validationsRegex.oneTimeCode,
    message: "Except only 6 digits positive number",
  },
};
