import { useState, useEffect } from 'react';
import {
  generateNewApiKey,
  getV2Keys,
  revokeApiKey,
  createNewKey,
  deleteV2Key
} from '../../store/apiKeys/apikeys.actions';
import { setAlert } from '../../store/alert/alert.actions';
import KeyTable from './KeyTable';
import { connect } from 'react-redux';
import TableNav from './TableNav';
import { PinataState } from '../../store/types';
import {Box, Button, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import { UserState } from '../../store/user/types';
import { ApiKeysState } from '../../store/apiKeys/types';
import { Add } from '@mui/icons-material';

interface V2ApiKeysProps {
  getV2Keys: (offset: number, limit: number) => void;
  apiKeys: ApiKeysState;
  setAlert: any;
  createNewKey: (...props: any) => any;
  revokeApiKey: (...props: any) => any;
  deleteV2Key: (...props: any) => any;
  user: UserState;
}

const V2ApiKeys = ({ getV2Keys, apiKeys, setAlert, createNewKey, revokeApiKey, deleteV2Key, user }: V2ApiKeysProps) => {
  const [keys, setKeys] = useState<any>([]);
  const [totalKeys, setTotalKeys] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  useEffect(() => {
    const offset = pageLimit * currentPage - pageLimit;
    getV2Keys(offset, pageLimit);
  }, []);

  useEffect(() => {
    if (apiKeys.v2Keys) {
      setKeys(apiKeys.v2Keys);
      setTotalKeys(apiKeys.v2Keys.length);
    }
  }, [apiKeys]);

  useEffect(() => {
    const offset = pageLimit * currentPage - pageLimit;
    getV2Keys(offset, pageLimit);
  }, [currentPage]);

  const generateNewV2Key = async () => {
    setAlert('Creating key...', 'info');
    await createNewKey();
    setAlert('API key created!', 'success');
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container display="flex" flexDirection="column">
      <Grid item display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box >
        { !isMobile &&
            <Typography variant="h3">Submarine Keys</Typography>
        }
        </Box>
        <Box>
          <Button fullWidth startIcon={<Add />} onClick={generateNewV2Key} color="primary" size={"small"}>
            New Key
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <KeyTable
          deleteV2Key={deleteV2Key}
          keys={keys}
          pageLimit={pageLimit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalKeys={totalKeys}
          setAlert={setAlert}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    apiKeys: state.apiKeys,
    user: state.user
  };
};
export default connect(mapStateToProps, {
  deleteV2Key,
  getV2Keys,
  setAlert,
  generateNewApiKey,
  revokeApiKey,
  createNewKey
})(V2ApiKeys);
