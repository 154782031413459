import ReactECharts from 'echarts-for-react';
import { prettySize } from 'helpers/pretty';
import { purple, pink, teal } from './../../../../themes/colors';

type Props = {
  data: any;
  unit: string;
  showLabel?: 'normal' | 'prettysize';
};

const PieChart = ({data, unit, showLabel='normal'}: Props) => {
  let chartOptions = {
    color: [
      purple[300],
      purple[500],
      purple[700],
      pink[700],
      pink[500],
      pink[300],
      teal[700],
      teal[500],
      teal[300],
      purple[100]
    ],
    tooltip: {
      trigger: 'item',
      formatter: '{b}&nbsp;({d}%)&nbsp;{c}&nbsp;{a}',
      position: 'right'
    },
    series: [
      {
        name: unit,
        type: 'pie',
        selectedMode: 'single',
        data: data,
        label: {
          show: showLabel,
          position: 'inner',
          formatter(param: any) {
            switch (showLabel) {
              case 'normal':
                return `${param.value}`;
                break;
              case 'prettysize':
                return `${prettySize(param.value)}`;                
                break;            
              default:
                break;
            }
          }          
        }, 
        // radius: ['40%', '90%'], <-Doughnut settings
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  return (
    <ReactECharts
      style={{width: '100%', height: '100%'}}
      notMerge={true}
      lazyUpdate={true}
      option={chartOptions}
    />
  )
}

export default PieChart;
