import { Auth, Amplify } from 'aws-amplify';
import awsauth from '../helpers/auth/awsauth';
import awsconfig from '../helpers/auth/awsconfig';
import { SignOutOpts } from '@aws-amplify/auth/lib-esm/types';
import type { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';

Amplify.configure(awsconfig);
Auth.configure({ oauth: awsauth });

export const getCurrentSession = async (): Promise<CognitoUserSession> => {
  try {
    return await Auth.currentSession();
  } catch (error) {
    console.log('getCurrentSession error: ', error);
    throw error;
  }
};

export const signOut = async (options: SignOutOpts) => {
  try {
    return await Auth.signOut(options);
  } catch (error) {
    console.log('logUserOut error: ', error);
    throw error;
  }
};

export const signIn = async (email: string, password: string): Promise <CognitoUser | any> => {
  try {
    return await Auth.signIn(email, password);
  } catch (error) {
    console.log('logUserIn error: ', error);
    throw error;
  }
};

export const signUp = async (params: { username: string; password: string; attributes?: Record<string, string> }) => {
  try {
    return await Auth.signUp(params);
  } catch (error) {
    console.log('signUp error: ', error);
    throw error;
  }
};

export const confirmSignIn = async (
  user: CognitoUser,
  code: string,
  type: 'SOFTWARE_TOKEN_MFA' | 'SMS_MFA' | null | undefined = 'SOFTWARE_TOKEN_MFA'
) => {
  try {
    return await Auth.confirmSignIn(user, code, type);
  } catch (error) {
    console.log('confirmSignIn error: ', error);
    throw error;
  }
};

export const confirmSignUp = async (email: string, code: string) => {
  try {
    await Auth.confirmSignUp(email, code);
  } catch (error) {
    console.log('confirmSignUp error: ', error);
    throw error;
  }
};

export const resendSignUp = async (email: string) => {
  try {
    return await Auth.resendSignUp(email);
  } catch (error) {
    console.log('reSendSignUp error: ', error);
    throw error;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    await Auth.forgotPassword(email);
  } catch (error) {
    console.log('forgotPassword error: ', error);
    throw error;
  }
};

export const forgotPasswordSubmit = async (email: string, code: string, newPassword: string) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    console.log('forgotPasswordSubmit error: ', error);
    throw error;
  }
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
  try {
    const user: CognitoUser = await currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  } catch (error) {
    console.log('changePassword error: ', error);
    throw error;
  }
};

export const getPreferredMFA = async () => {
  try {
    const user: CognitoUser = await currentAuthenticatedUser();
    return await Auth.getPreferredMFA(user);
  } catch (error) {
    console.log('getPreferredMFA error: ', error);
    throw error;
  }
};

export const setPreferredMFA = async (
  mfaType: 'TOTP' | 'SMS' | 'NOMFA' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' = 'TOTP'
) => {
  try {
    const user: CognitoUser = await currentAuthenticatedUser();
    await Auth.setPreferredMFA(user, mfaType);
  } catch (error) {
    console.log('setPreferredMFA error: ', error);
    throw error;
  }
};

export const setupTOTP = async () => {
  try {
    const user: CognitoUser = await currentAuthenticatedUser();
    return await Auth.setupTOTP(user);
  } catch (error) {
    console.log('setupTOTP error: ', error);
    throw error;
  }
};

export const verifyTotpToken = async (challengeAnswer: string) => {
  try {
    const user: CognitoUser = await currentAuthenticatedUser();
    return await Auth.verifyTotpToken(user, challengeAnswer);
  } catch (error) {
    console.log('verifyTotpToken error: ', error);
    throw error;
  }
};

export const updateUserAttributes = async (attributes: Record<string, string>) => {
  try {
    const user: CognitoUser = await currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
  } catch (error) {
    console.log('updateAttributes error: ', error);
    throw error;
  }
};

export const currentAuthenticatedUser = async (): Promise<CognitoUser> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log('currentAuthenticatedUser error: ', error);
    throw error;
  }
};
