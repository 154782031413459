import { useState } from 'react';
import KeyInfoModal from './KeyInfoModal';
import NewKeyModal from './NewKeyModal';
import {Box, Button, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import { KeyPermissions } from '../../store/apiKeys/types';
import { Add } from '@mui/icons-material';

interface TableNavProps {
  setAlert: () => any;
  generateNewApiKey: (chosenPermissions: KeyPermissions, maxUses: string, keyName: string) => any;
}

const TableNav = ({ setAlert, generateNewApiKey }: TableNavProps) => {
  const [newKeyModalOpen, setNewKeyModalOpen] = useState(false);
  const [keyInfoModalOpen, setKeyInfoModalOpen] = useState(false);
  const [keyData, setKeyData] = useState(null);
  const theme = useTheme();

  const generateNewKey = async (permissions: KeyPermissions, maxUses: string, keyName: string) => {
    setNewKeyModalOpen(false);
    const keyInfo = await generateNewApiKey(permissions, maxUses, keyName);
    console.log('keyInfo', keyInfo)
    if (keyInfo) {
      setKeyData(keyInfo);
      setKeyInfoModalOpen(true);
    }
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
        <Grid item display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box >
            { !isMobile &&
              <Typography variant="h3">API Keys</Typography>
            }
          </Box>
          <Box>
            <Button fullWidth startIcon={<Add />} onClick={() => setNewKeyModalOpen(true)} color="primary" size={"small"} id={'APIKeys-add-key'}>
              New Key
            </Button>
          </Box>
        </Grid>
        {newKeyModalOpen && (
          <NewKeyModal
            generateNewKey={generateNewKey}
            setAlert={setAlert}
            newKeyModalOpen={newKeyModalOpen}
            setNewKeyModalOpen={setNewKeyModalOpen}
          />
        )}
        {keyData && (
          <KeyInfoModal
            setKeyInfoModalOpen={setKeyInfoModalOpen}
            keyInfoModalOpen={keyInfoModalOpen}
            keyData={keyData}
            setAlert={setAlert}
          />
        )}
    </>
  );
};

export default TableNav;
