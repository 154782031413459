import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  TextField,
  CircularProgress,
  Box,
  TextareaAutosize,
  FormControl,
  Button,
  Badge,
  useTheme,
  Typography
} from '@mui/material';
import { api } from 'services/api';
import { PinataDialogTitle } from 'components/Common/MuiComponents';

function ContentPreviewModal({ contentPreviewModalOpen, toggleModal, setAlert, pinInfo, action, loading }: any) {
  const [description, setDescription] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [descrInputCount, setDescrInputCount] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [edit, setIsEdit] = useState<boolean>(false);
  const fileInput = useRef<any>(null);
  const theme = useTheme();

  useEffect(() => {
    const callPreview = async () => {
      let title = '';
      let description = '';
      let thumbnail = '';
      try {
        const { data: previewData } = await api.get(`v2/pins/${pinInfo.id}/preview`);
        if (previewData) {
          title = previewData.title;
          description = previewData.description;
          thumbnail = previewData.preview_url.substring(
            previewData.preview_url.length,
            previewData.preview_url.lastIndexOf('/') + 1
          );
          setIsEdit(true);
        }
      } catch (error) {
        console.log(error);
        setAlert('Failed to load previous preview settings.', 'error');
      } finally {
        setTitle(title);
        setDescription(description);
        setDescrInputCount(description.length);
        setThumbnail(thumbnail);
      }
    };
    callPreview();
  }, []);

  const textareaChange = (event: any) => {
    const count = event.target.value.length;
    setDescription(event.target.value);
    setDescrInputCount(count);
  };

  const handleTitle = (event: any) => {
    const result = event.target.value;
    setTitle(result);
  };

  const setContentPreviewSettings = () => {
    const config = {
      fileId: pinInfo.id,
      title,
      description,
      thumbnail
    };
    action(config);
  };

  const handleFileInput = async (e: any) => {
    setUploading(true);
    const files = e.target.files;
    setThumbnail(files);
    setUploading(false);
  };

  return (
    <Dialog open={contentPreviewModalOpen} onClose={() => toggleModal(false)} fullWidth sx={{ p: 2 }}>
      <PinataDialogTitle onClose={() => toggleModal(false)}>Content Preview</PinataDialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <FormControl sx={{ width: '100%', py: 1 }}>
              <TextField
                fullWidth
                label="Title"
                id="title"
                onChange={handleTitle}
                value={title}
                disabled={edit}
                sx={{ my: 1 }}
              />

              <Box sx={{ my: 1 }}>
                <Badge
                  sx={{
                    width: '100%',
                    '& .MuiBadge-badge': {
                      transform: 'none',
                      m: 0.5
                    }
                  }}
                  badgeContent={edit ? null : `${descrInputCount} / 225`}
                  color="primary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                >
                  <TextareaAutosize
                    id="textarea"
                    onChange={textareaChange}
                    maxLength={225}
                    minRows={6}
                    value={description}
                    placeholder="Description"
                    disabled={edit}
                    style={{
                      width: '100%',
                      padding: theme.spacing(1),
                      borderRadius: theme.shape.borderRadius / 2
                    }}
                  />
                </Badge>
              </Box>
              <input
                accept="image/*"
                id="thumbnail"
                ref={fileInput}
                style={{ display: 'none' }}
                type="file"
                onChange={handleFileInput}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                <Button
                  onClick={(e) => fileInput.current && fileInput.current.click()}
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={edit}
                >
                  {uploading ? 'Uploading...' : 'Upload a file'}
                </Button>

                <Typography sx={{ textDecoration: 'underline', mx: 2 }}>
                  {(thumbnail?.length > 0 && thumbnail[0].name) || thumbnail}
                </Typography>
              </Box>
              {!edit && (
                <label htmlFor="thumbnail" style={{ fontSize: theme.typography.pxToRem(10) }}>
                  Add Thumbnail (max 1MB)
                  <span style={{ color: `${!thumbnail ? theme.palette.error.main : null}` }}>*</span>
                </label>
              )}
            </FormControl>
          </>
        )}
        {!edit && (
          <Button
            fullWidth
            color="primary"
            disabled={uploading || loading || !thumbnail}
            onClick={setContentPreviewSettings}
          >
            Set
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default connect(null, null)(ContentPreviewModal);
