import { Button, ButtonGroup, useTheme } from "@mui/material";
import { neutral } from '../../themes/colors';
import { PinData } from "store/data/types";

type Props = {
  selectedRows: Array<PinData>;
  defaultLabel: string;
  handleRemovePinModal: Function;
}

const SelectOptions = ({selectedRows, defaultLabel, handleRemovePinModal}: Props) => {
  const selectedRowsLength = selectedRows.length;
  const theme = useTheme();
  const buttonStyle = {
    backgroundColor: theme.palette.mode === 'light' ? neutral[50] : neutral[800],
    borderRadius: '4px',
    letterSpacing: 'normal',
    borderColor: neutral[700]
  };
  return (
    selectedRowsLength > 0 ? (
      <ButtonGroup variant='outlined' aria-label="outlined button group"
        color={"inherit"}
        sx={{
          height: 30,
          color: theme.palette.mode === 'light' ? neutral[700] : neutral[200],
        }}  
      >
        <Button
          sx={buttonStyle}
        >
          {selectedRowsLength} selected
        </Button>
        <Button 
          sx={buttonStyle}
          onClick={() => handleRemovePinModal(selectedRows)}
        >
          Remove files
        </Button>
      </ButtonGroup>
    ) : (
      <span style={{fontWeight: 500}}>{defaultLabel}</span>
    )
  )
}

export default SelectOptions;