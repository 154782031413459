import { useState } from 'react';
import { Dialog, DialogContent, CircularProgress, DialogActions, Button, Typography, Box } from '@mui/material';
import SelectDomain from './SelectDomain';
import { PinataDialogTitle } from 'components/Common/MuiComponents';
import type { GatewayRow } from 'store/gateways/types';

interface GatewayWizardProps {
  wizardModalOpen: boolean;
  setWizardModalOpen: (isOpen: boolean) => void;
  checkSubdomain: (name: string) => Promise<boolean>;
  createGateway: (options: { subdomain: string; restricted: boolean }, update?: boolean) => GatewayRow;
}

const GatewayWizard = ({ setWizardModalOpen, wizardModalOpen, checkSubdomain, createGateway }: GatewayWizardProps) => {
  const [subdomain, setSubdomain] = useState<string>('');
  const [gatewayCreating, setGatewayCreating] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const createNewGateway = async () => {
    try {
      setGatewayCreating(true);
      await createGateway({ subdomain, restricted: true });
      setWizardModalOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setGatewayCreating(false);
    }
  };

  return (
    <Dialog fullWidth open={wizardModalOpen} onClose={() => setWizardModalOpen(false)} sx={{ p: 2 }}>
      <PinataDialogTitle onClose={() => setWizardModalOpen(false)}>Create New Gateway</PinataDialogTitle>
      <DialogContent dividers>
        {gatewayCreating ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <SelectDomain
              checkSubdomain={checkSubdomain}
              subdomain={subdomain}
              setSubdomain={setSubdomain}
              setIsDisabled={setIsDisabled}
            />
            <Typography variant="body2" sx={{ mt: 2, color: 'text.disabled', fontSize: 12 }}>
              All gateways are restricted by default. You can customize access in the developer’s section under
              restricted access.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" id="gateways-wizard-cancel-item" onClick={() => setWizardModalOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => createNewGateway()}
          id="gateways-wizard-create-item"
          disabled={isDisabled || gatewayCreating}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GatewayWizard;
