import {useQuery} from "@tanstack/react-query";
import { managedApi } from "services/api";
import {GatewayRow} from "../../store/gateways/types";

export const useGetGatewaySettings = (activeGateway: GatewayRow | null) => {
  const { isLoading, error, data } = useQuery({
    queryKey: ['gatewaySettings', activeGateway?.id],
    queryFn: () => managedApi.get(`gateways/${activeGateway?.id}`).then((res) => res.data),
    enabled: !!activeGateway?.id
  })
  return {isLoading, error, data};
};
