import { useMemo, useState, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Box, Button, Link as MUILink, Typography, useTheme, styled, alpha, Tab, Tabs } from '@mui/material';

import RemovePinModal from './RemovePinModal';
import MetadataModal from './MetadataModal';
import GenerateAccessTokenModal from './GenerateAccessTokenModal';
import MakePublicModal from './MakePublicModal';
import ConfirmationModal from 'pages/Gateway/ConfirmationModal';
import SelectGatewayModal from '../Gateway/SelectGatewayModal';
import ContentPreviewModal from './ContentPreviewModal';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Delete,
  EditOutlined,
  FolderOutlined,
  PreviewOutlined,
  ShareOutlined,
  MoreVert,
  Check,
  Stop
} from '@mui/icons-material';

import { setAlert } from '../../store/alert/alert.actions';
import { getAllGateways, getGatewayDetails, setRootContent } from '../../store/gateways/gateway.actions';
import {
  putHashMetadata,
  removePin,
  changeHashPinPolicy,
  generateAccessToken,
  listFolderContents,
  makeSubmarinedFilePublic,
  setPinContentPreviewOptions,
  removePinContentPreviewOptions
} from '../../actions/pin.actions';
import type { MetricsState } from '../../store/metrics/types';
import type { BillingState } from '../../store/billing/types';
import type { PinData } from '../../store/data/types';
import type { GatewaysState } from '../../store/gateways/types';

import { planTypes } from 'helpers/enums';
import { validateMetricsLimits } from '../../helpers/validations';
import { makeDatePretty, prettySize } from 'helpers/pretty';
import { getBorderedBoxStyles, neutral } from '../../themes/colors';
import DocumentDuplicate from '../../assets/images/icons/DocumentDuplicate';
import Eye from '../../assets/images/icons/Eye';
import SelectOptions from './SelectOptions';
import {managedApi} from "../../services/api";
import {useQuery} from "@tanstack/react-query";
import {GatewayRow} from "../../store/gateways/types";
import {useGetGatewaySettings} from "../../hooks/queries/useGetGatewaySettings";

const IS_BULK_UNPIN_ENABLED = false;

interface PinTableProps {
  files: any;
  fileCount: number;
  setAlert: (message: string | ReactElement, type: string) => void;
  putHashMetadata: any;
  removePin: any;
  currentPage: number;
  setCurrentPage: (pageChangeTo: number) => void;
  submitTableFilters: () => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  changeHashPinPolicy: (
    hash: string,
    policy: {
      version: number;
      regions: any[];
    }
  ) => void;
  gatewayToUse: string;
  selectedPinStatus: 'pinned' | 'unpinned' | 'all';
  generateAccessToken: (time: number, id: string) => void;
  listFolderContents: any;
  folderDetails: any;
  makeSubmarinedFilePublic: (pinInfo: any) => any;
  billing: BillingState;
  metrics: MetricsState;
  setPinContentPreviewOptions: (config: any) => void;
  removePinContentPreviewOptions: (id: string) => void;
  gateways: GatewaysState;
  getAllGateways: any;
  setRootContent: (gatewayId: string, pin: any) => any;
  getGatewayDetails: (gatewayId: string) => any;
  user: any;
  currentTab: string;
  setCurrentTab: (tabValue: string) => void;
  loadingDone: boolean;
  setSubmarined: Function
}

const IconContainer = styled(Box)(({ theme }) => {
  return {
    width: '30px',
    height: '30px',
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius / 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? alpha(neutral[100], 0.6) : neutral[800]
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main
    },
    ...getBorderedBoxStyles(theme)
  };
});

const getFileName = (row: PinData, folderDetails: any) => {
  //  This would mean we are inside of a V2 directory
  if (folderDetails && row.originalname) {
    const items = row.originalname.split('/');
    if (items.length > 1) {
      items.shift();
    }
    return items.join('/');
  } else {
    if (row.name) {
      return row.name;
    }
    if (row.metadata && typeof row.metadata === 'string') {
      const parsed = JSON.parse(row.metadata);
      if (parsed.name) {
        return parsed.name;
      }
    } else if (row.metadata && row.metadata.name) {
      return row.metadata.name;
    }
  }

  return 'No name set';
};

const PinTable = (props: PinTableProps) => {
  const {
    files,
    setAlert,
    putHashMetadata,
    removePin,
    currentPage,
    setCurrentPage,
    submitTableFilters,
    rowsPerPage,
    gatewayToUse,
    selectedPinStatus,
    generateAccessToken,
    listFolderContents,
    folderDetails,
    makeSubmarinedFilePublic,
    billing,
    metrics,
    setPinContentPreviewOptions,
    removePinContentPreviewOptions,
    gateways,
    getAllGateways,
    setRootContent,
    getGatewayDetails,
    currentTab,
    setCurrentTab,
    loadingDone,
    setSubmarined
  } = props;
  const [showEditMetaDataModal, setShowEditMetaDataModal] = useState<boolean>(false);
  const [keyValues, setKeyValues] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string>('');
  const [newKey, setNewKey] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');
  const [hash, setHash] = useState<string | Array<PinData>>('');
  const [removePinModalOpen, setRemovePinModalOpen] = useState<boolean>(false);
  const [pinInfo, setPin] = useState<any | null>(null);
  const [v2Id, setV2Id] = useState<string | null>(null);
  const [cidAccessModalOpen, setCIDAccessModalOpen] = useState<boolean>(false);
  const [makePublicModalOpen, setMakePublicModalOpen] = useState<boolean>(false);
  const [contentPreviewModalOpen, setContentPreviewModalOpen] = useState<boolean>(false);
  const [openConfirmDeletionDialog, setOpenConfirmDeletionDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectGatewayModalOpen, setSelectGatewayModalOpen] = useState<boolean>(false);
  const [rowsSelected, setRowsSelected] = useState<Array<PinData>> ([]);
  const [activeGateway, setActiveGateway] = useState<GatewayRow | null>(null);
  const { isLoading, error, data: gatewaySettingsData } = useGetGatewaySettings(activeGateway);
  const theme = useTheme();

  // Fix pagination workaround
  useEffect(() => {
    if (files?.length === 0 && currentPage > 0){
      setCurrentPage(0);
    }
  }, [files])

  const canAddNewKeyValue = useMemo(() => newKey && newValue, [newKey, newValue]);
  const isAnyMetricLimitReached = useMemo(
    () => validateMetricsLimits(metrics.metrics, billing),
    [metrics?.metrics, billing]
  );

  const submarinedColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
      sortable: false,
      minWidth: 150,
      renderHeader: () => (
        <SelectOptions
          selectedRows={rowsSelected}
          defaultLabel='Name'
          handleRemovePinModal={handleRemovePinModal}
        />
      ),
      renderCell: ({ row }: any) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {row.type === 'D' ? (
              <MUILink
                href="#"
                onClick={() => handleFolderSelect(row)}
                sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, fontWeight: 600 }}
              >
                {getFileName(row, folderDetails)}
                <IconContainer>
                  <FolderOutlined />
                </IconContainer>
              </MUILink>
            ) : (folderDetails && row.type === 'C') || row.type === 'F' ? (
              <MUILink
                href="#"
                onClick={() => generateLinkAndGo(row)}
                sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, whiteSpace: 'normal', wordBreak: 'break-all' }}
              >
                {getFileName(row, folderDetails)}{' '}
                <IconContainer>
                  <Eye />
                </IconContainer>
              </MUILink>
            ) : (
              <MUILink
                href={`https://${gatewayToUse}/ipfs/${getPath(row)}${isPreviewContentAvailable ? '?preview=1' : ''}${
                  currentGatewayToken
                    ? `${isPreviewContentAvailable ? '&' : '?'}pinataGatewayToken=${currentGatewayToken}`
                    : ''
                }`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, fontWeight: 600, whiteSpace: 'normal', wordBreak: 'break-all' }}
              >
                {getFileName(row, folderDetails)}
                <IconContainer>
                  <Eye />
                </IconContainer>
              </MUILink>
            )}{' '}
            <Typography variant="caption" sx={{ fontSize: theme.typography.pxToRem(12) }}>
              {makeDatePretty(row.date_pinned)} {prettySize(row.size, true)}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'ipfs_pin_hash',
      headerName: 'Content Identifier (CID)',
      editable: false,
      flex: 2,
      sortable: false,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <MUILink
            href="#"
            onClick={() => copyHash(row.ipfs_pin_hash)}
            sx={{ display: 'flex', alignItems: 'center', color: alpha(theme.palette.text.primary, 0.75) }}
          >
            {row.ipfs_pin_hash}
            <IconContainer>
              <DocumentDuplicate />
            </IconContainer>
          </MUILink>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 220,
      getActions: (params: any) => {
        const actionsArray = [
          <Button
            startIcon={<ShareOutlined sx={{ color: theme.palette.primary.main }} />}
            variant="outlined"
            onClick={() => {generateToken(params?.row)}}
            sx={{
              flexGrow: 1,
              m: theme.spacing(0, 0.75),
              ...getBorderedBoxStyles(theme)
            }}
            size={'small'}
            disabled={params.row.type === 'D'}
          >
            Share
          </Button>
        ];
        if (!folderDetails) {
          actionsArray.push(
            <GridActionsCellItem
              icon={<EditOutlined />}
              label="Edit Details"
              onClick={() => handleMetadata(params.row)}
              showInMenu
            />
          );
        }

        if (params?.row?.v2 && pinInfo?.type !== 'C' && !folderDetails) {
          actionsArray.push(
            <GridActionsCellItem
              icon={<Stop />}
              label="Make Public"
              onClick={() => stopSubmarining(params?.row)} showInMenu
            />
          );
        }

        if (billing?.activePricingPlan?.name !== 'Free') {
          actionsArray.push(
            <GridActionsCellItem
              icon={<Check />}
              label="Set gateway as a root"
              onClick={() => handleSetGatewayRoot(params?.row)}
              divider
              showInMenu
            />
          );
        }

        if (!params?.row?.date_unpinned && !folderDetails) {
          actionsArray.push(
            <GridActionsCellItem
              icon={<Delete color="error" />}
              label="Delete File"
              onClick={() => handleRemovePinModal(params?.row)}
              showInMenu
            />
          );
        }

        return actionsArray;
      },
      renderHeader: () => null
    }
  ];

  const getShareableUrl = (row: any) => {
    const link = `https://${gatewayToUse}/ipfs/${getPath(row)}${isPreviewContentAvailable ? '?preview=1' : ''}${
      currentGatewayToken
        ? `${isPreviewContentAvailable ? '&' : '?'}pinataGatewayToken=${gatewaySettingsData.item.settings.restrictionPolicies.accessTokens[0].value}`
        : ''
    }`
    return link;
  }

  const publicColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
      sortable: false,
      minWidth: 150,
      renderHeader: () => (
        <SelectOptions
          selectedRows={rowsSelected}
          defaultLabel='Name'
          handleRemovePinModal={handleRemovePinModal}
        />
      ),
      renderCell: ({ row }: any) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {row.type === 'C' ? (
              <MUILink
                href="#"
                onClick={() => generateLinkAndGo(row)}
                sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, fontWeight: 600, whiteSpace: 'normal', wordBreak: 'break-all' }}
              >
                {getFileName(row, folderDetails)}
                <IconContainer>
                  <Eye />
                </IconContainer>
              </MUILink>
            ) : (
              <MUILink
                href={getShareableUrl(row)}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'flex', alignItems: 'center', color: theme.palette.text.primary, whiteSpace: 'normal', wordBreak: 'break-all' }}
              >
                {getFileName(row, folderDetails)}
                <IconContainer>
                  <Eye />
                </IconContainer>
              </MUILink>
            )}

            <Typography variant="caption" sx={{ fontSize: theme.typography.pxToRem(12) }}>
              {makeDatePretty(row.date_pinned)} {prettySize(row.size, true)}
            </Typography>
          </Box>
        );
      }
    },
    {
      field: 'ipfs_pin_hash',
      headerName: 'Content Identifier (CID)',
      editable: false,
      flex: 2,
      sortable: false,
      minWidth: 150,
      renderCell: ({ row }: any) => {
        return (
          <MUILink
            href="#"
            onClick={() => copyHash(row.ipfs_pin_hash)}
            sx={{ display: 'flex', alignItems: 'center', color: alpha(theme.palette.text.primary, 0.75) }}
          >
            {row.ipfs_pin_hash}
            <IconContainer>
              <DocumentDuplicate />
            </IconContainer>
          </MUILink>
        );
      }
    },
    ...(selectedPinStatus !== 'pinned'
      ? [
          {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            sortable: false,
            renderCell: ({ row }: any) =>
              <Box sx={{fontWeight: 'initial'}}>{row?.date_unpinned ? makeDatePretty(row?.date_unpinned) : 'Currently pinned'}</Box>
          }
        ]
      : []),
    {
      field: 'actions',
      type: 'actions',
      minWidth: 220,
      getActions: (params: any) => {
        const actionsArray = [
          <Button
            startIcon={<ShareOutlined sx={{ color: theme.palette.primary.main }} />}
            variant="outlined"
            onClick={() => {generateToken(params?.row)}}
            sx={{
              flexGrow: 1,
              m: theme.spacing(0, 0.75),
              ...getBorderedBoxStyles(theme)
            }}
            size={'small'}
          >
            Share
          </Button>
        ];
        if (!folderDetails) {
          actionsArray.push(
            <GridActionsCellItem
              icon={<EditOutlined />}
              label="Edit Details"
              onClick={() => handleMetadata(params.row)}
              showInMenu
            />
          );
        }

        if (!params?.row?.v2 && billing?.activePricingPlan?.type !== planTypes.FREE.type) {
          actionsArray.push(
            <GridActionsCellItem
              icon={<PreviewOutlined />}
              label="Preview Options"
              onClick={() => handleSetPreview(params?.row)}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<Delete color="error" />}
              label="Delete Preview Options"
              onClick={() => handlePreviewDeletion(params?.row)}
              showInMenu
            />
          );
        }

        if (billing?.activePricingPlan?.name !== 'Free') {
          actionsArray.push(
            <GridActionsCellItem
              icon={<Check />}
              label="Set gateway as a root"
              onClick={() => handleSetGatewayRoot(params?.row)}
              divider
              showInMenu
            />
          );
        }

        if (!params?.row?.date_unpinned && !folderDetails) {
          actionsArray.push(
            <GridActionsCellItem
              icon={<Delete color="error" />}
              label="Delete File"
              onClick={() => handleRemovePinModal(params?.row)}
              showInMenu
            />
          );
        }

        return actionsArray;
      },
      renderHeader: () => null
    }
  ];

  const submarinedRows = files.filter((el: any) => !!el.v2);
  const publicRows = files.filter((el: any) => !el.v2);

  const currentGatewayToken = useMemo(() => {
    if (gatewaySettingsData) {
      return gatewaySettingsData.item?.settings?.restrictionPolicies?.accessTokens[0]?.value
    }
  }, [gatewaySettingsData]);

  useEffect(() => {
    if (gatewayToUse && gatewayToUse !== 'gateway.pinata.cloud') {
      const [domainName] = gatewayToUse.split('.');
      const newActiveGateway = gateways?.gateways?.rows?.find((el) => {
        if (!!el.customDomains.length) {
          return !!el.customDomains.find((cd) => cd.domain.split('.')[0] === domainName);
        } else {
          return el.domain === domainName;
        }
      });
      newActiveGateway?.id && setActiveGateway(newActiveGateway);
    }
  }, [gatewayToUse]);

  const isPreviewContentAvailable = useMemo(
    () => (gatewayToUse !== 'gateway.pinata.cloud' && billing?.activePricingPlan?.type) || 0 > planTypes.FREE.type,
    [billing?.activePricingPlan]
  );

  const copyHash = (hash: string) => {
    navigator.clipboard.writeText(hash);
    setAlert('Copied!', 'success');
  };

  const handleMetadata = (row: PinData) => {
    setKeyValues([]);
    setHash(row.ipfs_pin_hash);
    setV2Id(row.v2 ? row.id : null);
    if (row.metadata && row.metadata.keyvalues) {
      const keys = Object.keys(row.metadata.keyvalues);
      let keyValArray = [];
      for (const key of keys) {
        const newObject = {
          id: uuidv4(),
          key: key,
          value: row.metadata.keyvalues[key]
        };
        keyValArray.push(newObject);
      }
      setKeyValues(keyValArray);
    } else if (row.metadata) {
      const keys = Object.keys(row.metadata);
      let keyValArray = [];
      for (const key of keys) {
        if (key !== 'name') {
          const newObject = {
            id: uuidv4(),
            key: key,
            value: row.metadata[key]
          };
          keyValArray.push(newObject);
        }
      }
      setKeyValues(keyValArray);
    }
    setFileName(getFileName(row, folderDetails));
    setShowEditMetaDataModal(true);
  };

  const handleDeleteKeyValue = (id: any) => {
    const updatedKeyValues = keyValues.filter((k) => k.id !== id);
    setKeyValues(updatedKeyValues);
  };

  const handleAddKeyValue = () => {
    if (canAddNewKeyValue) {
      const keyValuesToUpdate = [...keyValues];
      keyValuesToUpdate.push({ key: newKey, value: newValue, id: uuidv4() });
      setKeyValues(keyValuesToUpdate);
      setNewKey('');
      setNewValue('');
    } else {
      setAlert('Enter a key and a value', 'error');
    }
  };

  const handleSaveMetaData = async () => {
    const metadataObj: any = {
      name: fileName
    };
    if (keyValues.length > 0) {
      const keyValObj: any = {};
      for (const item of keyValues) {
        keyValObj[item.key] = item.value;
      }
      metadataObj['keyvalues'] = keyValObj;
    } else {
      metadataObj['keyvalues'] = {}
    }
    await putHashMetadata(hash, metadataObj, submitTableFilters, v2Id);
    setV2Id(null);
    setShowEditMetaDataModal(false);
    setNewKey('');
    setNewValue('');
  };

  const handleRemovePin = async () => {
    setAlert('Removing content...', 'info');
    const isArray = Array.isArray(pinInfo);
    if(isArray && IS_BULK_UNPIN_ENABLED) {
      // ---Bulk unpin service call here (START)
      pinInfo.forEach(async (element) => {
        const contentId = element?.v2 ? element?.id : null;
        await removePin(element?.ipfs_pin_hash, submitTableFilters, contentId);
      });
      // ---Bulk unpin service call here (END)
    } else {
      const contentId = pinInfo?.v2 ? pinInfo?.id : null;
      await removePin(hash, submitTableFilters, contentId);
    }
    setRemovePinModalOpen(false);
    setPin(null);
  };

  const handleRemovePinModal = (row: PinData | Array<PinData>) => {
    const isArray = Array.isArray(row);
    setHash(isArray ? row : row?.ipfs_pin_hash);
    setPin(row);
    setRemovePinModalOpen(true);
  };

  const setMetricsRestrictionAlert = () => {
    setAlert(
      <span>
        This action was blocked due to plan limitations. <a href="/billing">See plan options</a>
      </span>,
      'error'
    );
  };

  const generateToken = async (row: PinData) => {
    if (isAnyMetricLimitReached) {
      setMetricsRestrictionAlert();
      return;
    }
    setPin(row);
    if (gatewayToUse === 'gateway.pinata.cloud') {
      setAlert('You need to create a dedicated gateway first', 'error');
    } else {
      setV2Id(row.id);
      setCIDAccessModalOpen(true);
    }
  };

  const handleSetPreview = (row: PinData) => {
    if (isAnyMetricLimitReached) {
      setMetricsRestrictionAlert();
      return;
    }
    setPin(row);
    setContentPreviewModalOpen(true);
  };

  const handlePreviewDeletion = (row: PinData) => {
    setPin(row);
    setOpenConfirmDeletionDialog(true);
  };

  const handleFolderSelect = async (row: PinData) => {
    setPin(row);
    await listFolderContents(row, 0);
  };

  const getPath = (row: PinData) => {
    if (row?.v2 && folderDetails) {
      return `${folderDetails.ipfs_pin_hash}/${getFileName(row, folderDetails)}`;
    }
    return row?.ipfs_pin_hash;
  };

  const handleSetGatewayRoot = (row: PinData) => {
    setPin(row);
    setSelectGatewayModalOpen(true);
  };

  const generateLinkAndGo = async (row: PinData) => {
    const accessTime = 3000;
    if (gatewayToUse === 'gateway.pinata.cloud') {
      setAlert('You need to create a dedicated gateway first', 'error');
    } else {
      setAlert('Generating access token...', 'info');
      let link;
      let id;
      let token;
      if (folderDetails) {
        id = row.id;
        token = await generateAccessToken(accessTime, id);
        link = `https://${gatewayToUse}${row.uri}?accessToken=${token}${
          currentGatewayToken ? `&pinataGatewayToken=${currentGatewayToken}` : ''
        }`;
      } else {
        id = row.id;
        token = await generateAccessToken(accessTime, id);
        link = `https://${gatewayToUse}/ipfs/${row.ipfs_pin_hash}?accessToken=${token}${
          currentGatewayToken ? `&pinataGatewayToken=${currentGatewayToken}` : ''
        }`;
      }

      window.open(link);
    }
  };

  const stopSubmarining = (row: PinData) => {
    setPin(row);
    setHash(row.ipfs_pin_hash);
    setMakePublicModalOpen(true);
  };

  const setContentPreviewSettings = async (config: any) => {
    try {
      setLoading(true);
      await setPinContentPreviewOptions(config);
      setContentPreviewModalOpen(false);
      setPin(null);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const confirmPreviewDeletion = async () => {
    try {
      setLoading(true);
      await removePinContentPreviewOptions(pinInfo.id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpenConfirmDeletionDialog(false);
      setPin(null);
    }
  };

  const handleMakePublic = async () => {
    setAlert('Making file public...', 'info');
    setMakePublicModalOpen(false);
    const success = await makeSubmarinedFilePublic(pinInfo);
    if (success) {
      setAlert('File will be added to the IPFS network soon!', 'success');
    }
  };

  const handlePageChange = (direction: 'Forward' | 'Backward') => {
    if (direction === 'Forward') {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const rowSelectionModelChangeHandler = (smc: GridRowSelectionModel, rows: Array<PinData>) => {
    const cidArray = rows.filter((row: PinData) => smc.includes(row.id));
    setRowsSelected(cidArray);
  };

  return (
    <>
      <Tabs
        sx={{
          '& .MuiTabs-indicator': {
            height: '6px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'
          }
        }}
        value={currentTab}
        onChange={(e: any, newValue: string) => {
          // set page to default every time tab changes
          setCurrentPage(0);
          setCurrentTab(newValue);
          setSubmarined(newValue === 'submarined'? true : false);
        }}
      >
        <Tab label="Public" value="public" />
        <Tab label="Private" value="submarined" />
      </Tabs>

      {currentTab === 'public' ? (
        <DataGrid
          sx={{
            '&': {
              borderColor: theme.palette.divider,
              borderRadius: 'unset'
            },
            '& div[data-field="ipfs_pin_hash"]': {
              fontWeight: 400
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none'
            },
            '& .MuiDataGrid-actionsCell > button[aria-label="more"]': {
              color: theme.palette.primary.main
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            }
          }}
          rows={publicRows}
          columns={publicColumns}
          autoHeight
          checkboxSelection={IS_BULK_UNPIN_ENABLED}
          onRowSelectionModelChange={(smc) => rowSelectionModelChangeHandler(smc, publicRows)}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          columnHeaderHeight={44}
          rowHeight={84}
          loading={!loadingDone}
          paginationModel={{ pageSize: rowsPerPage, page: currentPage }}
          slots={{
            moreActionsIcon: () => (
              <Button
                startIcon={<MoreVert sx={{ color: theme.palette.primary.main }} />}
                variant="outlined"
                sx={{
                  ...getBorderedBoxStyles(theme),
                }}
                size={'small'}
                component="span"
              >
                More
              </Button>
            ),
            pagination: () => (
              <Box>
                <Button disabled={currentPage === 0 || !loadingDone} onClick={() => handlePageChange('Backward')} variant={'text'}>
                  <ArrowBackIos />
                </Button>
                {loadingDone ? currentPage+1 : ''}
                <Button disabled={publicRows.length < rowsPerPage || !loadingDone} onClick={() => handlePageChange('Forward')} variant={'text'}>
                  <ArrowForwardIos />
                </Button>
              </Box>
            )
          }}
          slotProps={{
            basePopper: {
              sx: {
                '& > .MuiPaper-root': {
                  overflow: 'hidden'
                }
              }
            }
          }}
        />
      ) : (
        <DataGrid
          sx={{
            '&': {
              borderColor: theme.palette.divider,
              borderRadius: 'unset'
            },
            '& div[data-field="ipfs_pin_hash"]': {
              fontWeight: 400
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none'
            },
            '& .MuiDataGrid-actionsCell > button[aria-label="more"]': {
              color: theme.palette.primary.main
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            }
          }}
          rows={submarinedRows}
          columns={submarinedColumns}
          autoHeight
          checkboxSelection={IS_BULK_UNPIN_ENABLED}
          onRowSelectionModelChange={(smc) => rowSelectionModelChangeHandler(smc, submarinedRows)}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          columnHeaderHeight={44}
          rowHeight={84}
          loading={!loadingDone}
          paginationModel={{ pageSize: rowsPerPage, page: currentPage }}
          slots={{
            moreActionsIcon: () => (
              <Button
                startIcon={<MoreVert sx={{ color: theme.palette.primary.main }} />}
                variant="outlined"
                sx={{
                  ...getBorderedBoxStyles(theme)
                }}
                size={'small'}
                component="span"
              >
                More
              </Button>
            ),
            pagination: () => (
              <Box>
                <Button disabled={currentPage === 0 || !loadingDone} onClick={() => handlePageChange('Backward')} variant={'text'}>
                  <ArrowBackIos />
                </Button>
                <Button
                  disabled={submarinedRows.length < rowsPerPage || !loadingDone}
                  onClick={() => handlePageChange('Forward')}
                  variant={'text'}
                >
                  <ArrowForwardIos />
                </Button>
              </Box>
            )
          }}
          slotProps={{
            basePopper: {
              sx: {
                '& > .MuiPaper-root': {
                  overflow: 'hidden'
                }
              }
            }
          }}
        />
      )}

      {showEditMetaDataModal && (
        <MetadataModal
          newKey={newKey}
          setNewKey={setNewKey}
          newValue={newValue}
          setNewValue={setNewValue}
          handleDeleteKeyValue={handleDeleteKeyValue}
          handleSaveMetaData={handleSaveMetaData}
          handleAddKeyValue={handleAddKeyValue}
          fileName={fileName}
          setFileName={setFileName}
          keyValues={keyValues}
          showEditMetaDataModal={showEditMetaDataModal}
          setShowEditMetaDataModal={setShowEditMetaDataModal}
        />
      )}
      {removePinModalOpen && (
        <RemovePinModal
          hash={hash}
          handleRemovePin={handleRemovePin}
          setRemovePinModalOpen={setRemovePinModalOpen}
          removePinModalOpen={removePinModalOpen}
        />
      )}
      {cidAccessModalOpen && (
        <GenerateAccessTokenModal
          getPath={getPath}
          setAlert={setAlert}
          gatewayToUse={gatewayToUse}
          setPin={setPin}
          pinInfo={pinInfo}
          cidAccessModalOpen={cidAccessModalOpen}
          setCIDAccessModalOpen={setCIDAccessModalOpen}
        />
      )}
      {contentPreviewModalOpen && (
        <ContentPreviewModal
          setAlert={setAlert}
          contentPreviewModalOpen={contentPreviewModalOpen}
          toggleModal={setContentPreviewModalOpen}
          loading={loading}
          action={setContentPreviewSettings}
          pinInfo={pinInfo}
          currentPlan={billing?.activePricingPlan}
        />
      )}
      {openConfirmDeletionDialog && (
        <ConfirmationModal
          title="Remove Preview Options?"
          content="After deletion you can set options again."
          modalOpen={openConfirmDeletionDialog}
          toggleModal={setOpenConfirmDeletionDialog}
          loading={loading}
          action={confirmPreviewDeletion}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
      {selectGatewayModalOpen && (
        <SelectGatewayModal
          modalOpen={selectGatewayModalOpen}
          toggleModal={setSelectGatewayModalOpen}
          pinInfo={pinInfo}
          gateways={gateways}
          getAllGateways={getAllGateways}
          setRootContent={setRootContent}
        />
      )}
      {makePublicModalOpen && (
        <MakePublicModal
          makePublicModalOpen={makePublicModalOpen}
          setMakePublicModalOpen={setMakePublicModalOpen}
          hash={hash}
          handleMakePublic={handleMakePublic}
        />
      )}
    </>
  );
};

export default connect(null, {
  setAlert,
  putHashMetadata,
  removePin,
  changeHashPinPolicy,
  generateAccessToken,
  listFolderContents,
  makeSubmarinedFilePublic,
  setPinContentPreviewOptions,
  removePinContentPreviewOptions,
  getAllGateways,
  setRootContent,
  getGatewayDetails
})(PinTable);
