import { useState, useEffect } from 'react';
import { Hub } from 'aws-amplify';
import Cookies from 'js-cookie';
import type { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';

import * as Sentry from '@sentry/react';
import * as cognito from '../services/cognito';

export const fetchSession = async () => {
  try {
    const session: CognitoUserSession = await cognito.getCurrentSession();
    const user = await cognito.currentAuthenticatedUser();
    const idToken = session.getIdToken();
    const accessToken = session.getAccessToken();
    const refreshToken = session.getRefreshToken();
    return {
      user,
      session,
      accessToken: accessToken.getJwtToken(),
      refreshToken: refreshToken.getToken(),
      idToken: idToken.getJwtToken()
    };
  } catch (error) {
    console.log('useAuth Error', error);
    Sentry.captureException(error);
    //  Cognito does not handle non-logged in users well here
    //  Because it throws an error, we just need to return null for all properties
    return null;
  }
};

export const logUserOut = async () => {
  try {
    await clearCognitoCache();
    await cognito.signOut({ global: true }).catch(e => {
      console.log('Could not signout', e);
    });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export const clearCognitoCache = async () => {
  const cookies = Cookies.get();
  const cookieKeys = Object.keys(cookies);
  const filteredCookies = cookieKeys.filter((c) => c.includes('CognitoIdentityServiceProvider'));
  const cookieDeletionPromises = [];
  for (const c of filteredCookies) {
    cookieDeletionPromises.push(Cookies.remove(c, { path: '/', domain: '.app.pinata.cloud' }));
    cookieDeletionPromises.push(Cookies.remove(c, { path: '/', domain: '.staging-app.pinata.cloud' }));
    cookieDeletionPromises.push(Cookies.remove(c, { path: '/', domain: '.pinata.cloud' }));
    cookieDeletionPromises.push(Cookies.remove(c, { path: '/', domain: `.app.devpinata.cloud` }));    
    cookieDeletionPromises.push(Cookies.remove(c, { path: '/', domain: `.devpinata.cloud` }));
    cookieDeletionPromises.push(Cookies.remove(c, { path: '/', domain: 'localhost' }));
  }
  await Promise.all(cookieDeletionPromises);
  console.log('removed');
};

export const logUserIn = async (email: string, password: string): Promise <{ user?: CognitoUser | any, success: boolean, error?: any }> => {
  try {
    localStorage.removeItem('pinata-avatar');
    const res = await cognito.signIn(email, password);
    return {
      success: true,
      user: res
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error
    };
  }
};

export const signUpUser = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  isBuilder: boolean
): Promise<{success: boolean, user: CognitoUser, sub: string}> => {
  try {
    const { user, userSub } = await cognito.signUp({
      username: email,
      password,
      attributes: {
        email,
        'custom:firstName': firstName,
        'custom:lastName': lastName,
        'custom:userType': isBuilder ? 'builder' : 'creator'
      }
    });
    return {
      success: true,
      user,
      sub: userSub
    };
  } catch (error) {
    throw error;
  }
  // window.location.replace(
  //   `https://${process.env.REACT_APP_AWS_AUTH_URL}/signup?client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${process.env.REACT_APP_LOGIN_REDIRECT}`
  // )
};

export const confirmMFA = async (user: CognitoUser, code: string) => {
  try {
    await cognito.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
    return {
      success: true
    };
  } catch (error) {
    return {
      success: false,
      error
    };
  }
};

export const confirmSignUp = async (email: string, password: string, code: string) => {
  try {
    await cognito.confirmSignUp(email, code);
    await logUserIn(email, password);
    return {
      success: true
    };
  } catch (error) {
    console.log(error);
    throw error
  }
};

export const resendConfirmationCode = async (email: string) => {
  try {
    return await cognito.resendSignUp(email);
  } catch (error) {
    console.log('error resending code: ', error);
    throw error;
  }
};

export const sendPasswordReset = async (email: string) => {
  try {
    await cognito.forgotPassword(email);
  } catch (error) {
    console.log('error sending password reset: ', error);
    throw error;
  }
};

export const forgotPasswordSubmit = async (email: string, code: string, newPassword: string) => {
  try {
    await cognito.forgotPasswordSubmit(email, code, newPassword);
  } catch (error) {
    console.log('error submit password reset: ', error);
    throw error;
  }
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
  try {
    return await cognito.changePassword(oldPassword, newPassword);
  } catch (error) {
    throw error;
  }
};

export const getMFAOptions = async () => {
  try {
    return await cognito.getPreferredMFA();
  } catch (error) {
    throw error;
  }
};

export const setPreferredMFA = async (preferredMFA?: 'TOTP' | 'SMS' | 'NOMFA' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA') => {
  try {
    const mfaType = preferredMFA ? preferredMFA : 'TOTP';
    return await cognito.setPreferredMFA(mfaType);
  } catch (error) {
    throw error;
  }
};

export const setupTotp = async () => {
  try {
    return await cognito.setupTOTP();
  } catch (error) {
    throw error;
  }
};

export const verifytTotp = async (challengeAnswer: string) => {
  try {
    await cognito.verifyTotpToken(challengeAnswer);
    return await setPreferredMFA();
  } catch (error) {
    throw error;
  }
};

export const updateAttributes = async (attributes: any) => {
  try {
    await cognito.updateUserAttributes(attributes);
  } catch (error) {
    throw error;
  }
};

export const getAuthenticatedUser = async () => {
  try {
    return await cognito.currentAuthenticatedUser();
  } catch (error) {
    throw error;
  }
};

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggedInUser, setUser] = useState<any | null>({});
  const [accessToken, setAccessToken] = useState('');
  const [idToken, setIdToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          handleSession();
          break;
        case 'signOut':
          console.log('sign out');
          handleSession();
          break;
        case 'signUp':
          console.log('sign up');

          break;
        default:
          return;
      }
    });
    handleSession();
  }, []);

  const handleSession = async () => {
    const sessionData = await fetchSession();
    if (sessionData && sessionData.user && sessionData.session) {
      setUser(sessionData.user);
      setIsAuthenticated(true);
      setAccessToken(sessionData.accessToken);
      setRefreshToken(sessionData.refreshToken);
      setIdToken(sessionData.idToken);
      return sessionData;
    } else {
      setUser(null);
      setIsAuthenticated(false);
      setAccessToken('');
      setRefreshToken('');
      setIdToken('');
      clearCognitoCache();
    }
  };

  return {
    isAuthenticated,
    loggedInUser,
    signUpUser,
    confirmSignUp,
    resendConfirmationCode,
    sendPasswordReset,
    forgotPasswordSubmit,
    confirmMFA,
    logUserIn,
    logUserOut,
    fetchSession,
    changePassword,
    getMFAOptions,
    setPreferredMFA,
    setupTotp,
    verifytTotp,
    accessToken,
    idToken,
    refreshToken,
    updateAttributes,
    getAuthenticatedUser,
    clearCognitoCache,
    handleSession
  };
};
