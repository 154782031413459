import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';
import { PinataDialogTitle } from '../../components/Common/MuiComponents';
import UsageCard from './UsageCard';
import PlanSelector from './PlanSelector';
import { userPinnedDataTotal } from '../../store/data/data.actions';
import {
  retrieveStripeCustomer,
  createStripePaymentSource,
  detachStripeSourceFromCustomer,
  setDefaultCard,
  changePlan,
  getBillingHistory
} from '../../store/billing/billing.actions';
import { setAlert } from '../../store/alert/alert.actions';
import { getUsageMetrics } from '../../store/metrics/metrics.actions';
import { killGateways, checkUsage } from 'store/gateways/gateway.actions';
import type { BillingState, Plan } from '../../store/billing/types';
import type { MetricsState } from '../../store/metrics/types';
import { planTypes } from '../../helpers/enums';
import BannerImage from '../../assets/images/banners/enterprise.png';

interface BillingProps {
  userPinnedDataTotal: any;
  data: any;
  retrieveStripeCustomer: any;
  billing: BillingState;
  setAlert: any;
  changePlan: (newPlan: Plan) => any;
  gateways: { gateways: any };
  killGateways: any;
  metrics: MetricsState;
  getUsageMetrics: () => any;
}

const index = (props: BillingProps) => {
  const {
    userPinnedDataTotal,
    data,
    billing,
    setAlert,
    changePlan,
    gateways: { gateways },
    getUsageMetrics,
    metrics
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [metricsInterval, setMetricsInterval] = useState<any>(null);
  const { search } = useLocation();
  const upgradeToEnterprise = new URLSearchParams(search).get('upgradeToEnterprise');
  const theme = useTheme();

  const handleEnterpriseContact = () => {
    if (window.Intercom) {
      window.Intercom('showNewMessage', "I'd like to upgrade to the enterprise plan");
      window.Intercom('update', {
        enterpriseUpgradeRequested: true
      });
    }
  };

  useEffect(() => {
    if (upgradeToEnterprise) {
      location.href = '#enterprise-banner';
      handleEnterpriseContact();
    }
    userPinnedDataTotal();
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (metricsInterval) {
      clearInterval(metricsInterval);
      setUpdatingPlan(false);
      window.Intercom && window.Intercom('shutdown');
      const reloadTimeout = setTimeout(() => {
        getUsageMetrics();
      }, 1500);
      return () => clearTimeout(reloadTimeout);
    }
    setUpdatingPlan(false);
  }, [metrics.metrics.metricsAccount.fileCount.limit]);

  useEffect(() => {
    const timer = setInterval(getUsageMetrics, 60000);
    getUsageMetrics();
    return () => clearInterval(timer);
  }, []);

  const scheduleUsageMetrics = useMemo(() => {
    return () => {
      setUpdatingPlan(true);
      const updateInterval = setInterval(() => {
        getUsageMetrics();
      }, 2500);
      setMetricsInterval(updateInterval);
    };
  }, []);

  return (
    <Container maxWidth={'xl'}>
      {/* Show modal-notification for users who're using old-plan system */}
      {billing?.activePricingPlan?.isLegacy && (
        <Dialog open={isModalOpen}>
          <PinataDialogTitle onClose={() => setIsModalOpen(false)}>Pinata's Pricing is Changing</PinataDialogTitle>
          <DialogContent dividers>
            <p>
              As we mentioned in our email to all customers, Pinata's pricing is changing. These changes are to support
              continued growth and development as well as help customers better manage their usage. No one likes a
              surprise bill so we've created a tiered pricing system that makes everything more predictable.
            </p>
            <Typography>
              <strong>You need to select a new plan</strong>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsModalOpen(false)}>
              I understand
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Render Breadcrumbs */}
      <Typography variant="h3" sx={{ mb: 2 }}>
        Billing
      </Typography>
      {(billing?.activePricingPlan?.name || billing?.activePricingPlan?.isLegacy) && (
        <UsageCard
          data={data}
          billing={billing}
          setAlert={setAlert}
          changePlan={changePlan}
          gateways={gateways}
          metrics={metrics}
          updatingPlan={updatingPlan}
        />
      )}
      <PlanSelector
        data={data}
        billing={billing}
        setAlert={setAlert}
        changePlan={changePlan}
        gateways={gateways}
        scheduleUsageMetrics={scheduleUsageMetrics}
      />

      {billing?.activePricingPlan?.type !== planTypes.ENTERPRISE.type && (
        <Box
          id={'enterprise-banner'}
          sx={{
            my: 2,
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${BannerImage}), linear-gradient(180deg, rgba(27, 7, 139, 1) 0%, rgba(85, 1, 152, 1) 100%)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            borderRadius: 1
          }}
          className={'enterprise-banner-container'}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '400px'
            }}
          >
            <Typography sx={{ color: theme.palette.primary.contrastText }}>GET A PERSONALIZED PLAN</Typography>
            <Typography sx={{ color: theme.palette.primary.contrastText }} variant={'h2'}>
              Enterprise
            </Typography>
            <Typography sx={{ color: theme.palette.primary.contrastText }} className={'enterprise-description'}>
              For serious projects, brands, and organizations that require enterprise-grade features, custom packaging,
              and 1:1 support.
            </Typography>
            <Button onClick={handleEnterpriseContact} color={'primary'}>
              Contact Us
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    data: state.data,
    billing: state.billing,
    gateways: state.gateways,
    metrics: state.metrics
  };
};

export default connect(mapStateToProps, {
  userPinnedDataTotal,
  retrieveStripeCustomer,
  createStripePaymentSource,
  detachStripeSourceFromCustomer,
  setAlert,
  setDefaultCard,
  changePlan,
  killGateways,
  checkUsage,
  getBillingHistory,
  getUsageMetrics
})(index);
