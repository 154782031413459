import {useQueries} from "@tanstack/react-query";
import { api } from "services/api";
import { GatewayRow } from "store/gateways/types";
import { metrics } from './../../pages/Analytics/analyticsTypes'
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const QUERY_KEYS = ['analytics', 'gateway', 'usage'];

type Params = {
  gatewaysList: Array<GatewayRow>;
  metric: metrics;
  days: number;
};

export const useGetAnalyticsGatewaysUsage = ({
  gatewaysList,
  metric,
  days
}: Params
) => {
  const today = dayjs.utc().endOf('day').toDate().toISOString();
  const startDay = dayjs.utc().subtract(days, 'day').startOf('day').toDate().toISOString();
  
  const gatewayQueries = useQueries({
    queries: gatewaysList.map((gateway) => {
      return {
        queryKey: [...QUERY_KEYS, metric, gateway.id],
        queryFn: () => 
          api.get(`api/v2/analytics/gateway/${gateway.domain}.${process.env.REACT_APP_PINATA_GATEWAY_BASE}/${metric}?startDate=${startDay}&endDate=${today}&rollup=true`, {          
          withCredentials: false
        })        
      }
    }),
  });
  return gatewayQueries;
};
