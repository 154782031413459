import { useState, useEffect, SyntheticEvent } from 'react';
import { KeyPermissions } from '../../store/apiKeys/types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { PinataDialogTitle } from '../../components/Common/MuiComponents';
import { ExpandMore } from '@mui/icons-material';

interface NewKeyModalProps {
  newKeyModalOpen: any;
  setNewKeyModalOpen: any;
  setAlert?: (...props: any) => void;
  generateNewKey?: (chosenPermissions: KeyPermissions, maxUses: string, keyName: string) => any;
  existingKey?: any;
  keyInfo?: { max_uses: number; name: string; scopes: KeyPermissions };
}

enum DataAccess {
  PinningAccess = 'PinningAccess',
  DataAccess = 'DataAccess',
  PsaPins = 'psa/pins'
}

const NewKeyModal = ({
  newKeyModalOpen,
  setNewKeyModalOpen,
  setAlert = () => {},
  generateNewKey = () => {},
  existingKey,
  keyInfo
}: NewKeyModalProps) => {
  const [pinningAccordionOpen, setPinningAccordionOpen] = useState(false);
  const [dataAcordionOpen, setDataAccordionOpen] = useState(false);
  const [psaAccordionOpen, setPSAAccordionOpen] = useState(false);
  const [psaPinsAccordionOpen, setPSAPinsAccordionOpen] = useState(false);
  const [adminKey, setAdminKey] = useState(false);
  const [maxUseLimited, setMaxUseLimited] = useState(false);
  const [maxUses, setMaxUses] = useState(1);
  const [keyName, setKeyName] = useState('');
  const [pinningAccess, setPinningAccess] = useState({
    hashMetadata: false,
    pinByHash: false,
    pinFileToIPFS: false,
    pinJobs: false,
    pinJSONToIPFS: false,
    unpin: false
  });
  const [dataAccess, setDataAccess] = useState({
    pinList: false,
    userPinnedDataTotal: false
  });
  const [psaPinsAccess, setPSAPinsAccess] = useState({
    addPinObject: false,
    getPinObject: false,
    listPinObjects: false,
    removePinObject: false,
    replacePinObject: false
  });
  const [expanded, setExpanded] = useState<string | false>('panel1');

  useEffect(() => {
    if (adminKey) {
      bulkUpdatePermissions(true);
    } else {
      bulkUpdatePermissions(false);
    }
  }, [adminKey]);

  useEffect(() => {
    if (existingKey && keyInfo) {
      if (keyInfo.max_uses) {
        setMaxUseLimited(true);
        setMaxUseLimited(Boolean(keyInfo.max_uses));
        setMaxUses(keyInfo.max_uses);
      }
      setKeyName(keyInfo.name);
      const scopes = keyInfo.scopes;
      if (scopes && scopes.endpoints) {
        const endpoints = scopes.endpoints;
        setPSAPinsAccess(endpoints.psa ? endpoints.psa.pins : psaPinsAccess);
        setDataAccess(endpoints.data ? endpoints.data : dataAccess);
        setPinningAccess(endpoints.pinning ? endpoints.pinning : pinningAccess);
        setPinningAccordionOpen(true);
        setPSAPinsAccordionOpen(true);
        setDataAccordionOpen(true);
        setPSAPinsAccordionOpen(true);
      }
    }
  }, [keyInfo]);

  const bulkUpdatePermissions = (bool: boolean) => {
    let pinningAccessObject = deepCloneObject(pinningAccess);
    let dataAccessObject = deepCloneObject(dataAccess);
    let psaPinsAccessObject = deepCloneObject(psaPinsAccess);
    Object.keys(pinningAccessObject).forEach((key) => {
      pinningAccessObject[key] = bool;
    });
    Object.keys(dataAccessObject).forEach((key) => {
      dataAccessObject[key] = bool;
    });
    Object.keys(psaPinsAccessObject).forEach((key) => {
      psaPinsAccessObject[key] = bool;
    });
    setPinningAccess(pinningAccessObject);
    setDataAccess(dataAccessObject);
    setPSAPinsAccess(psaPinsAccessObject);
    setAdminKey(bool);
    if (bool === false) {
      setMaxUseLimited(bool);
    }
  };

  const deepCloneObject = (object: any) => {
    return JSON.parse(JSON.stringify(object));
  };

  const isEndpointChecked = (endpointParent: DataAccess, endpointName: string) => {
    let endpointObject: any = {};
    switch (endpointParent) {
      case 'PinningAccess':
        endpointObject = deepCloneObject(pinningAccess);
        break;
      case 'DataAccess':
        endpointObject = deepCloneObject(dataAccess);
        break;
      case 'psa/pins':
        endpointObject = deepCloneObject(psaPinsAccess);
        break;
      default:
        break;
    }
    return endpointObject[endpointName];
  };

  const toggleEndpoint = (endpointParent: DataAccess, endpointName: string) => {
    let endpointObject: any = {};
    switch (endpointParent) {
      case 'PinningAccess':
        endpointObject = deepCloneObject(pinningAccess);
        endpointObject[endpointName] = !endpointObject[endpointName];
        setPinningAccess(endpointObject);
        break;
      case 'DataAccess':
        endpointObject = deepCloneObject(dataAccess);
        endpointObject[endpointName] = !endpointObject[endpointName];
        setDataAccess(endpointObject);
        break;
      case 'psa/pins':
        endpointObject = deepCloneObject(psaPinsAccess);
        endpointObject[endpointName] = !endpointObject[endpointName];
        setPSAPinsAccess(endpointObject);
        break;
      default:
        break;
    }
  };

  const maxUsesChange = (e: any) => {
    if (e.target.value > -1) {
      const uses = parseInt(e.target.value, 10).toFixed(0);
      setMaxUses(Number(uses === '0' ? 1 : uses));
    } else {
      setMaxUses(0);
    }
  };

  const handleKeyCreation = () => {
    const chosenPermissions = {
      admin: adminKey,
      endpoints: {
        pinning: pinningAccess,
        data: dataAccess,
        psa: {
          pins: psaPinsAccess
        }
      }
    };

    const uses = maxUseLimited ? maxUses : 0;
    const validPerms = validatePermissions(chosenPermissions);
    if (validPerms) {
      generateNewKey(chosenPermissions, String(uses), keyName);
      bulkUpdatePermissions(false);
      setKeyName('');
    } else {
      setAlert('Please make sure you select at least one permission', 'error');
    }
  };

  const validatePermissions = (perms: KeyPermissions) => {
    if (perms.admin) {
      return true;
    } else {
      const pinningEndpointKeys = Object.keys(perms.endpoints?.pinning || {});
      for (const key of pinningEndpointKeys) {
        // @ts-ignore
        if (perms.endpoints?.pinning[key] === true) {
          return true;
        }
      }
      const dataEndpointKeys = Object.keys(perms.endpoints?.data || {});
      for (const key of dataEndpointKeys) {
        // @ts-ignore
        if (perms.endpoints?.data[key] === true) {
          return true;
        }
      }
      const psaEndpointKeys = Object.keys(perms.endpoints?.psa?.pins || {});
      for (const key of psaEndpointKeys) {
        // @ts-ignore
        if (perms.endpoints?.psa?.pins[key] === true) {
          return true;
        }
      }
      return false;
    }
  };

  const canSubmit = () => {
    if (maxUseLimited && !maxUses) {
      return false;
    }

    if (!keyName) {
      return false;
    }

    if (maxUses > 1000) {
      return false;
    }

    return true;
  };

  const handleChange = (panel: string) => (event: any, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Dialog open={newKeyModalOpen} onClose={() => setNewKeyModalOpen(false)}>
      <PinataDialogTitle onClose={() => setNewKeyModalOpen(false)}>
        {existingKey ? 'View Key Info' : 'Create New API Key'}
      </PinataDialogTitle>
      <DialogContent
        dividers
        sx={{
          '& .MuiAccordion-root.Mui-expanded': {
            m: 0
          }
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="body1">Admin</Typography>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled={existingKey ? true : false}
                    checked={adminKey}
                    onChange={() => setAdminKey(!adminKey)}
                  />
                }
                label="Admin Keys have access to all endpoints and account settings"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">API Endpoint Access</Typography>
          </Grid>
          <Grid item xs={6}>
            <Accordion expanded={expanded === 'panel-1'} onChange={handleChange('panel-1')}>
              <AccordionSummary expandIcon={<ExpandMore />} id="panel1d-header">
                Pinning
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PinningAccess, 'hashMetadata')}
                        onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'hashMetadata')}
                      />
                    }
                    label="hashMetadata"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PinningAccess, 'pinByHash')}
                        onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinByHash')}
                      />
                    }
                    label="pinByHash"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PinningAccess, 'pinFileToIPFS')}
                        onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinFileToIPFS')}
                      />
                    }
                    label="pinFileToIPFS"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PinningAccess, 'pinJobs')}
                        onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinJobs')}
                      />
                    }
                    label="pinJobs"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PinningAccess, 'pinJSONToIPFS')}
                        onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'pinJSONToIPFS')}
                      />
                    }
                    label="pinJSONToIPFS"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.PinningAccess, 'unpin')}
                        onChange={() => toggleEndpoint(DataAccess.PinningAccess, 'unpin')}
                      />
                    }
                    label="unpin"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel-2'} onChange={handleChange('panel-2')}>
              <AccordionSummary expandIcon={<ExpandMore />} id="panel2d-header">
                Data
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.DataAccess, 'pinList')}
                        onChange={() => toggleEndpoint(DataAccess.DataAccess, 'pinList')}
                      />
                    }
                    label="pinList"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={existingKey ? true : false}
                        checked={isEndpointChecked(DataAccess.DataAccess, 'userPinnedDataTotal')}
                        onChange={() => toggleEndpoint(DataAccess.DataAccess, 'userPinnedDataTotal')}
                      />
                    }
                    label="userPinnedDataTotal"
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel-3'} onChange={handleChange('panel-3')}>
              <AccordionSummary expandIcon={<ExpandMore />} id="panel3d-header">
                Pinning Services API
              </AccordionSummary>
              <AccordionDetails>
                <Accordion
                  expanded={psaPinsAccordionOpen}
                  onChange={() => setPSAPinsAccordionOpen(!psaPinsAccordionOpen)}
                >
                  <AccordionSummary expandIcon={<ExpandMore />} id="panel4d-header">
                    Pins
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={existingKey ? true : false}
                            checked={isEndpointChecked(DataAccess.PsaPins, 'addPinObject')}
                            onChange={() => toggleEndpoint(DataAccess.PsaPins, 'addPinObject')}
                          />
                        }
                        label="addPinObject"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={existingKey ? true : false}
                            checked={isEndpointChecked(DataAccess.PsaPins, 'getPinObject')}
                            onChange={() => toggleEndpoint(DataAccess.PsaPins, 'getPinObject')}
                          />
                        }
                        label="getPinObject"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={existingKey ? true : false}
                            checked={isEndpointChecked(DataAccess.PsaPins, 'listPinObjects')}
                            onChange={() => toggleEndpoint(DataAccess.PsaPins, 'listPinObjects')}
                          />
                        }
                        label="getPinObject"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={existingKey ? true : false}
                            checked={isEndpointChecked(DataAccess.PsaPins, 'removePinObject')}
                            onChange={() => toggleEndpoint(DataAccess.PsaPins, 'removePinObject')}
                          />
                        }
                        label="removePinObject"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={existingKey ? true : false}
                            checked={isEndpointChecked(DataAccess.PsaPins, 'replacePinObject')}
                            onChange={() => toggleEndpoint(DataAccess.PsaPins, 'replacePinObject')}
                          />
                        }
                        label="replacePinObject"
                      />
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <h5>Limit Max Uses</h5>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  disabled={existingKey ? true : false}
                  checked={maxUseLimited}
                  onChange={() => setMaxUseLimited(!maxUseLimited)}
                />
              }
              label="When enabled, you can set the maximum number of times a key can be used"
            />
            <Collapse in={maxUseLimited}>
              <Tooltip title={'Limited use keys can have a maximum of 1,000 uses.'} placement={'right'}>
                <TextField
                  fullWidth
                  sx={{ my: 1 }}
                  id="maxUse"
                  type="number"
                  value={maxUses}
                  onChange={existingKey ? () => {} : maxUsesChange}
                  color={maxUses > 1000 ? 'error' : 'primary'}
                  label={'Max Uses'}
                />
              </Tooltip>
            </Collapse>
          </Grid>
          <Divider />
          <Grid item xs={6}>
            <Typography variant="body1">Key Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={'Key Name'}
              disabled={existingKey ? true : false}
              value={keyName}
              onChange={existingKey ? () => {} : (e) => setKeyName(e.target.value)}
            />
          </Grid>
          <Divider />
          {existingKey ? (
            <Button sx={{ mt: 2 }} fullWidth onClick={() => setNewKeyModalOpen(false)}>
              Done
            </Button>
          ) : (
            <Button sx={{ mt: 2 }} fullWidth disabled={!canSubmit()} onClick={handleKeyCreation}>
              Create Key
            </Button>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NewKeyModal;
