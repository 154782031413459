import { Dialog, DialogContent } from '@mui/material';
import Upload from 'pages/PinManager/Upload';
import { PinataDialogTitle } from 'components/Common/MuiComponents';

const FileUploadModal = ({ fileUploadOpen, setFileUploadModalOpen }: any) => {
  const closeModal = () => {
    setFileUploadModalOpen(false);
  };
  return (
    <Dialog open={fileUploadOpen} onClose={closeModal} fullWidth sx={{ p: 2 }}>
      <PinataDialogTitle onClose={closeModal}>Upload File</PinataDialogTitle>
      <DialogContent>
        <Upload uploadType={'file'} closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadModal;
