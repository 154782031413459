import { Box, Card, Grid, LinearProgress, Typography } from "@mui/material";
import { prettySize } from "helpers/pretty";
import { GatewayRow } from "store/gateways/types";
import { PieChart, PieChartType } from "../../Charts";
import { useGetAnalyticsGatewaysUsage } from "hooks";
import { metrics } from "pages/Analytics/analyticsTypes";

type Props = {
  gatewaysList: Array<GatewayRow>;
  days: number;
  metric: metrics;
}

const GatewayUsageData = ({gatewaysList, days, metric}: Props) => {
  const gatewayQueries = useGetAnalyticsGatewaysUsage({gatewaysList, metric, days});

  const dataToPieChart = (data: any) => {
    const chartData: Array<PieChartType> = [];
    data.forEach((element: any, index: number) => {
      if(metric === 'bandwidth') {
        chartData.push({value: element?.data?.data?.total_bandwidth_in_bytes, name: `${gatewaysList[index].domain}`});
      } else {
        chartData.push({value: element?.data?.data?.total_requests, name: `${gatewaysList[index].domain}`});
      }
    });
    return chartData;
  };
  
  if(gatewayQueries[0]?.isLoading || gatewayQueries[0]?.isError) {
    return <Box sx={{ width: '100%', textAlign: 'center', marginTop: 4}}>
    <LinearProgress />
      Loading...
    </Box>    
  }


  return (
    <Card sx={{padding: 3, boxShadow: 'none'}}>
      <Typography variant="h6" sx={{marginBottom: 2, textTransform: 'capitalize'}}>{`${metric} (last ${days} days)`}</Typography>
      <Grid container spacing={0} alignItems={'center'}>
        <Grid item md={5} xs={12} sx={{height: 160}}>
          <PieChart
            data={dataToPieChart(gatewayQueries)}
            unit={metric === 'bandwidth' ? 'Bytes' : 'Requests'}
            showLabel={metric === 'bandwidth' ? 'prettysize' : 'normal'}
          />
        </Grid>
        <Grid item md={7} xs={12}>
          <Grid container direction={'column'}>
            {
              gatewayQueries.map((gateway: any, index: number) =>
              <Grid item key={index}>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                    {gatewaysList[index].domain}
                  </Grid>
                  <Grid item>
                    {
                      metric === 'bandwidth' ? (
                        <strong>{prettySize(gateway?.data?.data?.total_bandwidth_in_bytes)}</strong>
                      ) : (
                        <strong>{gateway?.data?.data?.total_requests}</strong>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>  
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default GatewayUsageData;
