import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, TextField, FormGroup, Box } from '@mui/material';
import { useAuth } from 'hooks/useAuth';

interface EditAccountDetailsProps {
  user: any;
  loggedInUser: any;
  setEditing: any;
  setAlert: any;
  changeEmail: any;
}

const EditAccountDetails = ({ user, loggedInUser, setEditing, setAlert, changeEmail }: EditAccountDetailsProps) => {
  const [newFName, setNewFirstName] = useState(
    loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName']
      ? loggedInUser.attributes['custom:firstName']
      : ''
  );
  const [newLName, setNewLastName] = useState(
    loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:lastName']
      ? loggedInUser.attributes['custom:lastName']
      : ''
  );
  const [submitting, setSubmitting] = useState(false);
  const [newEmail, setNewEmail] = useState(user?.email || '');
  const { updateAttributes } = useAuth();
  const history = useHistory();

  const getLoggedInUserAttributes = () => {
    let fName = '';
    let lName = '';
    if (loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName']) {
      fName = loggedInUser.attributes['custom:firstName'];
    }

    if (loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:lastName']) {
      lName = loggedInUser.attributes['custom:lastName'];
    }

    return { fName, lName };
  };

  const submit = async (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    if (getLoggedInUserAttributes().fName !== newFName || getLoggedInUserAttributes().lName !== newLName) {
      try {
        await updateAttributes({
          'custom:firstName': newFName,
          'custom:lastName': newLName
        });
        setAlert('Updated!', 'success');
        setEditing(false);
      } catch (error) {
        console.log(error);
        setAlert('Error updating', 'error');
      }
    }
    if (newEmail !== user.email) {
      try {
        const res = await changeEmail(newEmail);
        if (res.success) {
          setAlert('Email updated, logging out...', 'success');
          history.push('/signout');
        }
      } catch (error) {
        setAlert('Trouble updating email', 'error');
      }
    }
    setSubmitting(false);
  };

  return (
    <form onSubmit={submit}>
      <FormGroup>
        <TextField
          value={newFName}
          onChange={(e) => setNewFirstName(e.target.value)}
          label="First Name"
          sx={{ my: 1 }}
          id="formrow-firstname-Input"
        />
        <TextField
          value={newLName}
          onChange={(e) => setNewLastName(e.target.value)}
          label="Last Name"
          sx={{ my: 1 }}
          id="formrow-firstname-Input"
        />
        <TextField
          value={newEmail}
          type="email"
          onChange={(e) => setNewEmail(e.target.value)}
          label="Email"
          sx={{ my: 1 }}
          id="formrow-email-Input"
        />
      </FormGroup>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button type="submit">{submitting ? 'Saving...' : 'Save'}</Button>
      </Box>
    </form>
  );
};

export default EditAccountDetails;
