import { User } from 'store/user/types'
import { Avatar } from '@mui/material';

type AvatarProps = {
  user: User | null;
  size?: number;
}

const getUserInitials = (user: User | null): string => {
  let retVal: string = '-';
  let firstInitial: string = '';
  let secondInitial: string = '';
  try {
    const names: Array<string> | undefined= user?.['custom:firstName']?.split(' ');
    const surnames: Array<string> | undefined= user?.['custom:lastName']?.split(' ');
    if(names) firstInitial = names[0].charAt(0);
    if(surnames) secondInitial = surnames[surnames.length-1].charAt(0);
    if(firstInitial || secondInitial) retVal = firstInitial+secondInitial;    
  } catch (error) {
    console.error(error);
  }
  return retVal;
}

const UserProfileAvatar = ({user, size=40}: AvatarProps) => {
  return (
    <Avatar
      sx={{ width: size, height: size }}
      src={ user?.profilePicture || undefined}
      alt="Header Avatar"
      children={
        !user?.profilePicture && <p>{getUserInitials(user)}</p>
      }
    />    
  )
}

export default UserProfileAvatar
