export const MAX_THUMBNAIL_SIZE = 3000000;

export const ANALYTICS = {
  AUTH: {
    SIGN_UP: 'AUTH_SIGN_UP',
    LOGIN: 'AUTH_LOGIN',
    CONFIRMATION: 'AUTH_CONFIRMATION'
  },
  UI: {
    THEME: 'THEME'
  },
  PROCESS: {
    BILLING: {
      ACC_UPGRADED: 'ACCOUNT_UPGRADED',
      ACC_WAS_NOT_UPGRADED: 'ACC_WAS_NOT_UPGRADED',
      PLAN_PURCHASED: 'PLAN_PURCHASED',
      PLAN_CHANGED: 'PLAN_CHANGED'
    },
    CANCELATION_FEEDBACK: 'CANCELATION_FEEDBACK',
    FILES: {
      UPLOAD: 'FILES_UPLOAD'
    }
  }
};
