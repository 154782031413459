import { Typography, Box, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { prettySize } from 'helpers/pretty';
import type { Plan } from 'store/billing/types';

interface BandwidthUsageChartProps {
  currentPlan: Plan | null;
  bandwidth: number;
  percentage: number;
  limit: number;
}

const BandwidthUsageChart = (props: BandwidthUsageChartProps) => {
  const { bandwidth, percentage, limit, currentPlan } = props;
  const theme = useTheme();

  const series: number[] = [percentage];
  const options: ApexOptions = {
    chart: {
      height: 150,
      type: 'radialBar',
      foreColor: theme.palette.text.primary,
      sparkline: {
        enabled: true
      }
    },
    colors: ['#ff009e'],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5 // margin is in pixels
        },

        hollow: {
          size: '60%'
        },

        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '16px'
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    stroke: {
      dashArray: 3
    },
    labels: ['Storage']
  };
  return (
    <Box sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography variant="h6">Bandwidth Used This Month</Typography>
      <ReactApexChart options={options} series={series} type="radialBar" />
      <Typography sx={{ my: 1 }}>
        {prettySize(bandwidth)} of {currentPlan?.name !== 'PROFESSIONAL' ? limit : 100} GB used
      </Typography>
    </Box>
  );
};

export default BandwidthUsageChart;
