import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  ListItemText,
  MenuItem,
  MenuList,
  useMediaQuery,
  styled,
  useTheme,
  Box,
  ListItemIcon,
  alpha,
  Chip
} from '@mui/material';
import { purple } from '@mui/material/colors';
import SubmarineIcon from '../../assets/images/icons/Submarine';
import Server from '../../assets/images/icons/Server';
import BookOpen from '../../assets/images/icons/BookOpen';
import Document from '../../assets/images/icons/Document';
import Key from '../../assets/images/icons/Key';
import LockOpen from '../../assets/images/icons/LockOpen';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import File from '../../assets/images/icons/File';
import { Code } from '@mui/icons-material';
import { neutral } from '../../themes/colors';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IMenuItem {
  name?: string;
  icon?: React.ReactElement;
  text: string;
  chip?: string;
  to: string;
  disabled?: boolean;
  hidden?: boolean;
}

const NavMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius / 4,
  margin: theme.spacing(1, 'auto'),
  '& .MuiListItemText-primary': {
    fontSize: theme.typography.pxToRem(14)
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.mode === 'light' ? neutral[200] : neutral[800]
  },
  '&.Mui-selected .MuiListItemText-primary': {
    color: theme.palette.text.primary
  },
  '&:hover .MuiListItemText-primary': {
    color: theme.palette.text.primary
  }
}));

const NavItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main
}));

const PrimaryMenu = () => {
  const [selectedTab, setSelectedTab] = useState('files');
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { analyticsApi } = useFlags();

  const menuItems = [
    {
      section: null,
      items: [
        { name: 'pinmanager', icon: <Document />, text: 'Files', to: '/pinmanager' },
        { name: 'gateway', icon: <Server />, text: 'Gateways', to: '/gateway' },
        { name: 'analytics', icon: <TrendingUpIcon />, text: 'Analytics', to: '/analytics', chip: 'Alpha', hidden: !analyticsApi}
      ]
    },
    {
      section: 'Developers',
      items: [
        { name: 'api-keys', icon: <Key />, text: 'API Keys', to: '/developers/api-keys' },
        { name: 'v2-api-keys', icon: <SubmarineIcon />, text: 'Submarine Keys', to: '/developers/v2-api-keys' },
        { name: 'gateway-settings', icon: <LockOpen />, text: 'Access Controls', to: '/developers/gateway-settings' },
        { name: 'documentation', icon: <BookOpen />, text: 'Documentation', to: 'https://docs.pinata.cloud/' }
      ]
    }
  ];
  
  const mobileMenuItems = [
    { name: 'pinmanager', icon: <Document />, text: 'Files', to: '/pinmanager' },
    { name: 'gateway', icon: <Server />, text: 'Gateways', to: '/gateway' },
    { name: 'analytics', icon: <TrendingUpIcon />, text: 'Analytics', to: '/analytics', hidden: !analyticsApi },  
    { name: 'developers', icon: <Key />, text: 'Developers', to: '/developers/api-keys' },
    { name: 'documentation', icon: <BookOpen />, text: 'Documentation', to: 'https://docs.pinata.cloud/' }
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getMobileSelectedTab = (menuItem: any) => {
    if (
      (selectedTab === 'api-keys' || selectedTab === 'v2-api-keys' || selectedTab === 'gateway-settings') &&
      menuItem.name === 'developers'
    ) {
      return theme.palette.mode === 'light' ? neutral[200] : purple[900];
    }
    return selectedTab === menuItem.name ? (theme.palette.mode === 'light' ? neutral[200] : purple[900]) : '';
  };

  useEffect(() => {
    const path = location.pathname;
    const selectedTab = menuItems
      .reduce((prev, next) => {
        return prev.concat(next.items);
      }, [] as any[])
      .find((item: IMenuItem) => item.to === path);
    setSelectedTab(selectedTab?.name);
  }, [location.pathname]);

  const desktopMenu = (
    <MenuList sx={{ p: 0 }}>
      {menuItems.map((menuItem) => (
        <Box key={menuItem.section}>
          {menuItem?.section && (
            <Box
              sx={{
                marginTop: 4,
                fontSize: theme.typography.pxToRem(10),
                textTransform: 'uppercase',
                color: alpha(theme.palette.text.primary, theme.palette.mode === 'light' ? 0.75 : 0.45)
              }}
            >
              {menuItem.section}
            </Box>
          )}
          {menuItem.items.map((item: IMenuItem, index: number) => {
            if (item?.hidden) return
            if (item.name === 'documentation') {
              return (
                <NavMenuItem
                  key={item.name}
                  selected={selectedTab === item.name}
                  onClick={() => window.open(item.to, '_blank')}
                >
                  <NavItemIcon>{item.icon}</NavItemIcon>
                  <ListItemText>{item.text}</ListItemText>
                </NavMenuItem>
              );
            }
            return (
              <NavMenuItem
                key={item.name}
                selected={selectedTab === item.name}
                onClick={() => history.push(item.to)}
                sx={{ display: 'flex', alignItems: 'center', color: 'inherit', width: '100%' }}
              >
                <NavItemIcon>{item.icon}</NavItemIcon>
                <ListItemText>
                  {item.text}&nbsp;&nbsp;
                  {item.chip && <Chip size="small" label={item.chip} />}
                </ListItemText>
              </NavMenuItem>
            );
          })}
        </Box>
      ))}
    </MenuList>
  );

  const mobileMenu = (
    <BottomNavigation
      showLabels
      value={selectedTab}
      onChange={(_, value) => {
        if (value?.name == 'documentation') {
          return window.open(value.to, '_blank');
        }
        history.push(value.to);
      }}
    >
      {mobileMenuItems.map((menuItem) => {
        if(menuItem?.hidden) return
        return (
        <BottomNavigationAction
          key={menuItem?.name}
          label={menuItem?.text}
          icon={menuItem?.icon}
          value={menuItem}
          sx={{ backgroundColor: getMobileSelectedTab(menuItem) }}
        />
      )})}
    </BottomNavigation>
  );

  return !isMobile ? desktopMenu : mobileMenu;
};

export default PrimaryMenu;
