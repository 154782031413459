import { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  TableFooter,
  TablePagination,
  useTheme
} from '@mui/material';
import { makeDatePretty } from 'helpers/pretty';
import ConfirmationModal from './ConfirmationModal';
import { ROOT_DOMAIN } from './GatewayWizard/gatewayConstants';
import CustomDomainModal from './CustomDomainModal';
import {GatewayRow, Gateways} from '../../store/gateways/types';
import type { BillingState } from '../../store/billing/types';
import type { MetricsState } from '../../store/metrics/types';
import { MoreHoriz } from '@mui/icons-material';
import { planTypes } from 'helpers/enums';
import { useGetGateways } from 'hooks';

interface GatewaysTableProps {
  totalRecords: number;
  currentPage: number;
  gatewaysRowsPerPage: number;
  setCurrentPage: (newPage: number) => void;
  deleteGateway: any;
  createCustomDomain: any;
  setAlert: (message: any, type: string) => void;
  getCustomDomainDNSStatus: any;
  deleteCustomDomain: any;
  metrics: MetricsState;
  billing: BillingState;
}

const GatewaysTable = ({
  totalRecords,
  currentPage,
  gatewaysRowsPerPage,
  setCurrentPage,
  deleteGateway,
  createCustomDomain,
  setAlert,
  getCustomDomainDNSStatus,
  deleteCustomDomain,
  billing
}: GatewaysTableProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [gatewayId, setGatewayId] = useState<string | null>(null);
  const [customDomainModalOpen, setCustomDomainModalOpen] = useState<boolean>(false);
  const [gatewayDetails, setGatewayDetails] = useState<GatewayRow | any>(null);
  const [customDomains, setCustomDomains] = useState<{ item: any }[]>([]);
  const [confirmRemoveDomainModalOpen, setConfirmRemoveDomainModalOpen] = useState<boolean>(false);

  const [moreModalAnchorEl, setMoreModalAnchorEl] = useState<null | HTMLElement>(null);
  const isMoreMenuOpen = Boolean(moreModalAnchorEl);
  const history = useHistory();
  const theme = useTheme();

  const checkDNSStatus = async (gateways: GatewayRow[]) => {
    const dnsStatuses = [];
    for (const gateway of gateways) {
      if (gateway?.customDomains?.length > 0) {
        const status = await getCustomDomainDNSStatus(gateway?.id, gateway?.customDomains[0]?.id);
        dnsStatuses.push(status?.data);
      }
    }

    setCustomDomains(dnsStatuses);
  };

  const handleOpenDeleteModal = (id: string) => {
    setGatewayId(id);
    setDeleteModalOpen(true);
  };

  const confirmDeleteGatedway = async () => {
    setLoading(true);

    await deleteGateway(gatewayId);

    setLoading(false);
    setDeleteModalOpen(false);
    setGatewayId(null);
  };

  const handleOpenCustomDomainModal = (gateway: GatewayRow) => {
    setGatewayDetails(gateway);
    setCustomDomainModalOpen(true);
  };

  const getDNSStatus = (row: GatewayRow | null | undefined) => {
    if (row && row?.customDomains?.length > 0) {
      const domainStatus = row?.customDomains?.find(
        (customDomain) => customDomain?.domain === row?.customDomains[0]?.domain
      );
      if (
        domainStatus?.sslValidationStatus !== 'pending' &&
        domainStatus?.domainValidationStatus !== 'pending_validation'
      ) {
        return (
          <span style={{ marginLeft: 20 }}>
            <i className="fas fa-check-circle"></i> <span>DNS Configured</span>
          </span>
        );
      } else {
        return (
          <button
            onClick={() => handleOpenCustomDomainModal(row)}
            style={{ marginLeft: 20 }}
            className="pinata-link-button"
          >
            <i style={{ marginRight: 5 }} className="fas fa-info-circle text-warning"></i>
            <u>Check DNS Configuration</u>
          </button>
        );
      }
    }
  };

  const handleRemoveDomain = async (row?: GatewayRow, confirm?: boolean) => {
    if (!confirm) {
      setGatewayDetails(row);
      setConfirmRemoveDomainModalOpen(true);
    } else {
      setLoading(true);
      if (row) {
        await deleteCustomDomain(row.id, row.customDomains[0].id);
      }
      setLoading(false);
      setConfirmRemoveDomainModalOpen(false);
    }
  };

  const renderRow = (row: GatewayRow, key: any) => {
    return (
      <TableRow key={key}>
        <TableCell>
          <Link to={`/gateway/${row.domain}`} style={{ color: theme.palette.primary.main }}>
            {row.customDomains && row.customDomains.length > 0
              ? row.customDomains[0].domain
              : `${row.domain}${ROOT_DOMAIN}`}
          </Link>
          {row?.customDomains && row?.customDomains?.length > 0 && <span>{getDNSStatus(row)}</span>}
        </TableCell>
        <TableCell>{row.restrict ? 'Restricted' : 'Open'}</TableCell>
        <TableCell>{makeDatePretty(row.createdAt)}</TableCell>
        <TableCell>
          <Link to="#" id={`gateways-more-item-${key}`}>
            <MoreHoriz
              onClick={(event: any) => {
                setGatewayDetails(row);
                setMoreModalAnchorEl(event.currentTarget);
              }}
              sx={{ color: theme.palette.primary.main }}
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  const { isLoadingGetGateways, errorGetGateways, dataGetGateways} = useGetGateways();

  const gateways = dataGetGateways?.data.items.rows || [];

  useEffect(() => {
    if (gateways && gateways?.length > 0) {
      checkDNSStatus(gateways);
    }
  }, [gateways]);

  const gatewayRows = useMemo(() => {
    if (gatewaysRowsPerPage > 0) {
      return gateways.slice(currentPage * gatewaysRowsPerPage, currentPage * gatewaysRowsPerPage + gatewaysRowsPerPage);
    } else {
      return gateways;
    }
  }, [gatewaysRowsPerPage, gateways]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Domain</TableCell>
            <TableCell>Access</TableCell>
            <TableCell>Created</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{gatewayRows.map((gateway: GatewayRow, key: any) => renderRow(gateway, key))}</TableBody>
        {isMoreMenuOpen && (
          <Menu
            anchorEl={moreModalAnchorEl}
            open={isMoreMenuOpen}
            onClose={() => {
              setGatewayDetails(null);
              setMoreModalAnchorEl(null);
            }}
          >
            <MenuItem
              id="gateway-info-create-item"
              onClick={() => {
                handleOpenCustomDomainModal(gatewayDetails);
                setMoreModalAnchorEl(null);
              }}
              disabled={billing.activePricingPlan?.type === planTypes.NEW_FREE.type}
            >
              {gatewayDetails?.customDomains?.length > 0 ? 'Domain Info' : 'Add Custom Domain'}
            </MenuItem>
            {gatewayDetails?.customDomains?.length > 0 && (
              <MenuItem
                id="gateways-details-item"
                onClick={() => {
                  handleRemoveDomain(gatewayDetails);
                  setMoreModalAnchorEl(null);
                }}
              >
                Remove Custom Domain
              </MenuItem>
            )}

              <MenuItem
                id="gateways-secure-link"
                onClick={() => {
                  setMoreModalAnchorEl(null);
                  history.push(`/developers/gateway-settings?gateway=${gatewayDetails.domain}`);
                }}
              >
                See Security Options
              </MenuItem>

            <MenuItem
              id="gateways-remove-item"
              onClick={() => {
                handleOpenDeleteModal(gatewayDetails?.id);
                setMoreModalAnchorEl(null);
              }}
              disabled={billing.activePricingPlan?.type === planTypes.NEW_FREE.type}
            >
              Remove Gateway
            </MenuItem>
          </Menu>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              count={totalRecords}
              rowsPerPage={gatewaysRowsPerPage}
              rowsPerPageOptions={[{ label: '', value: -1 }]}
              page={currentPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                native: true
              }}
              onPageChange={(evt, value) => {
                setCurrentPage(value);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {customDomainModalOpen && (
        <CustomDomainModal
          createCustomDomain={createCustomDomain}
          setCustomDomainModalOpen={setCustomDomainModalOpen}
          customDomainModalOpen={customDomainModalOpen}
          setAlert={setAlert}
          gatewayDetails={gatewayDetails}
          customDomains={customDomains}
        />
      )}
      {deleteModalOpen && (
        <ConfirmationModal
          title="Remove Gateway"
          content="Are you sure you want to remove this gateway? It cannot be undone."
          loading={loading}
          modalOpen={deleteModalOpen}
          toggleModal={setDeleteModalOpen}
          action={confirmDeleteGatedway}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
      {confirmRemoveDomainModalOpen && (
        <ConfirmationModal
          title="Remove Custom Domain"
          content={`Are you sure you want to remove this custom domain (${
            gatewayDetails && gatewayDetails.customDomains.length > 0 && gatewayDetails.customDomains[0].domain
          })?`}
          loading={loading}
          modalOpen={confirmRemoveDomainModalOpen}
          toggleModal={setConfirmRemoveDomainModalOpen}
          action={() => handleRemoveDomain(gatewayDetails, true)}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
    </TableContainer>
  );
};

export default GatewaysTable;
