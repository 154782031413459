import {useQuery} from "@tanstack/react-query";
import dayjs from "dayjs";
import { metricsApi } from "services/api";

const QUERY_KEYS = ['metrics'];

export const useGetMetrics = () => {
  const {
    isLoading: isLoadingGetMetrics,
    error: errorGetMetrics,
    data: dataGetMetrics 
  } = useQuery ({
    queryKey: QUERY_KEYS,
    queryFn: () =>
    metricsApi.get(`metrics/usage?start=${dayjs(Date()).toISOString()}&end=${dayjs(Date()).toISOString()}`)
  });
  return {isLoadingGetMetrics, errorGetMetrics, dataGetMetrics};
};
