import { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { AuthContext } from './common';
import { useHistory } from 'react-router-dom';
import { confirmSignUp, resendConfirmationCode } from 'hooks/useAuth';
import {Message, useForm} from 'react-hook-form';
import { codeFieldRules } from 'helpers/formFieldValidations';
import { getErrorMessage } from 'helpers/error.helper';
import { useAnalytics } from 'use-analytics';
import { ANALYTICS } from './../../common/constants';

enum RESEND_STATUSES {
  UNINITIALIZED = 'UNINITIALIZED',
  SENT = 'SENT',
  FAILED = 'FAILED'
}

export function ConfirmCode({ email, password }: { email: any; password: any }) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { setAuthAlert } = useContext(AuthContext);
  const { handleSubmit, register, formState } = useForm();
  const [resendStatus, setResendStatus] = useState<RESEND_STATUSES>(RESEND_STATUSES.UNINITIALIZED);
  const history = useHistory();
  const { errors } = formState;
  const { track } = useAnalytics();

  const submit = async (data: any) => {
    try {
      setSubmitting(true);
      const { confirmCode } = data;
      await confirmSignUp(email, password, confirmCode);
      track(ANALYTICS.AUTH.CONFIRMATION);
      history.push('/pinmanager');
    } catch (error) {
      const message = getErrorMessage(error);
      setAuthAlert({ type: 'error', message });
    } finally {
      setSubmitting(false);
    }
  };

  const handleResend = async () => {
    try {
      const resendRes = await resendConfirmationCode(email);
      if (resendRes) {
        setResendStatus(RESEND_STATUSES.SENT);
        setAuthAlert({ type: 'success', message: 'Code sent!' });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      setAuthAlert({ type: 'error', message });
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      const errorsArr = Object.keys(errors);
      const errorMsg: any = errors[errorsArr[0]]?.message || '';
      setAuthAlert({ type: 'error', message:  errorMsg});
    } else {
      // remove error alert if validation passed
      setAuthAlert(null);
    }
  }, [errors]);

  return (
    <Box component="form" onSubmit={handleSubmit(submit)}>
      <Typography variant="h2">Confirm code</Typography>
      <Typography variant="caption">If you don't see it in your main inbox, be sure to check your spam folder.</Typography>
      <TextField
        fullWidth
        placeholder="Confirm code"
        autoComplete="one-time-code"
        variant="standard"
        id="d2-auth-confirm-code"
        sx={{ my: 3 }}
        inputProps={{
          ...register('confirmCode', codeFieldRules)
        }}
      />
      <Button type="submit" size="large" fullWidth sx={{ my: 1 }} id="d2-auth-confirm-submit">
        {submitting ? <CircularProgress /> : 'Verify code'}
      </Button>
      <Button
        variant={'link'}
        onClick={handleResend}
        disabled={resendStatus === RESEND_STATUSES.SENT}
        id="d2-auth-confirm-resend"
      >
        Resend Code
      </Button>
    </Box>
  );
}
