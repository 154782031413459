import React from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

export enum AuthPages {
  AUTH = 'AUTH',
  SIGNUP = 'SIGNUP',
  MFA = 'MFA',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  EMAIL_CODE = 'EMAIL_CODE'
}

export enum RESEND_STATUSES {
  UNINITIALIZED = 'UNINITIALIZED',
  SENT = 'SENT',
  FAILED = 'FAILED'
}

export type AuthAlert = { type: 'error' | 'success'; message: string };

interface AuthContextState {
  formData: {
    [key: string]: string;
  };
  setFormData: (data: any) => any;
  currentPage: AuthPages;
  setCurrentPage: (newPage: AuthPages) => any;
  cognitoUser: CognitoUser | any;
  setCognitoUser: (newUser: CognitoUser | any) => any;
  authAlert: null | AuthAlert;
  setAuthAlert: (newAlert: null | AuthAlert) => any;
}

export const AuthContext = React.createContext<AuthContextState>({
  formData: {
    email: '',
    password: '',
    code: ''
  },
  setFormData: (newData: any) => {},
  currentPage: AuthPages.AUTH,
  setCurrentPage: (newPage: AuthPages) => {},
  cognitoUser: CognitoUser,
  setCognitoUser: (newUser: CognitoUser) => {},
  authAlert: null,
  setAuthAlert: (newAlert: AuthAlert | null) => {}
});
