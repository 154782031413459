import { useState, useEffect } from 'react';
import { Typography, Box, Paper, Button, Grid } from '@mui/material';
import EditAccountDetails from './EditAccountDetails';
import QRModal from './QRModal';
import { useAuth, verifytTotp } from '../../hooks/useAuth';
import { CheckCircle } from '@mui/icons-material';

const AccountDetailsCard = ({ user, setAlert, changeEmail }) => {
  const { getAuthenticatedUser } = useAuth();
  const [editing, setEditing] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [mfaConfigured, setMfaConfigured] = useState(false);
  const [qrModalOpen, setQRModal] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [authenticatorCode, setAuthenticatorCode] = useState('');
  const { getMFAOptions, setupTotp, setPreferredMFA } = useAuth();

  useEffect(() => {
    checkOnMFA();
  }, []);

  const checkOnMFA = async () => {
    const mfa = await getMFAOptions();
    if (mfa === 'NOMFA') {
      setMfaConfigured(false);
    } else {
      setMfaConfigured(true);
    }
  };

  const setupMFA = async () => {
    try {
      setLoading(true);
      const code = await setupTotp();
      setLoading(false);
      if (code) {
        setMfaCode(code);
        setQRModal(true);
      }
    } catch (error) {
      setLoading(false);
      setAlert(error.message, 'error');
    }
  };

  const disableMFA = async () => {
    try {
      setLoading(true);
      await setPreferredMFA('NOMFA');
      setLoading(false);
      checkOnMFA();
      setAlert('MFA Disabled', 'success');
    } catch (error) {
      setLoading(false);
      setAlert(error.message, 'error');
    }
  };

  const submitAuthenticatorCode = async () => {
    try {
      setQRModal(false);
      await verifytTotp(authenticatorCode);
      checkOnMFA();
      setAlert('MFA Configured!', 'success');
      setAuthenticatorCode('');
      setMfaCode('');
    } catch (error) {
      setAlert(error.message, 'error');
    }
  };

  useEffect(() => {
    getCognitoUser();
  }, [editing]);

  const getCognitoUser = async () => {
    const cogUser = await getAuthenticatedUser();
    setLoggedInUser(cogUser);
  };
  const getName = (nameType) => {
    if (nameType === 'first') {
      return loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName']
        ? loggedInUser.attributes['custom:firstName']
        : '';
    } else {
      return loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:lastName']
        ? loggedInUser.attributes['custom:lastName']
        : '';
    }
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            {editing ? (
              <EditAccountDetails
                changeEmail={changeEmail}
                setEditing={setEditing}
                setAlert={setAlert}
                user={user}
                loggedInUser={loggedInUser}
              />
            ) : (
              <>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Account Info
                </Typography>
                <Box>
                  <Typography variant="body2">
                    Email: {user && user.email}
                    <CheckCircle color="success" sx={{ mx: 1 }} />
                  </Typography>
                  {loggedInUser && loggedInUser.attributes && loggedInUser.attributes['custom:firstName'] && (
                    <Typography variant="body2">
                      Name: {getName('first')} {getName('last')}
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={() => setEditing(true)}>Edit Profile</Button>
                  </Box>
                </Box>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4 }}>
            <Typography variant="h5">Multi-Factor Authentication</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              {mfaConfigured ? (
                <Button onClick={disableMFA} color="primary">
                  {loading ? 'Disabling...' : 'Disable MFA'}
                </Button>
              ) : (
                <Button onClick={setupMFA} color="primary">
                  {loading ? 'Loading MFA Code...' : 'Set Up MFA'}
                </Button>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {mfaCode && (
        <QRModal
          submitAuthenticatorCode={submitAuthenticatorCode}
          authenticatorCode={authenticatorCode}
          setAuthenticatorCode={setAuthenticatorCode}
          code={mfaCode}
          setQRModal={setQRModal}
          qrModalOpen={qrModalOpen}
          email={user.email}
        />
      )}
    </>
  );
};

export default AccountDetailsCard;
