import FileData from './FileData';
import UploadForm from './UploadForm';
import Uploading from './Uploading';

const UploadWizard = ({
  steps,
  setAlert,
  handleUpload,
  handleFileInput,
  uploadType,
  selectedFiles,
  fileName,
  setFileName,
  description,
  setDescription,
  handleSetStep,
  upload,
  progress,
  showNameThresh,
  setShowNameThresh,
  nameInputCount,
  setNameInputCount,
  showDescrThresh,
  setShowDescrThresh,
  descrInputCount,
  setDescrInputCount,
  thumbnail,
  setThumbnail,
  cid,
  setCID,
  uploading,
  setUploading,
  submarine,
  setSubmarine,
  billing
}: any) => {
  const activeStep = steps.filter((s: any) => s.active)[0];
  switch (activeStep.key) {
    case 1:
      return (
        <div>
          <UploadForm selectedFiles={selectedFiles} uploadType={uploadType} handleFileInput={handleFileInput} />
        </div>
      );
    case 2:
      return (
        <div>
          <FileData
            setAlert={setAlert}
            fileName={fileName}
            setFileName={setFileName}
            handleSetStep={handleSetStep}
            setNameInputCount={setNameInputCount}
            submarine={submarine}
            setSubmarine={setSubmarine}
            upload={upload}
            billing={billing}
          />
        </div>
      );
    case 3:
      return <Uploading progress={progress} />;
    default:
      return (
        <div>
          <UploadForm selectedFiles={selectedFiles} uploadType={uploadType} handleFileInput={handleFileInput} />
        </div>
      );
  }
};

export default UploadWizard;
