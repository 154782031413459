import { ReactNode, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { setAlert } from '../../store/alert/alert.actions';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Link,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme
} from '@mui/material/';
import { PinataBox } from 'components/Common/MuiComponents';
import { SignInForm } from './SignInForm';
import { RegisterForm } from './RegisterForm';
import { ForgotPassword } from './ForgotPassword';
import { ConfirmCode } from './ConfirmCode';
import { MFAForm } from './MFAForm';
import { AuthContext, AuthPages } from './common';
import { ArrowBack } from '@mui/icons-material';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const AuthTab = styled(Tab)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  '&.Mui-selected': {
    color: theme.palette.text.primary
  }
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <>
      {value === index && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
          }}
          hidden={value !== index}
          role="tabpanel"
          aria-labelledby={`simple-tab-${index}`}
          id={`simple-tabpanel-${index}`}
          {...other}
        >
          {children}
        </Box>
      )}
    </>
  );
}

const MainAuthSignUp = ({ isBuilder, setIsBuilder, setCurrentPage, formData, setFormData }: any) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1
      }}
      component="form"
      onSubmit={() => setCurrentPage(AuthPages.SIGNUP)}
    >
      <Box sx={{ margin: 'auto 0' }}>
        <TextField
          variant="standard"
          fullWidth
          label="Email"
          type="email"
          placeholder="Enter your email"
          autoComplete="email"
          required
          value={formData.email}
          onChange={(event: any) => setFormData({ email: event.target.value })}
        />
        <Box sx={{ my: 3 }}>
          <Typography variant={'body2'} sx={{ mb: 1 }} component="p">
            Which title describes you the best?
          </Typography>
          <Box sx={{ display: 'flex', gap: 1.5 }}>
            <Button
              sx={{
                borderRadius: 1,
                background: isBuilder ? 'linear-gradient(270.02deg, #06B6DD -22.5%, #8A02F1 34.07%);' : ''
              }}
              fullWidth
              color={isBuilder ? 'primary' : 'inherit'}
              onClick={() => setIsBuilder(true)}
            >
              Builder
            </Button>
            <Button
              sx={{
                borderRadius: 1,
                background: !isBuilder ? 'linear-gradient(270.02deg, #06B6DD -22.5%, #8A02F1 34.07%);' : ''
              }}
              fullWidth
              color={isBuilder ? 'inherit' : 'primary'}
              onClick={() => setIsBuilder(false)}
            >
              Creator
            </Button>
          </Box>
        </Box>
      </Box>
      <Button sx={{ mt: 1 }} type="submit" fullWidth disabled={!formData.email.length} color="primary" size="large">
        Next
      </Button>
    </Box>
  );
};

const AuthTabs = ({
  formData,
  setFormData,
  selectedTab,
  setSelectedTab,
  isBuilder,
  setIsBuilder
}: {
  formData: any;
  setFormData: (data: any) => void;
  selectedTab: number;
  setSelectedTab: (value: number) => void;
  isBuilder: boolean;
  setIsBuilder: (isBuilder: boolean) => void;
}) => {
  const { setCurrentPage } = useContext(AuthContext);
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      <Tabs
        centered
        sx={{
          '& .MuiTabs-indicator': {
            height: '10px',
            background: 'linear-gradient(270.02deg, #06B6DD -22.5%, #8A02F1 34.07%);',
            borderRadius: '100px',
            display: 'block'
          }
        }}
        value={selectedTab}
        onChange={(event: SyntheticEvent, newValue: number) => setSelectedTab(newValue)}
        variant="fullWidth"
      >
        <AuthTab
          label="Log in"
          onClick={() => {
            history.push('signin');
          }}
          sx={{
            fontWeight: 900,
            paddingRight: 0,
            paddingLeft: 0,
            paddingTop: 0,
            [theme.breakpoints.up('sm')]: { fontSize: '2.625rem' }
          }}
        />
        <AuthTab
          label="Sign up"
          onClick={() => {
            history.push('register');
          }}
          sx={{
            fontWeight: 900,
            paddingRight: 0,
            paddingLeft: 0,
            paddingTop: 0,
            [theme.breakpoints.up('sm')]: { fontSize: '2.625rem' }
          }}
        />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <SignInForm />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <MainAuthSignUp
          formData={formData}
          setFormData={setFormData}
          setCurrentPage={setCurrentPage}
          isBuilder={isBuilder}
          setIsBuilder={setIsBuilder}
        />
      </TabPanel>
    </>
  );
};

function MainAuthForm({ setAlert }: { setAlert: (message: string, type: string) => void }) {
  const [selectedTab, setSelectedTab] = useState<number>(0); // index of tab selected. LOGIN = 0, SIGNUP = 1
  const [isBuilder, setIsBuilder] = useState<boolean>(false); // index of button chosen. Builder/Developer = 0, Creator = 1
  const { currentPage, authAlert, setAuthAlert, setCurrentPage, formData, setFormData } = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/register') {
      setSelectedTab(1);
    }
  }, []);

  useEffect(() => {
    setAuthAlert(null);
  }, [currentPage]);

  return (
    <PinataBox
      sx={{
        flexDirection: 'column',
        flexGrow: currentPage === AuthPages.AUTH ? 1 : 0
      }}
    >
      {authAlert && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Alert severity={authAlert?.type} sx={{ marginTop: '-20px', mb: 1 }}>
            {authAlert?.message}
          </Alert>
        </Box>
      )}
      {currentPage === AuthPages.AUTH ? (
        <AuthTabs
          formData={formData}
          setFormData={setFormData}
          setIsBuilder={setIsBuilder}
          isBuilder={isBuilder}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      ) : (
        <>
          <Button
            variant={'text'}
            onClick={() => setCurrentPage(AuthPages.AUTH)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: (theme) => theme.palette.grey.A700,
              mb: 2,
              fontSize: '14px',
              textAlign: 'start',
              justifyContent: 'flex-start',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <ArrowBack />
            Back to login
          </Button>
          {currentPage === AuthPages.SIGNUP && (
            <RegisterForm isBuilder={isBuilder} formData={formData} setFormData={setFormData} setAlert={setAlert} />
          )}
          {currentPage === AuthPages.EMAIL_CODE && <ConfirmCode password={formData.password} email={formData.email} />}
          {currentPage === AuthPages.MFA && <MFAForm />}
          {currentPage === AuthPages.FORGOT_PASSWORD && <ForgotPassword setAlert={setAlert} />}
        </>
      )}
    </PinataBox>
  );
}

export default connect(null, { setAlert })(MainAuthForm);
