import { Grid } from "@mui/material";
import GatewayData from "../Data/GatewayData";

type Props = {
  dateStartFilter: Date;
  dateEndFilter: Date;
  gatewayFilter: string;
  config: any;
}

const Dashboard = ({config, dateStartFilter, dateEndFilter, gatewayFilter}: Props) => {

  return (
    <Grid container spacing={4} direction='column'>
      {
        config.map((configData: any, index: number) => 
          <Grid item key={index}>
            <GatewayData
              title={configData.title}
              dateStartFilter={dateStartFilter}
              dateEndFilter={dateEndFilter}
              gatewayFilter={gatewayFilter}
              metric={configData.metric}
              by={configData.by}
              top={configData.top}
            />
          </Grid>  
        )
      }
    </Grid>
  )
}

export default Dashboard;
