import { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, Typography, TextField, Box, Button } from '@mui/material';
import { PinataDialogTitle } from 'components/Common/MuiComponents';
import { pinHash } from '../../actions/pin.actions';

interface PinByHashModalProps {
  pinByHashOpen: boolean;
  setPinByHashOpen: (isOpen: boolean) => void;
  pinHash: (cid: string, name: string) => void;
}

const PinByHashModal = ({ pinByHashOpen, setPinByHashOpen, pinHash }: PinByHashModalProps) => {
  const [customPinName, setCustomPinName] = useState('');
  const [cid, setCid] = useState('');
  const closeModal = () => {
    setPinByHashOpen(false);
  };
  const handleSubmit = () => {
    setCustomPinName('');
    setCid('');
    pinHash(cid, customPinName);
    closeModal();
  };
  return (
    <Dialog open={pinByHashOpen} onClose={closeModal} fullWidth>
      <PinataDialogTitle onClose={closeModal}>Pin By CID</PinataDialogTitle>
      <DialogContent>
        <Typography component="p" variant="body2">
          Provide a Content Identifier(CID), also known as a hash, to pin and an optional name for that pin. Pinata will
          then add the CID to the queue and start searching for your content. Once your content has been found, it will
          be pinned.
        </Typography>
        <Typography component="p" variant="body2">
          Please note: The IPFS network is big, and it might take quite some time to find / retrieve content. Please be
          patient as our nodes search for your content. It is also possible that the content is no longer available on
          the network. In that scenario, your pin by CID action will fail.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="IPFS CID To Pin"
            placeholder="Qma3h..."
            onChange={(e) => setCid(e.target.value)}
            value={cid}
          />

          <TextField
            fullWidth
            label="Custom Name For Pin"
            placeholder="Optional pin name"
            onChange={(e) => setCustomPinName(e.target.value)}
            value={customPinName}
          />
          <Button sx={{ mt: 1 }} fullWidth onClick={handleSubmit} color="primary">
            Search and Pin
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default connect(null, { pinHash })(PinByHashModal);
