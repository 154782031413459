import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Grid, Typography, useTheme, useMediaQuery, Slide } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { PinataBox } from 'components/Common/MuiComponents';
import { PinataAlert } from 'components/Common/VerticalAlert';
import PinnieImg from '../../assets/images/pinnieMain.png';
import Clouds from '../../assets/images/clouds.png';
import smallPinnie from '../../assets/images/Pinnie.svg';
import { Themes } from '../../themes';
import { AuthAlert, AuthContext, AuthPages } from './common';
import { ThemeContext } from '../../services/globalContext';
import { PinataState } from 'store/types';

const MainLayout = ({ children, mainAlert }: any) => {
  const [currentPage, setCurrentPage] = useState(AuthPages.AUTH);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [alert, setAlert] = useState<AuthAlert | null>(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [formData, setFormData] = useState<any>({
    email: '',
    password: '',
    code: ''
  });
  const theme = useTheme();
  const showGreetingsBox = useMemo(() => currentPage === AuthPages.AUTH, [currentPage]);
  const moreThenMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const themeContext = useContext(ThemeContext);
  const pinnieContainerRef = useRef<any>(null);

  useEffect(() => {
    themeContext.setThemeName('dark');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        formData,
        setFormData,
        cognitoUser,
        setCognitoUser,
        currentPage,
        setCurrentPage,
        authAlert: alert,
        setAuthAlert: setAlert
      }}
    >
      <img width="420px" src={PinnieImg} style={{display: 'none'}} onLoad={() => {setImgLoaded(true)}}/>
      <Box sx={{ background: theme.palette.background.default, height: '100%', overflowY: 'auto' }}>
        {mainAlert?.alert?.message && (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <PinataAlert
              sx={{
                position: 'fixed'
              }}
              variant="filled"
              alert={mainAlert}
            />
          </Box>
        )}
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Grid container sx={{ columnGap: '24px', maxHeight: '754px', height: '82%' }} justifyContent="center">
            <Grid item xs={12} sm={12} md={5} flexDirection="column" display="flex" sx={{ gap: '24px' }}>
              {showGreetingsBox && (
                <PinataBox
                  sx={{
                    position: 'relative',
                    backgroundColor: 'rgba(49, 49, 49, 0.75)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: '350px',
                    height: '45%'
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '100%' }}>
                    <Box sx={{ height: '30px', [theme.breakpoints.down('xl')]: { height: '3.125rem' } }}>
                      <img style={{ position: 'absolute', top: '-20%', width: '5.25rem' }} src={smallPinnie} />
                    </Box>
                    <Typography variant="h2" sx={{ mb: 3, width: '240px' }}>
                      Welcome to Pinata!
                    </Typography>
                    <Typography
                      component="p"
                      variant="body1"
                      sx={{
                        color: theme.palette.grey['400'],
                        [theme.breakpoints.up('sm')]: { width: '320px' }
                      }}
                    >
                      Pinata offers a media distribution solution that enables brands to expand the reach of their content and generate revenue opportunities beyond any single platform.
                    </Typography>
                  </Box>
                </PinataBox>
              )}
              <ThemeProvider theme={Themes.light}>
                <>{children}</>
              </ThemeProvider>
            </Grid>
            {moreThenMediumScreen && (
              <Grid
                item
                sm={6}
                md={5}
                display="flex"
                justifyContent="center"
                alignItems="stretch"
                sx={{
                  background: 'linear-gradient(180deg, #953BED 0%, #01BCDD 100%);',
                  borderRadius: theme.spacing(6),
                  overflow: 'hidden'
                }}
              >
                <PinataBox
                  sx={{
                    backgroundImage: `url(${Clouds})`,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                    backgroundColor: 'transparent',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 0,
                    boxShadow: 'none',
                    margin: 0
                  }}
                  ref={pinnieContainerRef}
                >
                  { imgLoaded &&
                    <Slide direction="up" in={true} container={pinnieContainerRef.current} timeout={3000}>
                      <img width="420px" src={PinnieImg}/>
                    </Slide>
                  }
                </PinataBox>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </AuthContext.Provider>
  );
};

const mapStateToProps = (state: PinataState) => ({
  mainAlert: state.alert
});

export default connect(mapStateToProps, null)(MainLayout);
