import { Box, Container, Divider } from '@mui/material';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

function GlobalNavigation({ children }: Props) {
  return (
    <Box>
      <Container maxWidth={false} component="header" disableGutters>
        {children}
      </Container>
      <Divider />
    </Box>
  );
}

export default GlobalNavigation;
