const cookieStorage = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  path: '/',
  expires: 20,
  secure: true
};

export default {
  Auth: {
    region: 'us-east-1',
    endpoint: process.env.REACT_APP_NODE_ENV_LOCAL === 'localdev' ? process.env.REACT_APP_AWS_AUTH_URL : null,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: process.env.REACT_APP_NODE_ENV_LOCAL === 'localdev' ? false : cookieStorage,
    oauth: {
      domain: process.env.REACT_APP_AWS_AUTH_URL,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_LOGIN_REDIRECT,
      redirectSignOut: process.env.REACT_APP_SIGNOUT_REDIRECT,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'Pinata API',
        endpoint: process.env.REACT_APP_PINATA_API_URL
      }
    ]
  }
};
