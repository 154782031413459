import { useEffect } from 'react';
import { Alert as MuiAlert, AlertProps as MuiAlertProps, Box, IconButton, Snackbar, Typography } from '@mui/material';
import { Check, ErrorOutline, WarningAmberRounded, InfoOutlined, Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { AlertState } from '../../store/alert/types';
import { AlertTitle } from '@mui/material';

const IconBox = styled(Box)(({ theme }) => ({
  borderRadius: 0.25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px'
}));

interface AlertProps extends MuiAlertProps {
  alert: AlertState;
  removeAlert?: () => void;
  showTitle?: boolean;
  titleText?: string;
}

const alertTitles = {
  success: 'Success!',
  error: 'Error',
  warning: 'Uh oh, something went wrong',
  info: 'Did you know?'
};

//TODO: Get the colors from the theme once we refactor alert into UseContext
const iconMapping = {
  success: (
    <IconBox sx={{ backgroundColor: '#CCF1DF' }}>
      <Check sx={{ color: '#05542D' }} />{' '}
    </IconBox>
  ),
  error: (
    <IconBox sx={{ backgroundColor: '#F8BDBD' }}>
      <ErrorOutline sx={{ color: '#5C0707' }} />{' '}
    </IconBox>
  ),
  warning: (
    <IconBox>
      <WarningAmberRounded sx={{ color: '#68430A' }} />{' '}
    </IconBox>
  ),
  info: (
    <IconBox sx={{ backgroundColor: '#D4F9FF' }}>
      <InfoOutlined sx={{ color: '#005765' }} />{' '}
    </IconBox>
  )
};

export const PinataAlert = (props: AlertProps) => {
  const { alert, showTitle = true, titleText, removeAlert, ...rest } = props;
  return (
    <MuiAlert
      {...rest}
      severity={alert?.alert?.type || 'info'}
      iconMapping={iconMapping}
      action={
        removeAlert && (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              removeAlert();
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        )
      }
    >
      {showTitle && (
        <AlertTitle>{!!titleText?.length ? titleText : alertTitles[alert?.alert?.type || 'success']}</AlertTitle>
      )}
      <Typography variant="body2">{alert?.alert?.message}</Typography>
    </MuiAlert>
  );
};

const VerticalAlert = ({ alert }: AlertProps) => {
  return alert?.alert?.message ? (
    <Snackbar open={Boolean(alert?.alert?.message)} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      <PinataAlert alert={alert} />
    </Snackbar>
  ) : null;
};

export default VerticalAlert;
