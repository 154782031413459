import { Box, Button, CircularProgress, FormGroup, TextField, Typography } from '@mui/material';
import { confirmMFA } from '../../hooks/useAuth';
import { useContext, useState } from 'react';
import { AuthContext } from './common';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getErrorMessage } from 'helpers/error.helper';
import { codeFieldRules } from 'helpers/formFieldValidations';

export function MFAForm() {
  const { cognitoUser, setAuthAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const handleFormSubmit = async (data: any) => {
    try {
      setLoading(true);
      const confirmCodeRes: any = await confirmMFA(cognitoUser, data?.mfaCode);
      if (confirmCodeRes?.success) {
        history.push(`/authenticate${location.search}`);
      } else {
        setAuthAlert({ type: 'error', message: confirmCodeRes?.error?.message });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      setAuthAlert({ type: 'error', message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      onSubmit={handleSubmit(handleFormSubmit)}
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}
    >
      <Typography variant={'h2'}>Enter MFA code</Typography>
      <TextField
        fullWidth
        placeholder="MFA code"
        autoComplete="one-time-code"
        variant="standard"
        id="d2-auth-mfa-code"
        sx={{ my: 3 }}
        inputProps={{
          ...register('mfaCode', codeFieldRules)
        }}
      />
      <Button size={'medium'} type="submit" fullWidth sx={{ mt: 1 }} id="d2-auth-mfa-confirm">
        {loading ? <CircularProgress /> : 'Verify code'}
      </Button>
    </Box>
  );
}
