import { useMemo } from 'react';

import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableContainer
} from '@mui/material';
import { PinataDialogTitle } from 'components/Common/MuiComponents';

// Metadata Limits
// Max 100 key/value pairs
// Key length max 255
// Value max length 1000

interface MetadataModalProps {
  setShowEditMetaDataModal: (isOpen: boolean) => void;
  showEditMetaDataModal: boolean;
  keyValues: any[];
  fileName: string;
  handleAddKeyValue: () => void;
  setFileName: (fileName: string) => void;
  handleSaveMetaData: () => void;
  handleDeleteKeyValue: (id: string) => void;
  newKey: string;
  newValue: string;
  setNewKey: (newKey: string) => void;
  setNewValue: (newValue: string) => void;
}

const MetadataModal = ({
  setShowEditMetaDataModal,
  showEditMetaDataModal,
  keyValues,
  fileName,
  handleAddKeyValue,
  setFileName,
  handleSaveMetaData,
  handleDeleteKeyValue,
  newKey,
  newValue,
  setNewKey,
  setNewValue
}: MetadataModalProps) => {
  const invalidValue = useMemo(() => newValue?.length >= 1000, [newValue]);
  const invalidKey = useMemo(() => newKey?.length >= 255, [newKey]);

  return (
    <Dialog open={showEditMetaDataModal} onClose={() => setShowEditMetaDataModal(false)} fullWidth sx={{ p: 2 }}>
      <PinataDialogTitle onClose={() => setShowEditMetaDataModal(false)}>Edit File Details</PinataDialogTitle>
      <DialogContent>
        <TextField
          sx={{ my: 2 }}
          fullWidth
          label="Name"
          onChange={(e) => setFileName(e.target.value)}
          value={fileName}
          placeholder="name.png"
        />
        {keyValues.length > 0 && (
          <TableContainer>
            <Table stickyHeader padding="normal">
              <TableHead>
                <TableRow sx={{ '& .MuiTableCell-root': { backgroundColor: 'inherit' } }}>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ width: '100%', maxHeight: '100px', overflow: 'scroll' }}>
                {keyValues
                  .filter((k) => !!k.value)
                  .map((kv) => {
                    return (
                      <TableRow key={kv.id}>
                        <TableCell>{kv.key}</TableCell>
                        <TableCell>{kv.value}</TableCell>
                        <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button fullWidth size="small" color="error" onClick={() => handleDeleteKeyValue(kv.id)}>
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box sx={{ my: 2 }}>
          <Typography component="p">Add New Key/Value</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              label="Key"
              value={newKey}
              onChange={(e) => setNewKey(e.target.value)}
              placeholder="key"
              error={invalidKey}
            />
            <TextField
              label="Value"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              placeholder="value"
              error={invalidValue}
            />
            <Button disabled={invalidValue || invalidKey} onClick={handleAddKeyValue} size="small" variant="outlined">
              Add
            </Button>
          </Box>
        </Box>
        <Button fullWidth onClick={handleSaveMetaData} color="primary">
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default MetadataModal;
