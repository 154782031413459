import { useState, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  TextField,
  Typography,
  Box,
  CircularProgress,
  FormHelperText
} from '@mui/material';
import { ArrowForward, CheckCircle } from '@mui/icons-material';
import { ROOT_DOMAIN } from './GatewayWizard/gatewayConstants';
import { PinataDialogTitle } from 'components/Common/MuiComponents';
import type { GatewayRow } from 'store/gateways/types';

const validDomain = (value: string): boolean => {
  const tooLong = value.length > 103;
  const slashMatch = value.includes('/');
  const colonMatch = value.includes(':');
  const spaceMatch = value.includes(' ');
  const match = slashMatch || colonMatch || spaceMatch || tooLong;
  return !match;
};

const DNSInstructions = ({
  subdomain,
  gatewayDetails
}: {
  subdomain: string;
  gatewayDetails: GatewayRow | null | undefined;
}) => (
  <Box
    sx={{
      width: '100%',
      background: '#282828',
      color: '#fff',
      p: 3,
      borderRadius: 5
    }}
  >
    <code style={{ color: '#fff' }}>
      {subdomain} CNAME {`${gatewayDetails?.domain}${ROOT_DOMAIN}.`}
    </code>
  </Box>
);

interface CustomDomainModalProps {
  customDomainModalOpen: boolean;
  setCustomDomainModalOpen: (isOpen: boolean) => void;
  gatewayDetails: GatewayRow | null | undefined;
  customDomains: Array<any>;
  setAlert: (message: string, type: string) => void;
  createCustomDomain: any;
}

const CustomDomainModal = ({
  customDomainModalOpen,
  setCustomDomainModalOpen,
  gatewayDetails,
  customDomains,
  setAlert,
  createCustomDomain
}: CustomDomainModalProps) => {
  const [customDomain, setCustomDomain] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (value: string) => {
    setCustomDomain(value);
  };

  const isButtonDisabled = useMemo(() => customDomain.length < 6 || !validDomain(customDomain), [customDomain]);

  const handleCreate = async () => {
    if (customDomain.length < 6) {
      return setAlert('Custom domains must be at least 6 characters', 'error');
    } else {
      if (validDomain(customDomain)) {
        try {
          setLoading(true);
          await createCustomDomain(gatewayDetails?.id, customDomain);
          setCustomDomainModalOpen(false);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const renderDomainModal = () => {
    if (loading) {
      return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    } else {
      if (gatewayDetails?.customDomains && gatewayDetails?.customDomains?.length > 0) {
        const thisDomain = customDomains.find((c) => c.domain === gatewayDetails?.customDomains[0].domain);

        const getSubdomain = (fullDomain: string) => {
          if (fullDomain.split('.').length > 2) {
            return fullDomain.split('.')[0];
          }
          return '@';
        };
        const subdomain = getSubdomain(gatewayDetails?.customDomains[0].domain);

        return (
          <div>
            {thisDomain &&
            thisDomain?.sslValidationStatus === 'active' &&
            thisDomain?.domainValidationStatus === 'active' ? (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant="h6" sx={{ my: 2 }}>
                  <CheckCircle color="success" />
                  Your custom domain is successfully configured!
                </Typography>
                <Typography variant="h6" sx={{ my: 2 }}>
                  {`${gatewayDetails.domain}${ROOT_DOMAIN}`} <ArrowForward sx={{ mx: 1 }} />
                  {gatewayDetails.customDomains[0].domain}
                </Typography>
                <DNSInstructions subdomain={subdomain} gatewayDetails={gatewayDetails} />
              </Box>
            ) : (
              <Box sx={{ my: 3 }}>
                <Typography variant="h5">Configure DNS</Typography>
                <p>
                  For your custom domain to take effect, you'll need to update the DNS records with your domain
                  registrar. Log into your domain registrar and update the DNS records with the following info:{' '}
                </p>
                <Typography variant="h5">CNAME</Typography>
                <p>
                  Point subdomain to <strong>{`${gatewayDetails.domain}${ROOT_DOMAIN}`}</strong>
                </p>
                <DNSInstructions subdomain={subdomain} gatewayDetails={gatewayDetails} />
                <Box sx={{ mt: 3 }}>
                  <p>Once you've entered your DNS records, you may need to wait up to 24 hours.</p>
                  <Button onClick={() => setCustomDomainModalOpen(false)}>Got it</Button>
                </Box>
              </Box>
            )}
          </div>
        );
      } else {
        return (
          <Box sx={{ py: 2 }}>
            <TextField
              fullWidth
              id="domain-search"
              placeholder="www.domain.com"
              onChange={(e) => handleChange(e.target.value)}
              value={customDomain}
              label="Domain name"
            />
            {customDomain.length < 6 && (
              <FormHelperText sx={{ fontSize: 12, p: 0, m: 0 }}>
                Custom domains must be at least 6 characters
              </FormHelperText>
            )}
          </Box>
        );
      }
    }
  };

  return (
    <Dialog fullWidth open={customDomainModalOpen} sx={{ p: 2 }}>
      <PinataDialogTitle onClose={() => setCustomDomainModalOpen(false)}>Add Custom Domain</PinataDialogTitle>
      <DialogContent dividers>{renderDomainModal()}</DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setCustomDomainModalOpen(false)}>
          Cancel
        </Button>
        <Button disabled={!validDomain(customDomain) || loading || isButtonDisabled} onClick={handleCreate}>
          {loading ? 'Adding domain...' : 'Add Custom Domain'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDomainModal;
