import { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { planTypes } from 'helpers/enums';
import useSignOut from '../../../hooks/useSignOut';
import { ThemeContext } from '../../../services/globalContext';
import { UserState } from '../../../store/user/types';
import { PinataState } from '../../../store/types';
import { Menu, MenuItem, IconButton, Tooltip, ListItemIcon, Divider } from '@mui/material';
import { PersonOutlineOutlined, AccountBalanceWalletOutlined, Key, PowerSettingsNew } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { Plan } from 'store/billing/types';
import UserProfileAvatar from 'components/Common/UserProfileAvatar';

interface ProfileMenuProps {
  user: UserState;
  success?: any;
  currentPlan: Plan | null;
}

const ProfileMenu = ({ user, currentPlan }: ProfileMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { gatewaySettings, redesign } = useFlags();
  const { signOut } = useSignOut();
  const themeContext = useContext(ThemeContext);

  const canAccessSubmarine =
    currentPlan?.type !== planTypes.FREE.type ||
    // hardcoded support for old plans
    currentPlan?.name === 'PROFESSIONAL' ||
    currentPlan?.name === 'INDIVIDUAL';

  const handleToggleTheme = () => {
    themeContext.setThemeName(themeContext.themeName === 'dark' ? 'light' : 'dark');
  };

  return (
    <>
      <Tooltip title="Account Settings" disableInteractive>
        <IconButton
          onClick={(evt) => {
            setMenuOpen(true);
            setAnchorEl(evt.currentTarget);
          }}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={menuOpen ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
        >
          <UserProfileAvatar user={user?.user} />
        </IconButton>
      </Tooltip>

      {menuOpen && (
        <Menu open={menuOpen} onClick={() => setMenuOpen(false)} onClose={() => setMenuOpen(false)} anchorEl={anchorEl}>
          <MenuItem component={Link} to="/profile">
            <ListItemIcon>
              <PersonOutlineOutlined fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem component={Link} to="/billing">
            <ListItemIcon>
              <AccountBalanceWalletOutlined fontSize="small" />
            </ListItemIcon>
            Billing
          </MenuItem>
          <Divider />
          {redesign && (
            <MenuItem component={Link} to="/components">
              MUI Components
            </MenuItem>
          )}
          <MenuItem component={Link} to="#" onClick={() => signOut()}>
            <ListItemIcon>
              <PowerSettingsNew fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

const mapStatetoProps = (state: PinataState) => {
  return {
    user: state.user,
    currentPlan: state.billing?.activePricingPlan
  };
};

export default connect(mapStatetoProps)(ProfileMenu);
