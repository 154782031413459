import PaymentMethod from './PaymentMethod';
import { BillingState } from '../../store/billing/types';
import { Dialog, DialogContent } from '@mui/material';
import { PinataDialogTitle } from '../../components/Common/MuiComponents';

interface AddCardModalProps {
  addCardModalOpen: boolean;
  setAddCardModalOpen: (open: boolean) => void;
  handleAddCard: (cardInfo: any) => void;
  billing: BillingState;
  setAlert: (message: string, type: string) => void;
}

const AddCardModal = ({
  addCardModalOpen,
  setAddCardModalOpen,
  handleAddCard,
  billing,
  setAlert
}: AddCardModalProps) => {
  return (
    <Dialog open={addCardModalOpen} onClose={() => setAddCardModalOpen(false)}>
      <PinataDialogTitle onClose={() => setAddCardModalOpen(false)}>Add Payment Card</PinataDialogTitle>
      <DialogContent sx={{ minWidth: '500px' }} dividers>
        <PaymentMethod handleAddCard={handleAddCard} setAlert={setAlert} />
      </DialogContent>
    </Dialog>
  );
};

export default AddCardModal;
