import { Grid, Typography } from '@mui/material';
import GatewayUsageData from './GatewayUsageData';
import { GatewayRow } from 'store/gateways/types';

type Props = {
  gatewaysList: Array<GatewayRow>
  days: number;
}

function GatewayUsage({gatewaysList, days}: Props) {

  return (
    <Grid>
      <Grid item>
        <Typography variant="h4" sx={{marginBottom: 2}}>Gateway Usage</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            {
              gatewaysList &&
              <GatewayUsageData 
                gatewaysList={gatewaysList}
                days={days}
                metric='bandwidth'
              />
            }
          </Grid>
          <Grid item md={6} xs={12}>
            {
              gatewaysList &&
              <GatewayUsageData 
                gatewaysList={gatewaysList}
                days={days}
                metric='requests'
              />
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GatewayUsage;
