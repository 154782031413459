import { useState } from 'react';
import { Box, Button, FormGroup, FormHelperText, Paper, TextField, Typography } from '@mui/material';
import { useAuth } from 'hooks/useAuth';
import { getErrorMessage } from '../../helpers/error.helper';
import { passwordFieldRules } from '../../helpers/formFieldValidations';
import { useForm, Controller, FormProvider } from 'react-hook-form';

interface AccountSecurityCardProps {
  setAlert: (message: string, type: string) => void;
}

const AccountSecurityCard = (props: AccountSecurityCardProps) => {
  const { setAlert } = props;
  const { changePassword } = useAuth();

  const { register, getFieldState, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      password: '',
      newPassword: ''
    }
  });

  const oldPasswordState = getFieldState('password', formState);
  const newPasswordState = getFieldState('newPassword', formState);

  const canSubmit = oldPasswordState?.invalid || newPasswordState?.invalid;

  const handleChangePassword = async ({ password, newPassword }: any) => {
    try {
      await changePassword(password, newPassword);
      setAlert('Password updated!', 'success');
      reset();
    } catch (error) {
      const message = getErrorMessage(error);
      setAlert(message, 'error');
    }
  };

  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Change Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit(handleChangePassword)}>
        <FormGroup>
          <>
            <TextField
              {...register('password')}
              autoComplete="current-password"
              type="password"
              label="Current password"
              sx={{ my: 1 }}
              helperText={oldPasswordState?.error?.message}
              error={oldPasswordState?.invalid}
              FormHelperTextProps={{
                error: oldPasswordState?.invalid,
                sx: {
                  fontSize: (theme) => theme.typography.pxToRem(12)
                }
              }}
            />
          </>
          <>
            <TextField
              {...register('newPassword', passwordFieldRules)}
              autoComplete="new-password"
              type="password"
              label="New password"
              sx={{ my: 1 }}
              error={newPasswordState?.invalid}
              helperText={newPasswordState?.error?.message}
              FormHelperTextProps={{
                error: newPasswordState?.invalid,
                sx: {
                  fontSize: (theme) => theme.typography.pxToRem(12)
                }
              }}
            />
          </>
        </FormGroup>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button type="submit" disabled={canSubmit}>
            Save
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default AccountSecurityCard;
