import PinnieLightLogo from '../../assets/images/pinnieLogo.svg';
import PinnieDarkLogo from '../../assets/images/pinnieLogoLight.svg';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

const AppLogo = () => {
  const theme = useTheme();
  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', pl: '1.125rem', height: theme.spacing(9) }}>
      <Link to="/">
        <img
          src={theme.palette.mode === 'light' ? PinnieLightLogo : PinnieDarkLogo}
          alt="Pinata Logo"
          style={{ width: '6rem' }}
        />
      </Link>
    </Box>
  );
};

export default AppLogo;
