import { Box, Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { prettySize } from 'helpers/pretty';
import countriesJson from '../Charts/MapChart/data/countryList.json';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { connect } from 'react-redux';
import { setAlert } from '../../../store/alert/alert.actions';


type Data = {
  cid?: string;
  country?: string;
  referrer?: string;
  user_agent?: string;
  bandwidth_in_bytes?: number;
  requests?: number;
};

type Props = {
  setAlert: (message: string, type: string) => void;
  data: Array<Data>;
  gatewayFilter: string;
  dataDescription: string;
  metric: string;
  copyColumn: boolean;
};

function DataTable({setAlert, data, gatewayFilter, dataDescription, metric, copyColumn}: Props) {
  const dataType: string = Object.keys(data[0])[0];
  const [expandContentButton, setExpandContentButton] = useState<Array<boolean>>([]);

  let expandContentArray: Array<boolean> = [];

  const iso2letterToFullCountryName = (iso2letter: string): string => {
    let fullCountryName: string | undefined = (countriesJson.countries.find((country: any) => country.code === iso2letter.toUpperCase())?.name);
    if (!fullCountryName) fullCountryName = '-';
    return fullCountryName;
  }

  const expandContentButtonHandler = (index: number, status: boolean) => {
    expandContentArray[index] = status;
    setExpandContentButton(expandContentArray);
  };

  const theme = useTheme();
  return (
    <Box>
      <TableContainer component={Paper} sx={{ borderRadius: '0%', boxShadow: 'none' }}>
        <Table sx={{ width: '100%' }} size="small" aria-label="data table">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>{dataDescription}</TableCell>
              {copyColumn && <TableCell>Copy</TableCell>}
              <TableCell align="right" sx={{textTransform: 'capitalize'}}>{metric}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: Data, index: number) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  {`${index+1}.`}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant='body2'>
                    {
                      row?.cid !== undefined && (
                        row.cid === '' ? (
                          <Link sx={{color: theme.palette.text.primary}}>Unknown</Link>
                        ) : (
                          <Link underline="hover" href={`https://${gatewayFilter}/ipfs/${row.cid}`} target="__blank" style={{color: theme.palette.text.primary }}>
                            {/* {`${row.cid.slice(0, 10)}...${row.cid.slice(-10, row.cid.length)}`} */}
                            {`${row.cid.slice(0, row.cid.length/2)}`}<br/>{`${row.cid.slice(row.cid.length/2, row.cid.length)}`}
                          </Link>  
                        )
                      )
                    }
                    {
                      row?.country !== undefined && (
                        row.country === '' ? (
                          <Link sx={{color: theme.palette.text.primary}}>Unknown</Link>
                        ) : (
                          iso2letterToFullCountryName(row?.country)
                        )
                      )
                    }
                    {
                      row?.referrer !== undefined && (
                        row.referrer === '' ? (
                          <Link sx={{color: theme.palette.text.primary}}>Direct</Link>
                        ) : (
                          <Link underline="hover" href={row?.referrer} target="__blank" style={{color: theme.palette.text.primary}}>
                            {`${row?.referrer.slice(0, 25)}${row?.referrer.length > 25 ? `...${row?.referrer.slice(-10, row?.referrer.length)}` : ''}`}
                          </Link>  
                        )
                      )
                    }
                  </Typography>
                  {
                    row?.user_agent !== undefined && (
                      row.user_agent === '' ? (
                        <Link sx={{color: theme.palette.text.primary}}>Unknown</Link>
                      ) : (
                        <Grid container justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'}>
                          <Grid item>
                            {expandContentButton[index] && row?.user_agent}
                            {!expandContentButton[index] && `${row?.user_agent.slice(0, 30)}...`}
                          </Grid>
                          <Grid item>
                            <IconButton aria-label="expand" size="small" onClick={
                              () => {expandContentButtonHandler(index, !expandContentButton[index])}
                            }>
                              {
                                expandContentButton[index] ? (
                                  <ExpandLessIcon />
                                  ) : (
                                  <ExpandMoreIcon />
                                )
                              }
                            </IconButton>
                          </Grid>
                        </Grid>
                      )
                    )
                  }
                </TableCell>
                {
                  copyColumn && (
                    <TableCell component="th" scope="row">
                      <IconButton id={`analytics-copy-link-${dataType}`} onClick={() => {
                        navigator.clipboard.writeText(`${Object.values(row)[0]}`);
                        setAlert('Copied in clipboard!', 'success');                        
                      }}>
                        <ContentCopyIcon />
                      </IconButton>
                    </TableCell>
                  )
                }
                <TableCell align="right">
                  {row?.bandwidth_in_bytes && prettySize(row?.bandwidth_in_bytes)}
                  {row?.requests && row?.requests}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default connect(null, {
  setAlert
})(DataTable);
