import { BillingState } from '../store/billing/types';
import { MetricsState } from '../store/metrics/types';
import { metricLimitInfo } from '../helpers/enums';
import * as FullStory from '@fullstory/browser';

interface UseAnalyticsProps {
  user: {
    user: any;
  };
  gateways: {
    gateways: {
      rows: any[];
      count: number;
    };
    bandwidth: number;
  };
  data: any;
  billing: BillingState;
  metrics: MetricsState;
  apiKeys: any;
}

export const useAnalytics = (props: UseAnalyticsProps) => {
  const getName = (type: 'firstName' | 'lastName') => {
    if (!!props?.user?.user) {
      return props.user.user[`custom:${type}`] ? props.user.user[`custom:${type}`] : '';
    } else {
      return '';
    }
  };

  const getGateways = () => {
    if (props?.gateways?.gateways?.rows?.length) {
      return props.gateways.gateways.rows?.map((r) => r.domain).join(', ');
    } else {
      return '';
    }
  };

  const getCustomDomains = () => {
    if (props?.gateways?.gateways?.rows?.length) {
      return props.gateways.gateways?.rows.map((r) => r.customDomains.map((d: any) => d.domain)).join(', ');
    } else {
      return '';
    }
  };

  const updateIntercom = () => {
    try {
      window.Intercom('update', {
        name: `${getName('firstName')} ${getName('lastName')}`,
        email: props.user?.user?.email ?? '',
        'Total Pins': props.metrics?.metrics?.metricsAccount?.fileCount?.value ?? 0,
        'Current Plan': props?.billing?.activePricingPlan?.nickname ?? '',
        'Current Plan Type': props?.billing?.activePricingPlan?.type ?? '',
        'Total Gateways': props.metrics?.metrics?.metricsAccount?.gatewayCount?.value ?? 0,
        'Gateway Names': getGateways(),
        'Gateway Custom Domains': getCustomDomains(),
        'Pinned Data': props.metrics?.metrics?.metricsMonthly?.transferBytes?.value ?? 0,
        'API Keys Count': props.apiKeys?.keys?.count ?? 0,
        'Current Month Bandwidth': props.gateways?.bandwidth ?? 0,
        'Metric Limit Type': props?.billing?.activePricingPlan?.isLegacy
          ? metricLimitInfo.PLAN_OK.title
          : props?.metrics?.metricsLimitData?.title ?? ''
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    updateIntercom
  };
};
