import { ReactElement, SyntheticEvent, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Button,
  Grid,
  Accordion as MuiAccordion,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  AccordionDetails as MuiAccordionDetails,
  useTheme,
  Tooltip,
  Box
} from '@mui/material';
import { ArrowForwardIosSharp, InfoOutlined } from '@mui/icons-material';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharp />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  minHeight: 135
}));

interface GatewaySettingsItemProps {
  itemTitle: string;
  label: string;
  actionTitle: string;
  children?: ReactElement;
  itemAction: (...args: any[]) => void;
  isActionDisabled?: boolean;
  additionalComponent?: ReactElement;
  tooltipText: string;
  actionTooltipText?: ReactElement | string;
}

export default function GatewaySettingsItem(props: GatewaySettingsItemProps) {
  const [expanded, setExpanded] = useState<boolean>(true);
  const { itemTitle, actionTitle, children, itemAction, label, isActionDisabled, additionalComponent } = props;

  const handleChange = (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  const handleActionClick = (label: string) => (e: SyntheticEvent) => {
    e.stopPropagation();
    itemAction(label);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography sx={{ m: 0, display: 'flex', alignItems: 'center' }} variant="h6">
            {itemTitle}
            <Tooltip sx={{ mx: 1 }} title={props.tooltipText}>
              <InfoOutlined />
            </Tooltip>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            {additionalComponent && <>{additionalComponent}</>}
            <Tooltip title={props.actionTooltipText}>
              <Button disabled={isActionDisabled} fullWidth color="primary" onClick={handleActionClick(label)}>
                {actionTitle}
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
