//Colors taken from Pinata Figma design https://www.figma.com/file/Uaq250YCGDCK5NzrASJn7k/Pinata-Design-System?node-id=2%3A161
import { createTheme, Theme, PaletteOptions } from '@mui/material';
import { green, purple, red, teal, yellow, neutral, textNeutral } from './colors';
import '@fontsource/mulish/300.css';
import '@fontsource/mulish/400.css';
import '@fontsource/mulish/500.css';
import '@fontsource/mulish/600.css';
import '@fontsource/mulish/900.css';
import '../assets/fonts/HelveticaNeue/HelveticaNeue.ttf';

const pinataPalette: PaletteOptions = {
  primary: {
    main: purple[400]
  },
  info: {
    main: teal[500]
  },
  warning: {
    main: yellow[300]
  },
  error: {
    main: red[200]
  },
  success: {
    main: green[200]
  },
  background: {
    default: textNeutral[0],
    paper: '#f0f0f0'
  }
};

const lightColorPalette: PaletteOptions = {
  ...pinataPalette,
  mode: 'light'
};

const darkColorPalette: PaletteOptions = {
  ...pinataPalette,
  mode: 'dark',
  primary: {
    main: purple[300]
  },
  background: {
    default: '#212121',
    paper: '#212121'
  }
};

const typography = {
  typography: {
    h1: {
      fontFamily: `"Mulish", sans-serif`,
      fontSize: '3.75rem',
      fontWeight: 900,
      lineHeight: 1.133
    },
    h2: {
      fontFamily: `"Mulish", sans-serif`,
      fontSize: '2.625rem',
      fontWeight: 900,
      lineHeight: 1.047
    },
    h3: {
      fontFamily: `"Mulish", sans-serif`,
      fontSize: '2.125rem',
      fontWeight: 900,
      lineHeight: 1.058
    },
    h4: {
      fontFamily: `"Mulish", sans-serif`,
      fontSize: '1.75rem',
      fontWeight: 600,
      lineHeight: 1.142
    },
    h5: {
      fontFamily: `"Mulish", sans-serif`,
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.333
    },
    h6: {
      fontFamily: `"Mulish", sans-serif`,
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.4
    },
    subtitle1: {
      fontFamily: `"Helvetica Neue", sans-serif`,
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: 1.333
    },
    subtitle2: {
      fontFamily: `"Helvetica Neue", sans-serif`,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body1: {
      fontFamily: `"Helvetica Neue", sans-serif`,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontFamily: `"Helvetica Neue", sans-serif`,
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.428
    },
    caption: {
      fontFamily: `"Helvetica Neue", sans-serif`,
      fontSize: '0.875rem',
      fontWeight: 300,
      lineHeight: 1.428
    },
    button: {
      fontFamily: `"Helvetica Neue", sans-serif`,
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 1.428
    },
    overline: {
      fontFamily: `"Helvetica Neue", sans-serif`,
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.428
    }
  }
};

const alertCommon: any = {
  root: {
    borderRadius: '8px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.04)',
    padding: '8px 10px',
    maxWidth: 600,
    minWidth: 300,
    margin: '8px 0',
    zIndex: 1,
    whiteSpace: 'break-spaces'
  },
  message: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
};

const createModeTheme = (variant: 'light' | 'dark', baseTheme: Theme) => {
  return createTheme(baseTheme, {
    shape: {
      borderRadius: 32
    },
    typography: {
      ...typography.typography
    },
    components: {
      ...baseTheme.components,
      MuiContainer: {
        styleOverrides: {
          root: ({ theme }: any) => {
            return {
              padding: theme.spacing(0, 5.5),
              [theme.breakpoints.down('md')]: {
                padding: theme.spacing(0, 5.5 / 2)
              }
            };
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.mode === 'light' ? baseTheme.palette.primary.main : neutral[900]
          }
        }
      },
      MuiAlert: {
        defaultProps: {
          variant: 'outlined'
        },
        styleOverrides: {
          ...alertCommon,
          root: {
            ...alertCommon.root,
            color: baseTheme.palette.getContrastText(neutral[100]) // always darker for both themes
          },
          message: {
            ...alertCommon.message
          },
          filledSuccess: {
            border: `1px solid ${baseTheme.palette.success.dark}`,
            backgroundColor: green[100]
          },
          filledError: {
            border: `1px solid ${baseTheme.palette.error.dark}`,
            backgroundColor: red[100]
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          size: 'small'
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            height: 0,
            padding: 0
          },
        }        
      },
      MuiDataGrid: {
        styleOverrides: {
          footerContainer: {
            justifyContent: 'flex-start'
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained'
          // size: 'small'
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: '100px',
            fontSize: baseTheme.typography.pxToRem(14),
            fontWeight: 500,
            '&.MuiButton-containedPrimary': {
              '&.Mui-focusVisible': {
                border: `4px solid ${purple[100]}`
              },
              '&.Mui-disabled': {
                color: textNeutral[0],
                backgroundColor: purple[100]
              },
              '&:active': {
                backgroundColor: purple[400]
              },
              '&:hover': {
                backgroundColor: purple[300]
              }
            },
            '&.MuiButton-containedSecondary': {
              '&.Mui-focusVisible': {
                border: `4px solid ${purple[100]}`
              },
              '&.Mui-disabled': {
                backgroundColor: textNeutral[50],
                color: neutral[100]
              },
              '&:active': {
                backgroundColor: neutral[200]
              },
              '&:hover': {
                backgroundColor: neutral[100]
              }
            }
          },
          sizeLarge: {
            padding: '12px 20px'
          },
          sizeMedium: {
            padding: '10px 16px'
          },
          sizeSmall: {
            padding: '8px 12px',
            fontSize: '0.875rem',
            height: '40px'
          }
        },
        variants: [
          {
            props: { variant: 'text' },
            style: {
              padding: 0,
              borderRadius: 0,
              '& :hover': {
                backgroundColor: 'transparent'
              },
              height: 'auto'
            }
          },
          {
            props: { variant: 'link' },
            style: {
              color: '#969696',
              textDecoration: 'underline',
              fontSize: '14px',
              backgroundColor: 'transparent',
              padding: 0,
              fontWeight: 400,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }
            }
          }
        ]
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            textDecoration: 'none'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: variant === 'light' ? '0px 2px 7px 1px rgb(226 224 226)' : '0px 2px 7px 1px rgb(86 84 86)',
            borderRadius: '16px'
          }
        }
      },
      MuiTypography: {
        MuiSelect: {
          styleOverrides: {
            root: {
              '& legend': {
                display: 'none'
              },
              maxWidth: '387px'
            }
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                '& legend': {
                  display: 'none'
                },
                maxWidth: '387px'
              }
            }
          },
          MuiSelect: {
            styleOverrides: {
              root: {
                '& legend': {
                  display: 'none'
                },
                maxWidth: '387px'
              }
            }
          }
        }
      }
    }
  });
};

const lightBaseTheme = createTheme({
  palette: lightColorPalette
});

const darkBaseTheme = createTheme({
  palette: darkColorPalette
});

const dark = createModeTheme('dark', darkBaseTheme);
const light = createModeTheme('light', lightBaseTheme);

export const Themes = {
  light,
  dark
};
