import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import TableNav from './TableNav';
import PinTable from './PinTable';
import FileUploadModal from './FileUploadModal';
import FolderUploadModal from './FolderUploadModal';
import PinByHashModal from './PinByHashModal';

import { setAlert } from '../../store/alert/alert.actions';
import { pinJobs, userPinList, userPinnedDataTotal } from '../../store/data/data.actions';
import { regionPermissions } from '../../actions/pin.actions';
import { getAllGateways } from 'store/gateways/gateway.actions';

import { ROOT_DOMAIN } from '../../pages/Gateway/GatewayWizard/gatewayConstants';
import type { PinataState } from 'store/types';

type PinStatuses = 'pinned' | 'unpinned' | 'all';
const DEFAULT_GATEWAY = 'gateway.pinata.cloud';
const PinManager = ({
  gateways,
  getAllGateways,
  userPinList,
  data,
  userPinnedDataTotal,
  regionPermissions,
  billing,
  pinJobs,
  setAlert,
  metrics,
  user
}: any) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploadOpen, setFileUploadModalOpen] = useState<boolean>(false);
  const [folderUploadOpen, setFolderUploadModalOpen] = useState<boolean>(false);
  const [pinByHashOpen, setPinByHashOpen] = useState<boolean>(false);
  const [loadingDone, setLoadingDone] = useState<boolean>(false);
  const [submarined, setSubmarined] = useState<boolean>(false);
  const [fileCount, setFileCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPins, setTotalPins] = useState<number>(0);
  const [hashContains, setHashContains] = useState<string>('');
  const [selectedPinStatus, setSelectedPinStatus] = useState<PinStatuses>('pinned');
  const [nameContains, setNameContains] = useState<string>('');
  const [gatewayToUse, setGatewayToUse] = useState<string>(DEFAULT_GATEWAY);
  const [pinStartDate, setPinStartDate] = useState<Date | undefined>(undefined);
  const [pinEndDate, setPinEndDate] = useState<Date | undefined>(undefined);
  const [unpinStartDate, setUnpinStartDate] = useState<Date | undefined>(undefined);
  const [unpinEndDate, setUnpinEndDate] = useState<Date | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState<string>('public');

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  useEffect(() => {
    setLoadingDone(true);
    userPinnedDataTotal();
    regionPermissions();
    pinJobs(100, 0);
    getAllGateways(1);
  }, []);

  useEffect(() => {
    submitTableFilters();
  }, [currentPage, selectedPinStatus]);

  useEffect(() => {
    clearFilters();
  }, [currentTab]);

  useEffect(() => {
    if (data.userPinList && data.userPinList.rows) {
      setFileCount(data.userPinList.count);
      setFiles(data.userPinList.rows);
    }
    if (data.userPinnedDataTotals && data.userPinnedDataTotals.pin_count) {
      setTotalPins(data.userPinnedDataTotals.pin_count);
    }
  }, [data]);

  useEffect(() => {
    if (gateways?.gateways?.rows?.length > 0 && gatewayToUse === DEFAULT_GATEWAY) {
      setGatewayToUse(getGatewayValue(gateways.gateways.rows[0]));
    }
  }, [gateways?.gateways?.rows]);

  const getGatewayValue = (gateway: any) => {
    if (gateway.customDomains.length > 0) {
      return `${gateway.customDomains[0].domain}`;
    }
    return gateway.domain + ROOT_DOMAIN;
  };

  const getPageOffset = (data: any, rowsPerPage: number, currentPage: number) => {
    const maxOffset = (currentPage + 1) * rowsPerPage;
    const offsetValue = currentPage <= maxOffset ? currentPage : maxOffset;
    return offsetValue * rowsPerPage;
  };

  const submitTableFilters = useCallback(async () => {
    const queryParams = {
      hashContains: hashContains || null,
      cidContains: hashContains || null,
      pinStart: pinStartDate ? new Date(pinStartDate).toISOString() : null,
      pinEnd: pinEndDate ? new Date(pinEndDate).toISOString() : null,
      createdAtStart: pinStartDate ? new Date(pinStartDate).toISOString() : null,
      createdAtEnd: pinEndDate ? new Date(pinEndDate).toISOString() : null,
      unpinStart: unpinStartDate && selectedPinStatus !== 'pinned' ? new Date(unpinStartDate).toISOString() : null,
      unpinEnd: unpinEndDate && selectedPinStatus !== 'pinned' ? new Date(unpinEndDate).toISOString() : null,
      status: selectedPinStatus || null,
      ['metadata[name]']: nameContains || null,
      pageLimit: rowsPerPage,
      pageOffset: getPageOffset(data, rowsPerPage, currentPage),
      includeCount: 'false',
    };
    setLoadingDone(false);
    await userPinList(queryParams, data?.folderDetails, currentTab);
    setLoadingDone(true);
  }, [
    hashContains,
    pinStartDate,
    pinEndDate,
    unpinStartDate,
    unpinEndDate,
    selectedPinStatus,
    currentPage,
    currentTab,
    nameContains,
    submarined,
    rowsPerPage
  ]);

  const clearFilters = useCallback(async () => {
    setSelectedPinStatus('pinned');
    setCurrentPage(0);
    setNameContains('');
    setHashContains('');
    setPinEndDate(undefined);
    setPinStartDate(undefined);
    setUnpinEndDate(undefined);
    setUnpinStartDate(undefined);
    setLoadingDone(false);
    await userPinList({ status: 'pinned', pageLimit: rowsPerPage, pageOffset: 0, includeCount: 'false' },undefined, currentTab);
    setLoadingDone(true);
  }, [currentTab]);

  const handleGatewayToUse = (event: any) => {
    setGatewayToUse(event.target.value);
  };

  return (
    <>
      <TableNav
        folderDetails={data?.folderDetails}
        billing={billing}
        getGatewayValue={getGatewayValue}
        gateways={gateways}
        handleGatewayToUse={handleGatewayToUse}
        gatewayToUse={gatewayToUse}
        pinJobs={data.pinJobList}
        hashContains={hashContains}
        setHashContains={setHashContains}
        nameContains={nameContains}
        setNameContains={setNameContains}
        pinStartDate={pinStartDate}
        pinEndDate={pinEndDate}
        unpinStartDate={unpinStartDate}
        unpinEndDate={unpinEndDate}
        setUnpinStartDate={setUnpinStartDate}
        setUnpinEndDate={setUnpinEndDate}
        setPinStartDate={setPinStartDate}
        setPinEndDate={setPinEndDate}
        selectedPinStatus={selectedPinStatus}
        setSelectedPinStatus={setSelectedPinStatus}
        submitTableFilters={submitTableFilters}
        setFileUploadModalOpen={setFileUploadModalOpen}
        setFolderUploadModalOpen={setFolderUploadModalOpen}
        clearFilters={clearFilters}
        setPinByHashOpen={setPinByHashOpen}
        setAlert={setAlert}
        submarined={submarined}
        setSubmarined={setSubmarined}
        metrics={metrics}
        setCurrentPage={setCurrentPage}
      />
      <PinTable
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        user={user}
        gatewayToUse={gatewayToUse}
        submitTableFilters={submitTableFilters}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        currentPage={currentPage}
        selectedPinStatus={selectedPinStatus}
        setCurrentPage={setCurrentPage}
        files={files}
        fileCount={fileCount}
        folderDetails={data.folderDetails}
        billing={billing}
        metrics={metrics}
        gateways={gateways}
        loadingDone={loadingDone}
        setSubmarined={setSubmarined}
      />
      {fileUploadOpen && (
        <FileUploadModal
          fileUploadOpen={fileUploadOpen}
          setFileUploadModalOpen={setFileUploadModalOpen}
          currentPlan={billing?.activePricingPlan}
        />
      )}
      {folderUploadOpen && (
        <FolderUploadModal folderUploadOpen={folderUploadOpen} setFolderUploadModalOpen={setFolderUploadModalOpen} />
      )}
      {pinByHashOpen && <PinByHashModal pinByHashOpen={pinByHashOpen} setPinByHashOpen={setPinByHashOpen} />}
    </>
  );
};

const mapStateToProps = (state: PinataState) => ({
  data: state?.data,
  billing: state?.billing,
  gateways: state?.gateways,
  metrics: state?.metrics,
  user: state?.user?.user
});

const mapDispatchToProps = {
  userPinList,
  getAllGateways,
  userPinnedDataTotal,
  regionPermissions,
  pinJobs,
  setAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(PinManager);
