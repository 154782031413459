import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  createStripePaymentSource,
  detachStripeSourceFromCustomer,
  setDefaultCard
} from '../../store/billing/billing.actions';
import { setAlert } from '../../store/alert/alert.actions';

import AddCardModal from './AddCardModal';
import BillingAddressModal from './BillingAddressModal';
import RemoveCardModal from './RemoveCardModal';
import {
  CardContent,
  Card,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Button,
  Box,
  useTheme,
  ListItemIcon,
  Paper
} from '@mui/material';
import { GatewaysState } from '../../store/gateways/types';
import { BillingState, PaymentMethod } from '../../store/billing/types';
import { PinataState } from '../../store/types';
import { Delete } from '@mui/icons-material';

const CARDS_ALLOWED = 5;

interface PaymentInfoProps {
  billing: BillingState;
  createStripePaymentSource: any;
  detachStripeSourceFromCustomer: any;
  setDefaultCard: any;
  setAlert: (message: string, type: string) => void;
  gateways: GatewaysState;
  openCardModal: any;
  setOpenCardModal: any;
}

const RenderPaymentMethods = (method: any, handleOpenCardRemovalWindow: any) => {
  const theme = useTheme();
  return (
    <TableRow key={method.id}>
      <TableCell>{method.brand}</TableCell>
      <TableCell>{method.last4}</TableCell>
      <TableCell>{`${method.exp_month}/${method.exp_year}`}</TableCell>
      <TableCell>
        {method.isActive ? (
          <Typography color={theme.palette.success.main}>Default</Typography>
        ) : (
          <button onClick={() => setDefaultCard(method.customer, method.id)} className="pinata-link-button p-0">
            Make Default
          </button>
        )}
      </TableCell>
      <TableCell>
        {!method.isActive && (
          <button onClick={() => handleOpenCardRemovalWindow(method)} className="pinata-link-button">
            <Delete color={'error'} />
          </button>
        )}
      </TableCell>
    </TableRow>
  );
};

function PaymentInfo(props: PaymentInfoProps) {
  const [removeCardModalOpen, setRemoveCardModalOpen] = useState(false);
  const [cardInfo, setCardInfo] = useState<PaymentMethod | null>(null);
  const [openBillingAddressModal, setOpenBillingAddressModal] = useState(false);
  const {
    billing,
    gateways,
    createStripePaymentSource,
    detachStripeSourceFromCustomer,
    setAlert,
    setDefaultCard,
    openCardModal,
    setOpenCardModal
  } = props;
  const paymentMethods = billing?.stripe_customer?.paymentMethods;

  useEffect(() => {}, [openBillingAddressModal]);

  const confirmRemoveCard = async (card: { id: any }) => {
    setAlert('Removing card...', 'info');
    setRemoveCardModalOpen(false);
    setCardInfo(null);
    await detachStripeSourceFromCustomer(card.id);
  };

  const renderPaymentMethods = (method: any) => {
    const theme = useTheme();
    return (
      <TableRow key={method.id}>
        <TableCell>{method.brand}</TableCell>
        <TableCell>{method.last4}</TableCell>
        <TableCell>{`${method.exp_month}/${method.exp_year}`}</TableCell>
        <TableCell>
          {method.isActive ? (
            <Typography color={theme.palette.success.main}>Default</Typography>
          ) : (
            <Button variant="link" onClick={() => setDefaultCard(method.customer, method.id)}>
              Make Default
            </Button>
          )}
        </TableCell>
        <TableCell>
          {!method.isActive && (
            <Button variant="text" onClick={() => handleOpenCardRemovalWindow(method)}>
              <Delete color={'error'} />
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const handleAddCard = async (tokenId: string) => {
    setOpenCardModal(false);
    try {
      setAlert('Adding card...', 'info');
      await createStripePaymentSource(tokenId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenCardRemovalWindow = (paymentMethod: any) => {
    const hasGateways = gateways?.gateways?.rows?.length;
    if (hasGateways && paymentMethods.length === 1) {
      return setAlert('You cannot remove your card when you have active dedicated gateways', 'error');
    }

    setRemoveCardModalOpen(true);
    setCardInfo(paymentMethod);
  };

  const handleNewCardModal = () => {
    if (paymentMethods.length < CARDS_ALLOWED) {
      setOpenCardModal(true);
    } else {
      setAlert('You can only add 5 cards', 'error');
    }
  };

  return (
    <>
      <Paper sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Payment Info</Typography>
          <Button onClick={handleNewCardModal} size="small">
            Add Card
          </Button>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell scope="col">Brand</TableCell>
              <TableCell scope="col">Last 4</TableCell>
              <TableCell scope="col">Expiration</TableCell>
              <TableCell scope="col">Default</TableCell>
              <TableCell scope="col"></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{paymentMethods.map((pm) => renderPaymentMethods(pm))}</TableBody>
        </Table>
      </Paper>
      {openCardModal && (
        <AddCardModal
          setAddCardModalOpen={setOpenCardModal}
          addCardModalOpen={openCardModal}
          handleAddCard={handleAddCard}
          billing={billing}
          setAlert={setAlert}
        />
      )}
      {openBillingAddressModal && (
        <BillingAddressModal setModalOpen={setOpenBillingAddressModal} modalIsOpen={openBillingAddressModal} />
      )}
      {removeCardModalOpen && (
        <RemoveCardModal
          removeCardModalOpen={removeCardModalOpen}
          setRemoveCardModalOpen={setRemoveCardModalOpen}
          cardInfo={cardInfo}
          confirmRemoveCard={confirmRemoveCard}
        />
      )}
    </>
  );
}

const mapStateToProps = (state: PinataState) => ({
  billing: state?.billing,
  gateways: state?.gateways
});

const mapDispatchToProps = {
  setDefaultCard,
  setAlert,
  createStripePaymentSource,
  detachStripeSourceFromCustomer
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
