import {useQuery} from "@tanstack/react-query";
import { managedApi } from "services/api";

const QUERY_KEYS = ['gateways'];

export const useGetGateways = () => {
  const {
    isLoading: isLoadingGetGateways,
    error: errorGetGateways,
    data: dataGetGateways 
  } = useQuery ({
    queryKey: QUERY_KEYS,
    queryFn: () =>
    managedApi.get(`gateways`)
  });
  return {isLoadingGetGateways, errorGetGateways, dataGetGateways};
};
