import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { setAlert } from '../../../store/alert/alert.actions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

dayjs.extend(utc);

const daysRange = 90;

type Props = {
  setAlert: (message: any, type: any, time?: number | undefined, close?: any) => void;
  setDateStartFilter: (data: any) => {};
  setDateEndFilter: (data: any) => {};
  dateStartFilter: Date;
  dateEndFilter: Date;
}

const RangeDatePicker = ({setAlert, setDateStartFilter, setDateEndFilter, dateStartFilter, dateEndFilter}: Props) => {
  const errorHandler = () => {
    setAlert(`'Date From' must be earlier than 'Date To'`, 'error');    
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={1} direction='row' alignItems='center'>
        <Grid item>
          <DatePicker
            components={{
              OpenPickerIcon: ArrowDropDownIcon
            }}
            label="From"
            minDate={dayjs().subtract(daysRange, 'day')}
            disableFuture            
            value={dayjs.utc(dateStartFilter).startOf('day')}
            onChange={(newValue) => {
              if(newValue === null) newValue = dayjs(Date());
              if(newValue <= dayjs(dateEndFilter)) {
                setDateStartFilter(newValue);
              } else {
                errorHandler();
              }
            }}
          />
        </Grid>          
        <Grid item>
          <span>-</span>
        </Grid>
        <Grid item>
          <DatePicker
            components={{
              OpenPickerIcon: ArrowDropDownIcon
            }}
            label="To"
            minDate={dayjs().subtract(daysRange, 'day')}            
            disableFuture
            value={dayjs.utc(dateEndFilter).endOf('day')}
            onChange={(newValue) => {
              if(newValue === null) newValue = dayjs(Date());
              if(dayjs(dateStartFilter) <= newValue) {              
              setDateEndFilter(newValue);
              } else {
                errorHandler();
              }
            }}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default connect(null, {
  setAlert,
})(RangeDatePicker);
