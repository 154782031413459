import { Box } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

type Props = {
  value: number;
}

const scale = {
  mid: 50,
  max: 80
};

function BarChart({value}: Props) {

  const getColor = (theme: any) => {
    let color = theme.palette.success.main;
    switch (true) {
      case (value > scale.max):
        color = theme.palette.error.main;
        break;
      case (value > scale.mid):
        color = theme.palette.warning.main;
        break;
      default:
        break;
      }
    return color;
  }

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 26,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: getColor(theme)
    },
  }));
  
  return (
    <Box sx={{width: '100%'}}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  )
}

export default BarChart;
