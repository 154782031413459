import { alpha } from '@mui/material';

interface Color {
  [key: number | string]: string;
}

const COLOR_SHAPES = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

function generateColorPalette(colorsArray: string[]): Color {
  return colorsArray.reduce((acc: {}, nextEl: string, index: number) => {
    return (acc = {
      ...acc,
      [COLOR_SHAPES[index]]: nextEl
    });
  }, {});
}

const colors = {
  purple: [
    '#F1E8FF',
    '#E3D1FF',
    '#C6A4FF',
    '#AA76FF',
    '#953BED',
    '#8000DB',
    '#6C00BA',
    '#580098',
    '#3B0065',
    '#1D0033'
  ],
  teal: ['#E9FCFF', '#D4F9FF', '#A8F3FF', '#7DEDFF', '#3FD6EE', '#00BEDD', '#00A0BB', '#008298', '#005765', '#002B33'],
  pink: ['#FFE8F6', '#FFD0ED', '#FFA2DC', '#FF73CA', '#FF3AB4', '#FF009E', '#CC007E', '#98005E', '#65003F', '#33001F'],
  yellow: [
    '#FFFBEB',
    '#FFF8DE',
    '#FFF0BE',
    '#FFE99D',
    '#FFE669',
    '#FFE001',
    '#FFBD01',
    '#FF9900',
    '#AA6600',
    '#553300'
  ],
  neutral: ['#FAFAFA','#E1E1E1', '#C8C8C8', '#AFAFAF', '#969696', '#7C7C7C', '#636363', '#4A4A4A', '#313131', '#181818'],
  green: ['#CCF1DF', '#56DB99', '#05542D'],
  red: ['#F8BDBD', '#E24243', '#5C0707']
};
export const purple: Color = generateColorPalette(colors['purple']);
export const teal: Color = generateColorPalette(colors['teal']);
export const pink: Color = generateColorPalette(colors['pink']);
export const yellow: Color = generateColorPalette(colors['yellow']);
export const neutral = {
  50: colors.neutral[0],
  100: colors.neutral[1],
  200: colors.neutral[2],
  300: colors.neutral[3],
  400: colors.neutral[4],
  500: colors.neutral[5],
  600: colors.neutral[6],
  700: colors.neutral[7],
  800: colors.neutral[8],
  900: colors.neutral[9]
};

export const textNeutral = {
  0: '#FFFFFF',
  50: '#FAFAFA',
  999: '#000000'
};
export const green = {
  100: colors.green[0],
  200: colors.green[1],
  300: colors.green[2]
};
export const red = {
  100: colors.red[0],
  200: colors.red[1],
  300: colors.red[2]
};

//TODO: Do this the right way with extending classes or styled components extension
export const getBorderedBoxStyles = (theme: any) => ({
  backgroundColor: theme.palette.mode === 'light' ? neutral[50] : alpha(neutral[800], 0.5),
  border: `1px solid ${theme.palette.mode === 'light' ? neutral[200] : neutral[800]}`,
  color: theme.palette.text.primary,
});
