import {useRef, useEffect, useState} from 'react';
import {UploadFileRounded, CreateNewFolder, FileUpload} from '@mui/icons-material';
import {Box, Button, Typography, useTheme} from "@mui/material";
import {neutral} from "../../../themes/colors";


const DragDrop = (props: {handleChange: any, children: any, multiple?: boolean}) => {
  const theme = useTheme();
  const [fileDragged, setFileDragged] = useState(false);

  const dragOverHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setFileDragged(true);
  }

  const dragExitHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setFileDragged(false);
  }

  async function fromFileEntry(entry: any) {
    return new Promise((resolve, reject) => {
      entry.file((file: any) => {
        resolve(file);
      }, (err: any) => {
        reject(err);
      });
    });
  }

  const dropHandler = async (e: any) => {
    e.preventDefault();
    const item: any = await fromFileEntry(e.dataTransfer.items[0].webkitGetAsEntry());
    props.handleChange(item);
  }

  return <Box
    sx={{
      border: `1px dashed ${theme.palette.text.primary}`,
      padding: '1rem',
      backgroundColor: fileDragged ? theme.palette.mode === 'light' ? neutral[200] : neutral[500] : 'transparent',
      textAlign: 'center'
    }}
    onDragOver={dragOverHandler}
    onDrop={dropHandler}
    onDragLeave={dragExitHandler}
  >
    {props.children}
  </Box>
}

const UploadForm = ({ uploadType, handleFileInput, selectedFiles }: any) => {
  const fileInput: any = useRef(null);

  const handleChange = (files: any) => {
    handleFileInput(null, [files]);
  }

  if (uploadType === 'file') {
    return (
      <Box>
          <DragDrop handleChange={handleChange}>
            <UploadFileRounded sx={{fontSize: 45, mb: 1}}/>
            <Typography variant={'h4'}>Drag and drop your file</Typography>
          </DragDrop>
        <Box sx={{textAlign: 'center'}}>
          <Typography>Or select a file to upload</Typography>
          <input ref={fileInput} style={{ display: 'none' }} type="file" onChange={handleFileInput} />
          <div>
            <Button onClick={(e) => fileInput.current && fileInput.current.click()} color={"info"}>
              {selectedFiles.length > 0 ? 'Select another file' : 'Select a file'}
            </Button>
            <p style={{ marginTop: 10, textDecoration: 'underline' }}>
              {selectedFiles.length > 0 && selectedFiles[0].name}
            </p>
          </div>
        </Box>
      </Box>
    );
  } else {
    useEffect(() => {
      if (fileInput.current !== null) {
        fileInput.current.setAttribute('directory', '');
        fileInput.current.setAttribute('webkitdirectory', '');
      }
    }, [fileInput]);

    return (
      <Box sx={{textAlign: 'center'}}>
        <CreateNewFolder sx={{fontSize: 45, mb: 1}}/>
        <h3>Select a folder to upload</h3>
        <input style={{ display: 'none' }} ref={fileInput} type="file" onChange={handleFileInput} />
        <Button onClick={(e) => fileInput.current && fileInput.current.click()} color={"info"}>
          Select
        </Button>
      </Box>
    );
  }
};

export default UploadForm;
