import { Dialog, DialogContent, Typography, Button, Box } from '@mui/material';
import { PinataDialogTitle } from 'components/Common/MuiComponents';

interface RemovePinModalProps {
  hash: string | Array<any>;
  removePinModalOpen: boolean;
  setRemovePinModalOpen: (isOpen: boolean) => void;
  handleRemovePin: () => void;
}

const RemovePinModal = ({ hash, removePinModalOpen, setRemovePinModalOpen, handleRemovePin }: RemovePinModalProps) => {
  const isMultiple = Array.isArray(hash);
  return (
    <Dialog open={removePinModalOpen} onClose={() => setRemovePinModalOpen(false)} fullWidth sx={{ p: 2 }}>
      <PinataDialogTitle onClose={() => setRemovePinModalOpen(false)}>Delete File</PinataDialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center' }}>
          {
            isMultiple ? (
              <>
                <Typography variant="h5">{`Are you sure you want to remove ${hash.length} file${hash.length > 1 ? 's' : ''}?`}</Typography>
              </>
            ) : (
              <>
                <Typography variant="h5">Are you sure you want to remove this file?</Typography>
                <Typography component="code">
                  {hash}
                </Typography>    
              </>

            )
          }
          <Typography component="p">It cannot be undone.</Typography>
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button fullWidth onClick={handleRemovePin} color="error">
            Delete
          </Button>
          <Button fullWidth onClick={() => setRemovePinModalOpen(false)} variant="text">
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RemovePinModal;
