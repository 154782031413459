import { Dialog, DialogContent, Button, Box, Typography, List, ListItem } from '@mui/material';
import { PinataDialogTitle } from 'components/Common/MuiComponents';

interface MakePublicModalProps {
  hash: any;
  makePublicModalOpen: boolean;
  setMakePublicModalOpen: (isOpen: boolean) => void;
  handleMakePublic: () => void;
}

const MakePublicModal = ({
  hash,
  makePublicModalOpen,
  setMakePublicModalOpen,
  handleMakePublic
}: MakePublicModalProps) => {
  return (
    <Dialog open={makePublicModalOpen} onClose={() => setMakePublicModalOpen(false)} fullWidth sx={{ p: 2 }}>
      <PinataDialogTitle onClose={() => setMakePublicModalOpen(false)}>Make Public</PinataDialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6">
            Are you sure you want to make this content public?
          </Typography>
          <Typography sx={{ fontSize: '0.8rem' }} component="code">
            CID: {hash}
          </Typography>
          <Typography component="p" variant="body2">
            You will not be able to make it private again once it is on the public IPFS network.
          </Typography>
          <Typography component="p" variant="body2">
            How does this work?
          </Typography>
          <List sx={{ my: 2 }}>
            <ListItem>Your content is added to a queue</ListItem>
            <ListItem>When it's your file's turn, it will be added to the public IPFS network</ListItem>
            <ListItem>The pin manager will show you that your file is no longer private</ListItem>
            <ListItem>This process can take some time, so the status of your file will not update immediately</ListItem>
          </List>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button fullWidth sx={{ mr: 1 }} onClick={handleMakePublic} color="primary">
            Make it public
          </Button>
          <Button fullWidth sx={{ ml: 1 }} onClick={() => setMakePublicModalOpen(false)} color="error">
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MakePublicModal;
