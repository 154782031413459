import { useState, useEffect, useRef } from 'react';
import { Box, Button, Card, CardContent, Paper, TextareaAutosize, TextField, Typography } from '@mui/material';
import ConfirmationModal from 'pages/Gateway/ConfirmationModal';
import { deleteUserPreview, getUserPreview, setUserPreview } from '../../store/user/user.actions';
import { getErrorMessage } from '../../helpers/error.helper';
import { MAX_THUMBNAIL_SIZE } from '../../common/constants';

const S3PreviewBucketURL = process.env.REACT_APP_S3_PREVIEW;

interface AccountSettingsCardProps {
  setAlert: (message: string, type: string) => void;
}

interface CurrentData {
  title: string;
  description: string;
  preview_url: string;
}

const AccountSettingsCard = ({ setAlert }: AccountSettingsCardProps) => {
  const [editing, setEditing] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [currentData, setCurrentData] = useState<CurrentData | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const fileInput = useRef<any>(null);

  const fetchUserSettings = async () => {
    const userSettingsRes = await getUserPreview();
    setCurrentData(userSettingsRes.data);
  };

  const handleSave = () => {
    setSaving(true);
    if (!thumbnail || !newTitle || !newDescription) {
      setAlert('All fields are required', 'error');
      setSaving(false);
      return;
    }
    setUserPreview({ file: thumbnail[0], title: newTitle, description: newDescription })
      .then((res) => {
        setAlert('Success!', 'success');
        fetchUserSettings();
      })
      .catch((e) => {
        setAlert(getErrorMessage(e), 'error');
        console.error(e);
      })
      .finally(() => {
        setSaving(false);
        setEditing(false);
      });
  };

  const deleteSettings = () => {
    setSaving(true);
    deleteUserPreview()
      .then((res) => {
        setCurrentData(null);
      })
      .catch((e) => {
        setAlert(getErrorMessage(e), 'error');
        console.error(e);
      })
      .finally(() => {
        setSaving(false);
        setEditing(false);
        setModalOpen(false);
      });
  };

  const handleFileInput = async (e: any) => {
    setUploading(true);
    const files = e.target.files;
    if (files && files[0].size < MAX_THUMBNAIL_SIZE) {
      setThumbnail(files);
      setUploading(false);
    } else {
      setAlert('File too big', 'error');
      setUploading(false);
    }
  };

  useEffect(() => {
    fetchUserSettings().catch((e) => console.error(e));
  }, []);

  return (
    <>
      <Paper sx={{ p: 4, flexGrow: 1 }}>
        <div>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Account Preview Settings
          </Typography>
          {currentData && <Typography variant="h6">Title</Typography>}
          {editing ? (
            <TextField
              fullWidth
              sx={{ mb: 1 }}
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              type="text"
              label="Title"
              id="formrow-firstname-Input"
            />
          ) : (
            <Typography variant="body2">{currentData?.title}</Typography>
          )}
          {currentData && <Typography variant="h6">Description</Typography>}
          {editing ? (
            <TextareaAutosize
              id="textarea"
              minRows={6}
              value={newDescription}
              placeholder="This textarea has a limit of 225 chars."
              className="position-relative"
              onChange={(e) => setNewDescription(e.target.value)}
              style={{ width: '100%' }}
            />
          ) : (
            <Typography variant="body2">{currentData?.description}</Typography>
          )}
          {currentData && <Typography variant="h6">Thumbnail</Typography>}
          {editing ? (
            <Box sx={{mb: '1rem'}}>
              <input
                accept="image/*"
                id="thumbnail"
                ref={fileInput}
                style={{ display: 'none' }}
                type="file"
                onChange={handleFileInput}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={(e) => fileInput.current && fileInput.current?.click()} color={'info'} size="small">
                  {uploading ? 'Uploading...' : 'Upload a file'}
                </Button>

                <Typography variant={'caption'} sx={{ ml: 1 }}>
                  {thumbnail?.length > 0 && thumbnail[0].name}
                </Typography>
              </Box>
            </Box>
          ) : (
            <img src={(S3PreviewBucketURL || '') + currentData?.preview_url} alt="" height={80} />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            {editing ? (
              <Button onClick={() => handleSave()} color="primary">
                {saving ? 'Saving...' : 'Save'}
              </Button>
            ) : currentData ? (
              <Button onClick={() => setModalOpen(true)} color={'error'} variant="outlined">
                Delete Settings
              </Button>
            ) : (
              <Button onClick={() => setEditing(true)}>Set Default Settings</Button>
            )}
          </Box>
        </div>
      </Paper>
      {modalOpen && (
        <ConfirmationModal
          title="Delete Preview Settings"
          content="Are you sure that you want to remove preview settings?"
          modalOpen={modalOpen}
          toggleModal={setModalOpen}
          action={deleteSettings}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
    </>
  );
};

export default AccountSettingsCard;
