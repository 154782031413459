import { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  Typography
} from '@mui/material';
import { PinataDialogTitle } from 'components/Common/MuiComponents';
import { api, managedApi } from 'services/api';
import { PinData } from 'store/data/types';
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import {ContentCopy} from "@mui/icons-material";
import {ShareToLens, Theme} from '@lens-protocol/widgets-react'

function getTimeInMiliseconds(tokenDate: { hours: number; days: number; months: number }): number {
  const { hours, days, months } = tokenDate;
  const hourSeconds = hours * 60 * 60;
  const daysSeconds = days * 86_400;
  const monthSeconds = months * 2_628_288; //This is the average seconds in a month
  const tokenTimeInSeconds = monthSeconds + hourSeconds + daysSeconds;
  return tokenTimeInSeconds;
}

interface GenerateAccessTokenModalProps {
  getPath: (row: PinData) => string;
  setAlert: (message: string, type: string) => void;
  gatewayToUse: string;
  setPin: (pin: any) => void;
  pinInfo: any;
  cidAccessModalOpen: boolean;
  setCIDAccessModalOpen: (isOpen: boolean) => void;
}

const GenerateAccessTokenModal = ({
  getPath,
  setAlert,
  gatewayToUse,
  setPin,
  pinInfo,
  cidAccessModalOpen,
  setCIDAccessModalOpen
}: GenerateAccessTokenModalProps) => {
  const [tokenDate, setTokenDate] = useState({
    hours: 1,
    days: 0,
    months: 0
  });

  const [linkToShare, setlinkToShare] = useState('');
  const [accessTime, setAccessTime] = useState(0);
  const [generating, setGenerating] = useState(false);

  const handleDateChange = (e: any) => {
    e.preventDefault();
    const { value, name } = e.target;

    setTokenDate((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!pinInfo.v2) {
      getShareLink(getTimeInMiliseconds(tokenDate));
    }
  }, []);

  const closeModal = () => {
    setPin(null);
    setCIDAccessModalOpen(false);
  };

  const calculateAccessTimeInSeconds = () => {
    const tokenTimeInSeconds = getTimeInMiliseconds(tokenDate);
    setAccessTime(tokenTimeInSeconds);
    getShareLink(tokenTimeInSeconds);
  };

  const copyShareLink = async () => {
    navigator.clipboard.writeText(linkToShare);
    linkToShare && setAlert('Copied!', 'success');
    closeModal();
  };

  const getShareLink = async (tokenTimeInSeconds: number) => {
    let data = null;

    try {
      setGenerating(true);
      if (pinInfo.v2 && pinInfo.submarined) {
        data = await managedApi.post(`content/${pinInfo.id}/shorten`, {
          domain: gatewayToUse,
          jwtExpireSeconds: tokenTimeInSeconds
        });
      } else {
        data = await api.post(`v2/pins/${pinInfo.id}/shorten`, {
          domain: gatewayToUse
        });
      }
    } catch (error) {
      console.log(error);
      setAlert("Can't generate share link. Try again later", 'error');
      closeModal();
    } finally {
      setGenerating(false);
      setlinkToShare(data?.data?.short_url ?? '');
    }
  };

  const renderShareLink = () => (
    <>
      <Box onClick={copyShareLink} sx={{ cursor: 'pointer', my: 2 }}>
        {generating && <CircularProgress />}
        <Typography component="pre">
          <Typography component="code">{linkToShare} <ContentCopy /></Typography>
        </Typography>
      </Box>

      <Typography>Share on social media</Typography>
      {
        generating ? <CircularProgress /> :
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2, gap: 1 }}>
          {/*  Add lens react share icon */}
          {/*  <TwitterShareButton url={linkToShare} id={'twitterShare'}><TwitterIcon round size={40}/></TwitterShareButton>*/}
            {/*<LinkedinShareButton url={linkToShare} ><LinkedinIcon round size={40}/></LinkedinShareButton>*/}
            <RedditShareButton url={linkToShare} id={'redditShare'}><RedditIcon round size={40}/></RedditShareButton>
            <TelegramShareButton url={linkToShare} id={'telegramShare'}><TelegramIcon round size={40}/></TelegramShareButton>
            <EmailShareButton url={linkToShare} id={'emailShare'}><EmailIcon round size={40}/></EmailShareButton>
            <ShareToLens content={"Check out this content"} url={linkToShare} hashtags={"pinataCloud"} theme={Theme["mint"]}/>
          </Box>
      }

      <Button onClick={closeModal} fullWidth color="error">
        Close
      </Button>
    </>
  );

  return (
    <Dialog open={cidAccessModalOpen} onClose={closeModal} fullWidth sx={{ p: 2 }}>
      <PinataDialogTitle onClose={closeModal}>Share File</PinataDialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        {pinInfo && pinInfo.submarined ? (
          <>
            <Typography variant="h6">You're about to share a submarined file:</Typography>
            {accessTime ? (
              renderShareLink()
            ) : (
              <>
                <Typography sx={{ fontSize: '0.8rem' }} component="code">
                  {getPath(pinInfo)}
                </Typography>
                <Typography component="p" sx={{ fontSize: '0.8rem' }}>
                  Select how long you'd like the share link to be valid for (default: 1 hour, max: 8 hours).
                </Typography>
                <Box sx={{ display: 'flex', my: 2, flexDirection: 'column' }}>
                  <FormControl sx={{ my: 1 }}>
                    <InputLabel htmlFor="hours">Hours</InputLabel>
                    <Input
                      type="number"
                      inputProps={{
                        min: 1,
                        max: 8
                      }}
                      onChange={handleDateChange}
                      name="hours"
                      value={tokenDate.hours}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Button
                    sx={{ mr: 1 }} fullWidth
                    onClick={calculateAccessTimeInSeconds}
                    color="primary"
                    disabled={tokenDate.hours > 8}
                  >
                    {generating ? 'Generating share link...' : 'Share'}
                  </Button>
                  <Button sx={{ ml: 1 }} fullWidth onClick={closeModal} color="error">
                    Cancel
                  </Button>
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            <Typography variant="h5">You're about to share a public file</Typography>
            {renderShareLink()}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GenerateAccessTokenModal;
