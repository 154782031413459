import { Typography, Box } from '@mui/material';
import { prettySize } from 'helpers/pretty';

interface BandwidthObj {
  domain: string;
  bandwidth: number;
  requests: number;
}

let grandTotalRequests = 0;
let grandTotalBandwidth = 0;
const Bandwidth = ({ usageData }: { usageData: BandwidthObj[] }) => {
  grandTotalRequests = 0;
  grandTotalBandwidth = 0;
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        my: 1
      }}
    >
      <Typography variant="h5">Bandwidth Used</Typography>
      {usageData.map((usage, index) => {
        grandTotalRequests += usage.requests;
        grandTotalBandwidth += usage.bandwidth;
        return (
          <Box component="div" sx={{ my: 2 }} key={index}>
            <Typography variant="h6">{usage.domain}</Typography>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 8,
                p: 3,
                border: '1px #556ee6 solid',
                borderRadius: 1000,
                height: 355,
                width: 355,
                margin: 'auto'
              }}
            >
              <Typography sx={{ my: 1 }} variant="h2">
                {prettySize(usage.bandwidth || 0)}
              </Typography>
            </Box>
            <p>Requests: {usage.requests}</p>
          </Box>
        );
      })}
      <Box component="div" sx={{ my: 2, width: '100%' }}>
        <Typography variant="h5">
          Total
          <br />
          Requests: {grandTotalRequests} - Bandwidth: {prettySize(grandTotalBandwidth || 0)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Bandwidth;
