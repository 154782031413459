import React from "react";
import {useTheme} from "@mui/material";

export default function Eye() {
    const theme = useTheme();
    return (
      <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
      >
      <path
    stroke={theme.palette.primary.main}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      ></path>
    <path
      stroke={theme.palette.primary.main}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2.458 12C3.733 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.543 7-1.275 4.057-5.065 7-9.542 7-4.478 0-8.268-2.943-9.543-7z"
    ></path>
</svg>
    );
}