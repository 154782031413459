import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import mapJson from './data/worldMap.json';
import countriesJson from './data/countryList.json';
import { purple, pink } from './../../../../themes/colors';

type Props = {
  data: any;
  unit: string;
  showLabel?: 'normal' | 'prettysize';
};

// @ts-ignore
echarts.registerMap('World', mapJson);

const MapChart = ({data, unit, showLabel='normal'}: Props) => {
  const getFormattedData = () => {
    return data.map((originalData: any) => {
      originalData.name = (countriesJson.countries.find((country: any) => country.code === originalData.name.toUpperCase())?.name);
      return originalData;
    })    
  }

  let chartOptions = {
    tooltip: {
      trigger: 'item',
      showDelay: 0,
      transitionDuration: 0.2
    },
    visualMap: {
      left: 'left',
      min: data.length < 2 ? 0 : data[0].value,
      max: data[data.length-1].value,
      inRange: {
        color: [
          purple[300],
          purple[500],
          purple[700],
          pink[300],
          pink[500],
          pink[700]
        ]
      },
      text: ['High', 'Low'],
      calculable: true
    },
    series: [
      {
        name: 'Country',
        type: 'map',
        map: 'World',
        emphasis: {
          label: {
            show: true
          }
        },
        data: getFormattedData()
      }
    ]
  };

  return (
    <ReactECharts
      style={{width: '100%', height: '100%'}}
      notMerge={true}
      lazyUpdate={true}
      option={chartOptions}
    />
  )
}

export default MapChart;
