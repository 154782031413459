import { useMemo } from 'react';
import { planTypes } from 'helpers/enums';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  Typography,
  Switch,
  Tooltip
} from '@mui/material';
import type { BillingState } from 'store/billing/types';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface FileDataProps {
  setAlert: (message: string, type: string) => void;
  fileName: string;
  setFileName: (name: string) => void;
  handleSetStep: (step: number) => void;
  setNameInputCount: (count: number) => void;
  submarine: boolean;
  setSubmarine: (isSubmarine: boolean) => void;
  upload: any;
  billing: BillingState;
}

const FileData = ({
  setAlert,
  fileName,
  setFileName,
  handleSetStep,
  setNameInputCount,
  submarine,
  setSubmarine,
  upload,
  billing
}: FileDataProps) => {
  const handleChange = (event: any) => {
    setFileName(event.target.value);
    setNameInputCount(event.target.value.length);
  };

  const handleUploadStep = () => {
    if (fileName) {
      handleSetStep(2);
      upload();
    } else {
      setAlert('Name is required', 'error');
    }
  };

  const isProPlan = () => {
    return (
      (billing?.activePricingPlan?.type && billing?.activePricingPlan?.type !== planTypes.FREE.type) ||
      billing?.activePricingPlan?.name === 'PROFESSIONAL'
    );
  };

  const fileNameTooLong = useMemo(() => fileName?.length > 50, [fileName]);

  return (
    <Box>
      <Typography variant="h5">Details</Typography>
      <Typography sx={{ my: 1 }} component="p">
        Give your file or folder a name.
      </Typography>

      <Badge
        sx={{ width: '100%', '& .MuiBadge-badge': { right: '25px' } }}
        badgeContent={`${fileName?.length} / 50`}
        color={fileNameTooLong ? 'error' : 'primary'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <TextField
            sx={{ my: 1, m: 0 }}
            fullWidth
            label="Name"
            onChange={handleChange}
            name="thresholdconfig"
            id="thresholdconfig"
            value={fileName}
            error={!!fileNameTooLong}
            required
          />
          {fileNameTooLong && <FormHelperText>Name can't be longer than 50 characters</FormHelperText>}
        </Box>
      </Badge>

      {isProPlan() && (
        <Box sx={{ mt: 3 }}>
          <FormGroup>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={<Switch checked={submarine} onChange={() => setSubmarine(!submarine)} />}
                label={<>Make your file private?</>}
              />
              <Tooltip
                placement="top"
                title="Making a file private is the process of generating an IPFS CID and storing a file without putting the file on IPFS. You can upload another copy of the file without making it private to put it on the public network, or you can leave it private."
                componentsProps={{
                  tooltip: {
                    sx: {
                      padding: 2,
                    },
                  },
                }}
              >
                {submarine ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
              </Tooltip>
            </Box>
          </FormGroup>
        </Box>
      )}
      <Button
        disabled={!fileName || fileNameTooLong}
        onClick={handleUploadStep}
        sx={{ mt: 2 }}
        color="primary"
        fullWidth
      >
        Upload
      </Button>
    </Box>
  );
};

export default FileData;
