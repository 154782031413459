import { useState, useEffect, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, List } from '@mui/material';
import CardPricing from '../Pricing/CardPricing';
import ConfirmationModal from 'pages/Gateway/ConfirmationModal';
import BillingAddressModal from './BillingAddressModal';
import AddCardModal from './AddCardModal';
import { AfterUpdatePlanDialog } from '../../components/Common/AfterUpdatePlanDialog';
import { cancelUserAccount, loadUserInfo, restoreCanceledAccount } from '../../store/user/user.actions';
import { createStripePaymentSource, retrieveStripeCustomer } from '../../store/billing/billing.actions';
import { createGateway } from '../../store/gateways/gateway.actions';
import { setAlert } from '../../store/alert/alert.actions';
import type { Gateways } from '../../store/gateways/types';
import type { User, UserState } from '../../store/user/types';
import type { BillingPlan, BillingState, Plan } from '../../store/billing/types';
import { planTypes } from 'helpers/enums';
import { useAnalytics } from 'use-analytics';
import { ANALYTICS } from 'common/constants';

interface ChangePlanRes {
  plan: Plan;
  nextPlan: Plan;
}

interface PlanSelectorProps {
  data: any;
  billing: BillingState;
  setAlert: any;
  changePlan: (newPlan: Plan) => Promise<ChangePlanRes>;
  gateways: Gateways;
  cancelUserAccount: any;
  user: UserState;
  apiKeys: any;
  restoreCanceledAccount: () => void;
  loadUserInfo: (user: User) => Promise<any>;
  createGateway: (gatewayInfo: { subdomain: string; restricted?: boolean }, update: boolean) => any;
  createStripePaymentSource: (...props: any) => any;
  scheduleUsageMetrics: (...props: any) => any;
}

const PlanSelector = ({
  billing,
  changePlan,
  gateways,
  user,
  restoreCanceledAccount,
  loadUserInfo,
  createGateway,
  createStripePaymentSource,
  scheduleUsageMetrics
}: PlanSelectorProps) => {
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planToChangeTo, setPlanChoice] = useState<any>(null);
  const [planChoiceConfirmationOpen, setPlanChangeConfirmationOpen] = useState(false);
  const [openCardModal, setOpenCardModal] = useState(false); //Boolean or null if no default
  const [openBillingAddressModal, setOpenBillingAddressModal] = useState(false);
  const [restorePlanModalOpen, setRestorePlanModalOpen] = useState(false);
  const [afterUpdateDialogProps, setAfterUpdateDialogProps] = useState<{ newPlan: Plan; gateways: Gateways } | null>(
    null
  );
  const [upgradeButtonWasClicked, setUpgradeButtonWasClicked] = useState<boolean>(false);
  const [planWasUpgraded, setPlanWasUpgraded] = useState<boolean>(false);
  const plansRef = useRef<null | HTMLUListElement>(null);
  const location: any = useLocation();
  const history = useHistory();
  const { track } = useAnalytics();
  const { hash } = location;

  const changePlanLocal = async (planToChangeTo: Plan) => {
    // if (
    //   planToChangeTo.type !== planTypes.FREE.type &&
    //   (!(billing?.stripe_customer?.address?.country?.length > 0) ||
    //     !(billing?.stripe_customer?.address?.postalCode?.length > 0))
    // ) {
    //   setOpenBillingAddressModal(true);
    // } else {
    try {
      const changePlanRes: ChangePlanRes = await changePlan(planToChangeTo);
      if (!changePlanRes.nextPlan) {
        scheduleUsageMetrics();
        setAfterUpdateDialogProps({ newPlan: changePlanRes.plan, gateways });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
    // }
  };

  const handleAddCard = async (tokenId: string) => {
    setOpenCardModal(false);
    try {
      setAlert('Adding card...', 'info');
      const stripeRes = await createStripePaymentSource(tokenId);
      if (stripeRes) {
        setPlanChangeConfirmationOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Scroll to anchor hash
  useEffect(() => {
    const scrollToHashElement = () => {
      const elementToScroll = document.getElementById(hash?.replace('#', ''));

      if (hash === '#upgrade') {
        setTimeout(() => {
          plansRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }, 1000);
        setUpgradeButtonWasClicked(true);
        history.push(location.pathname);
        return;
      }

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll?.offsetTop - 75,
        behavior: 'smooth'
      });
    };

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, [hash]);

  useEffect(() => {
    // check if user came from marketing site and wants to change plan
    if (location?.state?.registerFromMarketing) {
      const currentPlan = billing?.activePricingPlan;
      const desiredPlan = billing?.billing_plans?.find((item) => item.nickname === location?.state?.desiredPlan);
      if (desiredPlan && currentPlan) {
        handlePlanChoice(desiredPlan);
        localStorage.removeItem('pinata-registration-plan-selection');
      }
    }
  }, []);

  useEffect(() => {
    if (upgradeButtonWasClicked) {
      if (planWasUpgraded) {
        track(ANALYTICS.PROCESS.BILLING.ACC_UPGRADED, {
          planName: planToChangeTo
        });
      }
      return () => {
        !planWasUpgraded && track(ANALYTICS.PROCESS.BILLING.ACC_WAS_NOT_UPGRADED, {});
      };
    }
  }, [planWasUpgraded]);

  const handlePlanChoice = async (plan: Plan) => {
    setPlanChoice(plan);
    if (!billing.stripe_customer.paymentMethods.length && plan.type !== planTypes.FREE.type) {
      // if there is no payment method -> add credit card
      setOpenCardModal(true);
      return;
    }
    if (user.user?.scheduledToBeCancelledAt) {
      setRestorePlanModalOpen(true);
    } else if (
      (billing?.activePricingPlan &&
        plan.id !== billing?.activePricingPlan?.id &&
        plan.price > billing?.activePricingPlan?.price) ||
      billing?.activePricingPlan?.isLegacy
    ) {
      // if planToChange's type is greater than current - we just confirm and change it
      setPlanChangeConfirmationOpen(true);
    } else {
      // Need to kill off gateways and alert them
      setChangePlanModalOpen(true);
    }
  };

  const confirmProfessionalUpgrade = async () => {
    track(ANALYTICS.PROCESS.BILLING.PLAN_PURCHASED, {
      planName: planToChangeTo
    });
    track(ANALYTICS.PROCESS.BILLING.PLAN_CHANGED, {
      planName: planToChangeTo
    });
    setLoading(true);
    try {
      if (billing.stripe_customer) {
        await changePlanLocal(planToChangeTo);
        setPlanWasUpgraded(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPlanChangeConfirmationOpen(false);
    }
  };

  const confirmRestorePlan = async () => {
    setLoading(true);
    try {
      await restoreCanceledAccount();
    } catch (error) {
      console.log(error);
    } finally {
      if (user.user) {
        await loadUserInfo(user.user);
      }
      setRestorePlanModalOpen(false);
    }
  };

  const confirmDowngrade = async () => {
    track(ANALYTICS.PROCESS.BILLING.PLAN_CHANGED, {
      planName: planToChangeTo
    });
    setLoading(true);
    try {
      await changePlanLocal(planToChangeTo);
    } catch (error) {
      console.log(error);
    } finally {
      setChangePlanModalOpen(false);
    }
  };

  const getChangePlanText = useMemo(() => {
    return (
      <>
        <p>
          {!billing?.activePricingPlan?.isLegacy && planToChangeTo?.type === planTypes.FREE.type
            ? `By downgrading your plan, any dedicated gateways you've created will be removed and you won't be able to access them.`
            : billing?.activePricingPlan?.isLegacy
            ? `Are you sure you want to change your plan?`
            : billing?.activePricingPlan?.type === planToChangeTo?.type
            ? 'Are you sure you want to cancel your downgrade and keep your current plan?'
            : `Are you sure you want to downgrade? If your usage exceeds 125% of the plan you downgrade to, your account will be locked.`}
          {planToChangeTo?.type === planTypes.FREE.type
            ? 'Also the payments methods will be removed at the end of the billing period.'
            : ''}
        </p>
        {billing?.nextBillingDate && billing?.activePricingPlan?.type !== planToChangeTo?.type && (
          <p>
            This change will take effect on <strong>{billing?.nextBillingDate}</strong>. Please continue to enjoy the
            features of the <strong>{billing?.activePricingPlan?.nickname}</strong> until then.
          </p>
        )}
      </>
    );
  }, [billing?.activePricingPlan, billing?.nextBillingDate, planToChangeTo]);

  return (
    <>
      {billing?.activePricingPlan?.type === planTypes.ENTERPRISE.type ? (
        <Alert variant="filled" color="info" sx={{ p: 2 }}>
          Please <a href="mailto:sales@pinata.cloud">contact</a> your account manager to adjust your plan.
        </Alert>
      ) : (
        <List ref={plansRef} id="pinata-pricing-cards-list" sx={{gridTemplateColumns: `repeat(${billing?.billing_plans.length}, 1fr)`}}>
          {billing?.billing_plans
            ?.map((pricing: BillingPlan) => (
              <CardPricing
                plan={pricing}
                key={pricing.id}
                action={handlePlanChoice}
                currentPlan={billing?.activePricingPlan}
                nextPlan={billing?.nextPlan}
              />
            ))}
        </List>
      )}
      {changePlanModalOpen && (
        <ConfirmationModal
          title="Change Plan?"
          content={getChangePlanText}
          modalOpen={changePlanModalOpen}
          toggleModal={setChangePlanModalOpen}
          loading={loading}
          action={confirmDowngrade}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
      {planChoiceConfirmationOpen && (
        <ConfirmationModal
          title={`Upgrade to ${planToChangeTo.nickname}?`}
          content={`Are you sure you want to upgrade to the ${planToChangeTo.nickname} plan for $${planToChangeTo.price}/month?`}
          modalOpen={planChoiceConfirmationOpen}
          toggleModal={setPlanChangeConfirmationOpen}
          loading={loading}
          action={confirmProfessionalUpgrade}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
      {restorePlanModalOpen && (
        <ConfirmationModal
          title={`Restore Plan?`}
          content={`Your plan is scheduled to be cancelled by ${dayjs(user.user?.scheduledToBeCancelledAt).format(
            'YYYY-MM-DD'
          )}, in order to change your plan you need first to restore your plan`}
          modalOpen={restorePlanModalOpen}
          toggleModal={setRestorePlanModalOpen}
          loading={loading}
          action={confirmRestorePlan}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
      {openBillingAddressModal && (
        <BillingAddressModal
          setModalOpen={setOpenBillingAddressModal}
          modalIsOpen={openBillingAddressModal}
          mainActionHandler={() => changePlan(planToChangeTo)}
        />
      )}
      {openCardModal && (
        <AddCardModal
          setAddCardModalOpen={setOpenCardModal}
          addCardModalOpen={openCardModal}
          handleAddCard={handleAddCard}
          billing={billing}
          setAlert={setAlert}
        />
      )}
      {afterUpdateDialogProps !== null && (
        <AfterUpdatePlanDialog
          createGateway={createGateway}
          newPlan={afterUpdateDialogProps.newPlan}
          gateways={gateways}
          onClose={() => {
            setAfterUpdateDialogProps(null);
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  cancelUserAccount,
  restoreCanceledAccount,
  loadUserInfo,
  retrieveStripeCustomer,
  createGateway,
  createStripePaymentSource
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    apiKeys: state.apiKeys
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelector);
