import { Box, Card, Grid, LinearProgress, Typography, useTheme } from '@mui/material';
import { PieChart, MapChart, PieChartType } from '../Charts';
import DataTable from '../DataTable/DataTable';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useGetAnalyticsGateway } from 'hooks';
import { metrics, metricsBy } from '../analyticsTypes';

type Props = {
  title: string;
  dateStartFilter: Date;
  dateEndFilter: Date;
  gatewayFilter: string;
  metric: metrics;
  by: metricsBy;
  top: number;
}

const GatewayData = ({title, dateStartFilter, dateEndFilter, gatewayFilter, metric, by, top}: Props) => {
  const theme = useTheme();
  const {
    isLoadingGetAnalyticsGateway,
    errorGetAnalyticsGateway,
    dataGetAnalyticsGateway
  } = useGetAnalyticsGateway({gateway: gatewayFilter, metric, by, startDate: dateStartFilter, endDate: dateEndFilter, top});

  if(isLoadingGetAnalyticsGateway) return (
    <Box sx={{ width: '100%', textAlign: 'center', marginTop: 4}}>
      <LinearProgress />
      Loading...
    </Box>
  );
  
  const dataToChart = (data: any) => {
    const chartData: Array<PieChartType> = [];
    data.forEach((element: any, index: number) => {
      chartData.push({
        value: metric === 'bandwidth' ? element.bandwidth_in_bytes : element.requests,
        name: `${Object.values(element)[0]}`,
      });
    });
    return chartData;
  };

  return (
    <Card sx={{padding: 3, boxShadow: 'none'}}>
      <Box>
        <Typography variant="h6" sx={{marginBottom: 2}}>{title}</Typography>
        {
          !dataGetAnalyticsGateway?.data || !dataGetAnalyticsGateway?.data?.length ? (
            <Box sx={{textAlign: 'center'}}>
              <Box>
                <TrendingUpIcon sx={{color: theme.palette.primary.light, backgroundColor: 'white', border: 1, borderRadius: '18px', width: 80, height: 80, padding: 2, borderColor: theme.palette.grey[400]}} />
              </Box>
              <Typography component={'h6'} variant="h6" sx={{marginBottom: 1}}>No Data</Typography>
              <Typography variant='caption' sx={{marginBottom: 2}}>
                We don't have any data for the filters you selected.<br />Try another gateway or date range.                
              </Typography>
            </Box>
          ) : (
            <Grid container>
              <Grid item md={6} xs={12} sx={{minHeight: 300}}>
                {
                  by === 'byCountry' ? (
                    <MapChart 
                      data={dataToChart(dataGetAnalyticsGateway?.data)}
                      unit={metric === 'bandwidth' ? 'Bytes' : 'Requests'}
                      showLabel={metric === 'bandwidth' ? 'prettysize' : 'normal'}
                    />  
                  ) : (
                    <PieChart 
                      data={dataToChart(dataGetAnalyticsGateway?.data)}
                      unit={metric === 'bandwidth' ? 'Bytes' : 'Requests'}
                      showLabel={metric === 'bandwidth' ? 'prettysize' : 'normal'}
                    />  
                  )
                }
              </Grid>
              <Grid item md={6} xs={12}>
                <DataTable
                  data={dataGetAnalyticsGateway?.data}
                  gatewayFilter={gatewayFilter}
                  metric={metric}
                  dataDescription={by.slice(2)}
                  copyColumn={by === 'byCID' || by === 'byReferrer' || by === 'byUserAgent'}
                />
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Card>
  )
}

export default GatewayData;
