import { Box, Button, CircularProgress, TextField, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import { AuthContext, AuthPages } from './common';
import { useQueryClient } from '@tanstack/react-query';

enum SubmitStatuses {
  UNSTARTED = 'UNSTARTED',
  STARTED = 'STARTED',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

type SigninInputs = {
  [x: string]: any;
};

export function SignInForm() {
  const [submitStatus, setSubmitStatus] = useState(SubmitStatuses.UNSTARTED);
  const { setCognitoUser, setCurrentPage, setAuthAlert, setFormData } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const { logUserIn, confirmSignUp, resendConfirmationCode, confirmMFA } = useAuth();
  const history = useHistory();
  const theme = useTheme();
  useEffect(() => {
    setAuthAlert(null);
  }, []);

  const queryClient = useQueryClient();
  const onSubmit: SubmitHandler<SigninInputs> = async (data) => {
    queryClient.clear();  
    const { email, password, code } = data;
    setSubmitStatus(SubmitStatuses.STARTED);
    setAuthAlert(null);
    const result = await logUserIn(email, password);
    if (result.user && result.user.challengeName) {
      //  Indicates the user has MFA enabled
      setCognitoUser(result.user);
      setCurrentPage(AuthPages.MFA);
    } else if (result.error && result.error.code && result.error.code === 'UserNotConfirmedException') {
      setAuthAlert({
        type: 'error',
        message: 'Account not confirmed, enter code that was previously emailed or request a new one'
      });
      setFormData({ email, password });
      setCurrentPage(AuthPages.EMAIL_CODE);
    } else if (
      !result.success &&
      (result.error.code === 'UserNotFoundException' || result.error.code === 'NotAuthorizedException')
    ) {
      setAuthAlert({ type: 'error', message: 'Wrong email or password, try again.' });
    } else if (!result.success) {
      setAuthAlert({ type: 'error', message: result.error.message });
    } else {
      history.push(`/authenticate${location.search}`);
    }
    setSubmitStatus(SubmitStatuses.UNSTARTED);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}
    >
      <Box sx={{ margin: 'auto 0', display: 'flex', flexDirection: 'column' }}>
        <TextField
          sx={{ my: 1 }}
          variant="standard"
          fullWidth
          label="Email"
          type="email"
          name="email"
          id="d2-auth-login-email"
          placeholder="Enter your email"
          autoComplete="email"
          required
          inputProps={{
            ...register('email')
          }}
        />
        <TextField
          sx={{ my: 1 }}
          variant="standard"
          fullWidth
          label="Password"
          type="password"
          name="password"
          id="d2-auth-login-password"
          placeholder="Password"
          autoComplete="password"
          required
          inputProps={{
            ...register('password')
          }}
        />
        <Button
          variant={'link'}
          onClick={() => {
            setCurrentPage(AuthPages.FORGOT_PASSWORD);
          }}
          sx={{ alignSelf: 'flex-end' }}
          id="d2-auth-login-forgotPass"
        >
          Forgot Password
        </Button>
      </Box>
      <Button fullWidth color="primary" size="large" type={'submit'} id="d2-auth-login-submit">
        {submitStatus === SubmitStatuses.STARTED ? <CircularProgress size={24} /> : 'Log In'}
      </Button>
    </Box>
  );
}
