import { BillingPlan, Plan } from '../../store/billing/types';
import {Box, Button, Card, CardContent, List, ListItem, Paper, Typography, useTheme} from '@mui/material';
import { Check } from '@mui/icons-material';

interface CardPricingProps {
  plan: BillingPlan;
  action: any;
  currentPlan: Plan | null;
  nextPlan: Plan | null;
}

const CardPricing = ({ plan, action, currentPlan, nextPlan }: CardPricingProps) => {
  const theme = useTheme();
  return (
    <ListItem sx={{padding: 1}}>
      <Paper
        sx={{ height: '100%', width: '100%', border: currentPlan?.type === plan.type ? `6px solid ${theme.palette.primary.main}` : '' }}
      >
        <Box
          sx={{
            p: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '0.9rem'
          }}
        >
          <Box>
            <Typography variant="h4" align={'center'}>
              {plan.nickname}
            </Typography>
            {/*<Typography align={'center'}>{plan.subtitle}</Typography>*/}
            <Typography sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <span style={{ fontSize: '3em' }}>${plan.price}</span>
              <span style={{ fontSize: '1em', marginLeft: '0.5em' }}>/month</span>
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <List>
                {plan?.features?.length > 0 &&
                  plan.features.map((feature, index) => {
                    return (
                      <ListItem key={`feature_${index}`} sx={{ padding: 0, py: 0.5 }}>
                        <div className="d-flex align-items-center">
                          <Check color={'primary'} sx={{ mr: 1, fontSize: '1.2rem' }} />
                          <span>{feature}</span>
                        </div>
                      </ListItem>
                    );
                  })}
              </List>
            </Box>
          </Box>
          {currentPlan?.type !== plan.type && nextPlan?.type !== plan.type && (
            <Button
              disabled={currentPlan?.type === plan.type}
              onClick={() => action(plan)}
              size="large"
              sx={{ marginTop: 2 }}
              id={`select_${plan.nickname}`}
            >
              Select {plan.nickname}
            </Button>
          )}
          {/* do not allow user click one more time to the already chosen plan */}
          {nextPlan?.type === plan.type && currentPlan?.type !== plan.type && (
            <Button disabled={nextPlan?.type === plan.type} size="large" sx={{ marginTop: 2 }}>
              Your Next Plan
            </Button>
          )}
          {currentPlan?.type === plan.type && nextPlan && nextPlan.type !== plan.type && (
            <>
              <Typography variant="caption" sx={{ marginTop: 2 }}>
                Your plan will be downgraded
              </Typography>
              <Button size="large" onClick={() => action(plan)}>
                Keep my current plan
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </ListItem>
  );
};

export default CardPricing;
