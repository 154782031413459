import { useState, useRef, useMemo, useEffect } from 'react';
import { ROOT_DOMAIN } from './gatewayConstants';
import { Box, FormHelperText, InputAdornment, TextField, Typography } from '@mui/material';
import { Cancel, CheckCircle } from '@mui/icons-material';
import useDebounce from 'hooks/useDebounce';
import { isValidDomainName } from 'helpers/validations';

interface SelectDomainProps {
  subdomain: string;
  setSubdomain: (name: string) => void;
  checkSubdomain: (subdomain: string) => Promise<boolean>;
  setIsDisabled: (isDisabled: boolean) => void;
}

const validDomain = (value: string): boolean => {
  const tooLong = value.length > 63;
  const slashMatch = value.includes('/');
  const colonMatch = value.includes(':');
  const periodMatch = value.includes('.');
  const spaceMatch = value.includes(' ');
  const match = slashMatch || colonMatch || periodMatch || spaceMatch || tooLong;
  return !match;
};

const SelectDomain = ({ subdomain, setSubdomain, checkSubdomain, setIsDisabled }: SelectDomainProps) => {
  const [checking, setChecking] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(false);
  const [displayResults, setDisplayResults] = useState<boolean>(false);
  const [domainError, setDomainError] = useState<string>('');
  const inputRef = useRef<any>(null);
  const debouncedValue = useDebounce(subdomain, 500);

  const handleChange = (event: any) => {
    setIsDisabled(true);
    const value = event.target.value;
    setSubdomain(value);
  };

  const checkDomain = (domain: string) => {
    try {
      setChecking(true);
      const isValid = validDomain(domain);

      if (isValid) {
        checkSubdomain(domain).then((isTaken: boolean) => {
          setDisplayResults(true);
          if (isTaken) {
            setAvailable(false);
            setDomainError('This gateway name is already taken');
          } else {
            setAvailable(true);
            setDomainError('');
          }
        });
      }
    } catch (error) {
      console.log(error);
      setDisplayResults(false);
      setDomainError('Invalid subdomain');
    } finally {
      setChecking(false);
    }
  };

  const isValidInput = useMemo(() => {
    return isValidDomainName(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    // focus input everytime user is typing
    if (!checking) {
      inputRef?.current?.focus();
    }
  }, [checking]);

  useEffect(() => setIsDisabled(!available || domainError.length > 0 || checking), [available, domainError, checking]);

  useEffect(() => {
    if (debouncedValue.length > 0) {
      if (isValidInput) {
        checkDomain(debouncedValue);
      } else {
        setDomainError('Invalid subdomain name');
        setDisplayResults(false);
        setAvailable(false);
      }
    }
    return () => {
      setDomainError('');
      setDisplayResults(false);
      setAvailable(false);
      setChecking(false);
    };
  }, [debouncedValue]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          inputRef={inputRef}
          sx={{
            '& > .MuiInputBase-root': {
              m: 0
            },
            width: '50%',
            m: 0
          }}
          id="domain-search"
          label="Name"
          onChange={handleChange}
          value={subdomain}
          autoComplete="off"
          disabled={checking}
          placeholder="mycompanyname"
          data-placeholder={ROOT_DOMAIN}
          error={domainError.length > 0}
          InputProps={{
            ...(subdomain.length && {
              endAdornment: (
                <InputAdornment position="end">
                  {domainError.length > 0 || (displayResults && !available) ? (
                    <Cancel color="error" />
                  ) : displayResults && available ? (
                    <CheckCircle color="success" />
                  ) : null}
                </InputAdornment>
              )
            })
          }}
        />
        <Typography sx={{ mx: 1 }}>{ROOT_DOMAIN}</Typography>
      </Box>
      {domainError.length > 0 && (
        <FormHelperText error sx={{ lineHeight: '18px', mx: 0, fontSize: 12 }}>
          {domainError}
        </FormHelperText>
      )}
    </>
  );
};

export default SelectDomain;
