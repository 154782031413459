import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import Dashboard from "../Dashboard";

function a11yProps(index: number) {
  return {
    id: `analytics-tab-${index}`,
    'aria-controls': `analytics-tabpanel-${index}`,
  };
}

type analyticsTabData = {
  tabTitle: string;
  config: any;
}

type Props = {
  dateStartFilter: Date;
  dateEndFilter: Date;
  gatewayFilter: string;
  analyticsTabDataArray: Array<analyticsTabData>;
}

const DataTabs = ({dateStartFilter, dateEndFilter, gatewayFilter, analyticsTabDataArray}: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Analytics tabs">
          {
            analyticsTabDataArray.map((analyticsTabData, index) => 
              <Tab key={index} label={analyticsTabData.tabTitle} {...a11yProps(index)} />
            )
          }
        </Tabs>
      </Box>
      {
        analyticsTabDataArray.map((analyticsTabData, index) => {
          return(
            <TabPanel value={value} index={index} key={index}>
              <Dashboard 
                dateStartFilter={dateStartFilter}
                dateEndFilter={dateEndFilter}
                gatewayFilter={gatewayFilter}
                config={analyticsTabData.config}
              />
            </TabPanel>    
          );
        })
      }
    </Box>
  );
}
 export default DataTabs;
 