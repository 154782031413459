import { ChangeEvent, useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  Grid,
  List,
  Typography,
  Button,
  ListItem,
  ListItemText,
  Box,
  TextField,
  Tooltip,
  useTheme, useMediaQuery
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import GatewaySettingsItem from './GatewaySettingsItem';
import ConfirmationModal from '../../Gateway/ConfirmationModal';
import AccessTokenCard from './AccessTokenCard';
import NoContent from './GatewaySettingsNoContent';
import GatewaySettingsDataTable from './GatewaySettingsDataTable';
import { setAlert } from 'store/alert/alert.actions';
import {
  createGatewaySettingsToken,
  removeGatewaysSettingsToken,
  createGatewaySettingsIp,
  removeGatewaySettingsIp,
  createGatewaySettingsHost,
  removeGatewaySettingsHost,
  getGatewayDetails,
  getAllGateways
} from 'store/gateways/gateway.actions';
import { ROOT_DOMAIN } from '../../Gateway/GatewayWizard/gatewayConstants';
import type { GatewayRow } from '../../../store/gateways/types';
import LightLogo from '../../../assets/images/lightlogo.png';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface GatewaySettingsProps {
  gateways: GatewayRow[];
  setAlert: (message: string, type: 'success' | 'error') => void;
  createGatewaySettingsToken: (gatewayId: string) => void;
  createGatewaySettingsIp: (gatewayId: string, ipAddressToAdd: string) => void;
  createGatewaySettingsHost: (gatewayId: string, hostToAdd: string) => void;
  removeGatewaysSettingsToken: (gatewayId: string, token: string) => void;
  removeGatewaySettingsIp: (gatewayId: string, ipToRemove: string) => void;
  removeGatewaySettingsHost: (gatewayId: string, hostToRemove: string) => void;
  getGatewayDetails: (gatewayId: string) => void;
  getAllGateways: (page: number) => any;
}

enum SettingsItem {
  AccessTokens = 'AccessTokens',
  IpAdresses = 'IpAdresses',
  HostOrigin = 'HostOrigin'
}

function GatewaySettings(props: GatewaySettingsProps) {
  const {
    gateways,
    setAlert,
    createGatewaySettingsToken,
    removeGatewaysSettingsToken,
    createGatewaySettingsIp,
    removeGatewaySettingsIp,
    createGatewaySettingsHost,
    removeGatewaySettingsHost,
    getGatewayDetails,
    getAllGateways
  } = props;

  const [selectedGatewayIndex, setSelectedGatewayIndex] = useState<number>(0);
  const [selectedSettingsTab, setSelectedSettingsTab] = useState<string>('');
  const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
  const [ipAdressToCreate, setIpAdressToCreate] = useState<string>('');
  const [hostOriginToCreate, setHostOriginToCreate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ipAddressToSearch, setIpAdressToSearch] = useState<string>('');
  const [hostOriginToSearch, setHostOriginToSearch] = useState<string>('');
  const { search } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const { gatewayAllowMultipleControls } = useFlags();
  const selectedGateway = new URLSearchParams(search).get('gateway');

  useEffect(() => {
    if (selectedGateway) {
      const defaultIndex = gateways.findIndex((gateway) => gateway.domain === selectedGateway);
      if (defaultIndex) {
        setSelectedGatewayIndex(defaultIndex);
        history.replace({
          search: ''
        });
      }
    }
  }, [gateways]);

  useEffect(() => {
    if (gateways.length > 0) {
      getGatewayDetails(gateways[selectedGatewayIndex]?.id);
    }
  }, [selectedGatewayIndex, gateways.length]);

  // Dynamically set the selected tab and content for the CreateModal
  const actionModalContent = useMemo(() => {
    let content,
      title = null;

    if (selectedSettingsTab) {
      if (selectedSettingsTab === SettingsItem.AccessTokens) {
        title = <Box sx={{ display: 'flex', alignItems: 'center' }}>Access Controls Request Token</Box>;
        content = `Are you sure you want to create an access token for this gateway ${gateways[selectedGatewayIndex]?.domain}?`;
      }
      if (selectedSettingsTab === SettingsItem.IpAdresses) {
        title = <Box sx={{ display: 'flex', alignItems: 'center' }}>Access Controls IP Address</Box>;
        content = (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Enter IP Address"
              id="create-ip-input"
              placeholder="000.000.000"
              value={ipAdressToCreate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setIpAdressToCreate(e.target.value)}
            />
          </Box>
        );
      }
      if (selectedSettingsTab === SettingsItem.HostOrigin) {
        title = <Box>Access Controls Host Origin</Box>;
        content = (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Enter Host URL"
              id="create-host-input"
              value={hostOriginToCreate}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setHostOriginToCreate(e.target.value)}
            />
          </Box>
        );
      }
    } else {
      // erase content if no tab is not selected
      if (ipAdressToCreate) setIpAdressToCreate('');
      if (hostOriginToCreate) setHostOriginToCreate('');
    }
    return {
      content,
      title
    };
  }, [selectedSettingsTab, ipAdressToCreate, hostOriginToCreate]);

  function handleAddAction(title: string) {
    setSelectedSettingsTab(title);
    setIsActionModalOpen(true);
  }

  // handle SettingsItem button action
  function handleCreateAction() {
    setIsLoading(true);
    try {
      switch (selectedSettingsTab) {
        case SettingsItem.AccessTokens:
          createGatewaySettingsToken(gateways[selectedGatewayIndex]?.id);
          break;
        case SettingsItem.IpAdresses:
          if (!ipAdressToCreate) {
            setAlert('Please enter an IP Address', 'error');
            return;
          }
          createGatewaySettingsIp(gateways[selectedGatewayIndex]?.id, ipAdressToCreate);
          break;
        case SettingsItem.HostOrigin:
          if (!hostOriginToCreate) {
            setAlert('Please enter a Host URL', 'error');
            return;
          }
          createGatewaySettingsHost(gateways[selectedGatewayIndex]?.id, hostOriginToCreate);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsActionModalOpen(false);
    }
  }

  const ipAddresses = useMemo(() => {
    return gateways[selectedGatewayIndex]?.gatewaySettings?.allowedIPAddresses.filter((el: any) =>
      ipAddressToSearch ? el.value.includes(ipAddressToSearch.toLocaleLowerCase()) : el
    );
  }, [gateways[selectedGatewayIndex]?.gatewaySettings?.allowedIPAddresses?.length, ipAddressToSearch]);

  const hostOrigins = useMemo(() => {
    return gateways[selectedGatewayIndex]?.gatewaySettings?.allowedHosts.filter((el: any) =>
      hostOriginToSearch ? el.value.includes(hostOriginToSearch.toLocaleLowerCase()) : el
    );
  }, [gateways[selectedGatewayIndex]?.gatewaySettings?.allowedHosts?.length, hostOriginToSearch]);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      { !isMobile &&
        <Typography variant="h3" sx={{mb: 2}}>Access Controls</Typography>
      }
      {gateways?.length ? (
        <>
          <Typography variant="h6" sx={{ ml: 2 }}>
            Gateways
          </Typography>
          <Grid
            container
            direction={{
              xs: 'column',
              sm: 'column',
              md: 'row'
            }}
            wrap="nowrap"
          >
            <Grid item xs={12} sm={2}>
              <List sx={{ p: 0 }}>
                {gateways.map((gateway, index) => (
                  <ListItem
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    key={gateway.id}
                    selected={gateway.id === gateways[selectedGatewayIndex]?.id}
                    onClick={() => {
                      setSelectedGatewayIndex(index);
                    }}
                  >
                    <ListItemText sx={{ overflowWrap: 'break-word' }} primary={`${gateway.domain}${ROOT_DOMAIN}`} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={10}>
              <>
                <GatewaySettingsItem
                  itemTitle={`Access Tokens ${
                    gateways[selectedGatewayIndex]?.gatewaySettings?.accessTokens?.length > 0
                      ? `(${gateways[selectedGatewayIndex]?.gatewaySettings?.accessTokens?.length})`
                      : ''
                  }`}
                  label={SettingsItem.AccessTokens}
                  actionTitle="Request Token"
                  itemAction={handleAddAction}
                  isActionDisabled={
                    gateways[selectedGatewayIndex]?.gatewaySettings?.accessTokens?.length >= 2 ||
                    (gateways[selectedGatewayIndex]?.gatewaySettings?.allowedHosts?.length > 0 &&
                      !gatewayAllowMultipleControls)
                  }
                  tooltipText={'Token-based authentication that allows access to API'}
                >
                  <>
                    {gateways[selectedGatewayIndex]?.gatewaySettings?.accessTokens?.length > 0 ? (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {gateways[selectedGatewayIndex]?.gatewaySettings?.accessTokens?.map(
                          (token: { id: string; value: string; createdAt: string }, index: number) => (
                            <AccessTokenCard
                              key={token.id}
                              removeTokenAction={(tokenId: string) =>
                                removeGatewaysSettingsToken(gateways[selectedGatewayIndex]?.id, tokenId)
                              }
                              selectedGateway={gateways[selectedGatewayIndex]}
                              token={{ ...token, name: `Token-${index + 1}` }}
                            />
                          )
                        )}
                      </Box>
                    ) : (
                      <NoContent title="Access Tokens" />
                    )}
                  </>
                </GatewaySettingsItem>
                <GatewaySettingsItem
                  itemTitle={`IP Addresses ${
                    gateways[selectedGatewayIndex]?.gatewaySettings?.allowedIPAddresses?.length > 0
                      ? `(${gateways[selectedGatewayIndex]?.gatewaySettings?.allowedIPAddresses?.length})`
                      : ''
                  }`}
                  label={SettingsItem.IpAdresses}
                  actionTitle="Set IP Address"
                  itemAction={handleAddAction}
                  isActionDisabled={
                    gateways[selectedGatewayIndex]?.gatewaySettings?.allowedHosts?.length > 0 &&
                    !gatewayAllowMultipleControls
                  }
                  additionalComponent={
                    <TextField
                      fullWidth
                      sx={{
                        '& > .MuiInputBase-root': {
                          m: 0
                        }
                      }}
                      label="Search IP"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setIpAdressToSearch(event.target.value);
                      }}
                      value={ipAddressToSearch}
                      placeholder="192.168.0.1"
                    />
                  }
                  tooltipText={'Supports both IPv4 and IPv6'}
                >
                  {gateways[selectedGatewayIndex]?.gatewaySettings?.allowedIPAddresses?.length > 0 ? (
                    <GatewaySettingsDataTable
                      label="IP Address"
                      removeAction={(ipAddressId: string) =>
                        removeGatewaySettingsIp(gateways[selectedGatewayIndex]?.id, ipAddressId)
                      }
                      rowsData={['IP Address', 'Date issued']}
                      content={ipAddresses}
                    />
                  ) : (
                    <NoContent title="IP Addresses" />
                  )}
                </GatewaySettingsItem>
                <GatewaySettingsItem
                  itemTitle={`Host Origins ${
                    gateways[selectedGatewayIndex]?.gatewaySettings?.allowedHosts?.length > 0
                      ? `(${gateways[selectedGatewayIndex]?.gatewaySettings?.allowedHosts?.length})`
                      : ''
                  }`}
                  label={SettingsItem.HostOrigin}
                  actionTitle="Add Host Origin"
                  itemAction={handleAddAction}
                  isActionDisabled={
                    (gateways[selectedGatewayIndex]?.gatewaySettings?.allowedIPAddresses?.length > 0 ||
                      gateways[selectedGatewayIndex]?.gatewaySettings?.accessTokens?.length > 0) &&
                    !gatewayAllowMultipleControls
                  }
                  actionTooltipText={
                    gateways[selectedGatewayIndex]?.gatewaySettings?.allowedIPAddresses?.length > 0 ||
                    gateways[selectedGatewayIndex]?.gatewaySettings?.accessTokens?.length > 0 ? (
                      <Box>
                        Host Origins cannot be used with other access tokens, please read our
                        <Link
                          style={{ color: theme.palette.primary.main }}
                          target="_blank"
                          to={{ pathname: 'https://docs.pinata.cloud/gateways/gateway-access-controls' }}
                        >
                          {' '}
                          Docs
                        </Link>
                      </Box>
                    ) : (
                      ''
                    )
                  }
                  additionalComponent={
                    <TextField
                      fullWidth
                      sx={{
                        '& > .MuiInputBase-root': {
                          m: 0
                        }
                      }}
                      label="Search Host"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setHostOriginToSearch(event.target.value);
                      }}
                      value={hostOriginToSearch}
                      placeholder="https://test.com"
                    />
                  }
                  tooltipText={'Allowed host url or origin'}
                >
                  {gateways[selectedGatewayIndex]?.gatewaySettings?.allowedHosts?.length > 0 ? (
                    <GatewaySettingsDataTable
                      label="Host Origin"
                      removeAction={(hostId: string) =>
                        removeGatewaySettingsHost(gateways[selectedGatewayIndex]?.id, hostId)
                      }
                      rowsData={['URL Name', 'Date issued']}
                      content={hostOrigins}
                    />
                  ) : (
                    <NoContent title="Host Origins" />
                  )}
                </GatewaySettingsItem>
              </>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            minHeight: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <img src={LightLogo} width="150px" />
          <Typography variant="h5">No Gateway Detected</Typography>
          <Typography component="p" sx={{ maxWidth: '250px', textAlign: 'center' }}>
            You need to create a gateway before adding restrictions
          </Typography>
          <Button color="primary" sx={{ mt: 1 }}>
            <Link to="/gateway" style={{ color: theme.palette.primary.contrastText }}>
              Create Gateway
            </Link>
          </Button>
        </Box>
      )}
      {isActionModalOpen && (
        <ConfirmationModal
          title={actionModalContent.title}
          content={actionModalContent.content}
          modalOpen={isActionModalOpen}
          toggleModal={() => {
            setIsActionModalOpen(false);
            setSelectedSettingsTab('');
          }}
          action={handleCreateAction}
          isDisabled={
            (selectedSettingsTab === SettingsItem.AccessTokens && !gateways[selectedGatewayIndex]) ||
            (selectedSettingsTab === SettingsItem.IpAdresses && !ipAdressToCreate) ||
            (selectedSettingsTab === SettingsItem.HostOrigin && !hostOriginToCreate)
          }
          loading={isLoading}
          confirmButtonText={
            selectedSettingsTab === SettingsItem.IpAdresses
              ? 'Set IP Address' :
              selectedSettingsTab === SettingsItem.HostOrigin ? 'Set Host Origin'
              : 'Yes, I am sure'
          }
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
    </>
  );
}

const mapDispatchToProps = {
  setAlert,
  createGatewaySettingsToken,
  removeGatewaysSettingsToken,
  createGatewaySettingsIp,
  removeGatewaySettingsIp,
  createGatewaySettingsHost,
  removeGatewaySettingsHost,
  getGatewayDetails,
  getAllGateways
};

export default connect(null, mapDispatchToProps)(GatewaySettings);
