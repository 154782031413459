import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PaymentInfo from './PaymentInfo';
import BillingHistory from './BillingHistory';
import CurrentPlanInfo from './CurrentPlanInfo';
import { cancelUserAccount, loadUserInfo, restoreCanceledAccount } from '../../store/user/user.actions';
import { retrieveStripeCustomer } from '../../store/billing/billing.actions';
import { getUsageMetrics } from 'store/metrics/metrics.actions';
import { BillingState, Plan } from '../../store/billing/types';
import { MetricsState } from '../../store/metrics/types';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { User, UserState } from '../../store/user/types';
import { Gateways } from '../../store/gateways/types';
import { createGateway } from '../../store/gateways/gateway.actions';

interface UsageCardProps {
  data: any;
  billing: BillingState;
  setAlert: any;
  changePlan: (newPlan: Plan) => Promise<{ plan: Plan; nextPlan: Plan }>;
  gateways: Gateways;
  cancelUserAccount: any;
  getUsageMetrics: () => void;
  metrics: MetricsState;
  user: UserState;
  apiKeys: any;
  restoreCanceledAccount: () => void;
  loadUserInfo: (user: User) => Promise<any>;
  createGateway: (gatewayInfo: { subdomain: string; restricted?: boolean }, update: boolean) => any;
  updatingPlan: boolean;
}

const UsageCard = ({ billing, updatingPlan }: UsageCardProps) => {
  const [openCardModal, setOpenCardModal] = useState(false); //Boolean or null if no default

  // Scroll to anchor hash
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll.offsetTop - 75,
        behavior: 'smooth'
      });
    };

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, []);

  return (
    <Grid
      container
      sx={{
        marginBottom: 2,
        flexWrap: { md: 'nowrap' },
        columnGap: { md: 2 }
      }}
    >
      <Grid item xs={12} md={6}>
        {updatingPlan ? (
          <div className="updating-plan-spinner">
            <Typography>We're updating your plan</Typography>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <CurrentPlanInfo billing={billing} />
        )}
      </Grid>
      <Grid item xs={12} md={6} sx={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <PaymentInfo openCardModal={openCardModal} setOpenCardModal={setOpenCardModal} />
        <BillingHistory />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  cancelUserAccount,
  getUsageMetrics,
  restoreCanceledAccount,
  loadUserInfo,
  retrieveStripeCustomer,
  createGateway
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    apiKeys: state.apiKeys
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsageCard);
