import * as Analytics from 'analytics';
import rudderstackPlugin from './rudderstack';

/* Initialize analytics & load plugins */
const analytics: any = Analytics.default({
  app: 'pinata_app',
  plugins: [
    rudderstackPlugin({
      writeKey: process.env.REACT_APP_ANALYTICS_WRITE_KEY || '',
      dataPlaneUrl: process.env.REACT_APP_DATA_PLANE_URL || ''
    })
  ]
});

export default analytics;
