import Flatpickr from 'react-flatpickr';
import {
  Switch,
  FormControlLabel,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  TextField,
  Grid,
  useTheme
} from '@mui/material';
import 'flatpickr/dist/themes/material_green.css';
import { PinataDialogTitle } from '../../components/Common/MuiComponents';

interface FilterModalProps {
  pinStatus: string;
  filterModalOpen: boolean;
  setFilterModalOpen: (isOpen: boolean) => void;
  hashContains: string;
  setHashContains: (hash: string) => void;
  nameContains: string;
  setNameContains: (name: string) => void;
  pinStartDate: Date | undefined;
  pinEndDate: Date | undefined;
  unpinStartDate: Date | undefined;
  unpinEndDate: Date | undefined;
  setUnpinStartDate: (date: Date) => void;
  setUnpinEndDate: (date: Date) => void;
  setPinStartDate: (date: Date) => void;
  setPinEndDate: (date: Date) => void;
  updateFilter: () => void;
  submarined: boolean;
  setSubmarined: (submarined: boolean) => void;
  setAlert: (message: string, type: string) => void;
}

const FilterModal = ({
  filterModalOpen,
  setFilterModalOpen,
  pinStatus,
  setHashContains,
  hashContains,
  nameContains,
  setNameContains,
  pinStartDate,
  pinEndDate,
  unpinStartDate,
  unpinEndDate,
  setPinStartDate,
  setPinEndDate,
  setUnpinStartDate,
  setUnpinEndDate,
  updateFilter,
  submarined,
  setSubmarined
}: FilterModalProps) => {
  const theme = useTheme();
  return (
    <Dialog open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
      <PinataDialogTitle onClose={() => setFilterModalOpen(false)}>Filter Pins</PinataDialogTitle>
      <DialogContent dividers>
        <p>Enter as many search criteria as you'd like, then hit Submit.</p>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={hashContains}
                onChange={(e) => setHashContains(e.target.value)}
                type="text"
                placeholder="Qm..."
                label="Search by CID"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={nameContains}
                onChange={(e) => setNameContains(e.target.value)}
                type="text"
                placeholder="file.png"
                label="Search by Name"
              />
            </FormControl>
          </Grid>
          <Grid container item flexDirection={'row'} spacing={4} xs={12}>
            <Grid item flexGrow={1} xs={6}>
              <FormControl fullWidth>
                <FormControlLabel
                  labelPlacement="top"
                  label={pinStatus === 'pinned' ? 'Pinned Start Date' : 'Unpinned Start Date'}
                  sx={{
                    '& > input': {
                      width: '100%',
                      height: '40px',
                      border: `1px solid ${theme.palette.grey['400']}`
                    }
                  }}
                  control={
                    <Flatpickr
                      onChange={(date) =>
                        pinStatus === 'pinned' ? setPinStartDate(date[0]) : setUnpinStartDate(date[0])
                      }
                      placeholder={pinStatus === 'pinned' ? 'Pinned Start Date' : 'Unpinned Start Date'}
                      value={pinStatus === 'pinned' ? pinStartDate : unpinStartDate}
                      options={{
                        clickOpens: true,
                        inline: false,
                        altInput: true,
                        altFormat: 'F j, Y',
                        dateFormat: 'mm-dd-yy',
                        maxDate: new Date()
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item flexGrow={1} xs={6}>
              <FormControl fullWidth>
                <FormControlLabel
                  labelPlacement="top"
                  label={pinStatus === 'pinned' ? 'Pinned End Date' : 'Unpinned End Date'}
                  sx={{
                    '& > input': {
                      width: '100%',
                      height: '40px',
                      border: `1px solid ${theme.palette.grey['400']}`
                    }
                  }}
                  control={
                    <Flatpickr
                      onChange={(date) => (pinStatus === 'pinned' ? setPinEndDate(date[0]) : setUnpinEndDate(date[0]))}
                      placeholder={pinStatus === 'pinned' ? 'Pinned End Date' : 'Unpinned End Date'}
                      value={pinStatus === 'pinned' ? pinEndDate : unpinEndDate}
                      options={{
                        clickOpens: true,
                        inline: false,
                        altInput: true,
                        altFormat: 'F j, Y',
                        dateFormat: 'mm-dd-yy',
                        maxDate: new Date()
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Button fullWidth onClick={updateFilter} sx={{ mt: 2 }}>
          Filter Pins
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FilterModal;
