import { useContext, useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  Container,
  MenuItem,
  Divider,
  Select,
  Switch,
  TextField,
  Typography,
  Paper,
  Grid,
  Alert,
  Stack,
  Collapse,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  FormHelperText,
  Slider,
  Backdrop,
  CircularProgress,
  LinearProgress,
  Skeleton,
  useTheme,
  Tabs,
  Tab,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  styled,
  Snackbar,
  PaletteOptions,
  PaletteMode
} from '@mui/material';
import { ThemeContext } from '../../services/globalContext';
import { PinataAlert } from '../../components/Common/VerticalAlert';
import { Close, DarkMode, ExpandMore, LightMode, Square } from '@mui/icons-material';

function TransitionAlerts() {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Collapse sx={{ flexGrow: 1 }} in={open}>
        <Alert
          sx={{
            '& .MuiAlert-icon': {
              display: 'flex',
              alignItems: 'center'
            },
            '& .MuiAlert-action': {
              p: 0
            }
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          Close me!
        </Alert>
      </Collapse>
      <Button
        sx={{ ml: 2 }}
        size="small"
        disabled={open}
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Re-open
      </Button>
    </Box>
  );
}

function SimpleSnackbar() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <Close fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Button onClick={handleClick}>Open simple snackbar</Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="Note archived" action={action} />
    </div>
  );
}

function RangeSlider() {
  const [value, setValue] = useState<number | number[]>([20, 37]);

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue);
  };

  function valuetext(value: number) {
    return `${value}°C`;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Typography sx={{ mr: 2 }}>Range</Typography>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}

function SimpleBackdrop() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Button onClick={handleToggle}>Show backdrop</Button>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function BasicTabs({ tabsCount = 5, ...rest }: { tabsCount?: number; [key: string]: any }) {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabsCountArray = Array.from({ length: tabsCount }, (_, index) => index + 1);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs {...rest} value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabsCountArray?.map((el, index) => (
            <Tab key={`item-${index}`} label={`Item ${el}`} />
          ))}
        </Tabs>
      </Box>
      <TabPanel key={`panel-${value}`} value={value} index={value}>
        {`Item ${value}`}
      </TabPanel>
    </Box>
  );
}

function ColorTabs({
  textColor,
  indicatorColor
}: {
  textColor: 'primary' | 'secondary';
  indicatorColor: 'primary' | 'secondary';
}) {
  const [value, setValue] = useState('one');

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        textColor={textColor}
        indicatorColor={indicatorColor}
        aria-label="secondary tabs example"
      >
        <Tab value="one" label="Item One" />
        <Tab value="two" label="Item Two" />
        <Tab value="three" label="Item Three" />
      </Tabs>
    </Box>
  );
}

function HorizontalLabelPositionBelowStepper() {
  const steps = ['Fill data', 'Upload file', 'Confirm'];

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={1} alternativeLabel>
        {steps?.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

function AlertDialog() {
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no
            apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Disagree
          </Button>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function FormDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CustomizedDialogs() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget
            quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet
            rutrum faucibus dolor auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl
            consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

type PartialPalleteOptions = { [P in keyof PaletteOptions]: PaletteOptions[P] };
const updateColorsInitialState: PartialPalleteOptions = {
  primary: {
    main: ''
  },
  info: {
    main: ''
  },
  warning: {
    main: ''
  },
  error: {
    main: ''
  },
  success: {
    main: ''
  }
};

function themeUpdatesReducer(state: PartialPalleteOptions, action: any): any {
  switch (action.type) {
    case 'primary': {
      return {
        ...state,
        primary: {
          main: action.payload
        }
      };
    }
    case 'info': {
      return {
        ...state,
        info: {
          main: action.payload
        }
      };
    }
    case 'warning': {
      return {
        ...state,
        warning: {
          main: action.payload
        }
      };
    }
    case 'error': {
      return {
        ...state,
        error: {
          main: action.payload
        }
      };
    }
    case 'success': {
      return {
        ...state,
        success: {
          main: action.payload
        }
      };
    }
    default:
      throw new Error();
  }
}

function CustomiseThemeColors() {
  const [colorsState, dispatch] = useReducer(themeUpdatesReducer, updateColorsInitialState);
  const { changeTheme } = useContext(ThemeContext);
  const theme = useTheme();

  const colors = [...Object.keys(updateColorsInitialState)];

  return (
    <Grid item container spacing={2}>
      <Grid container spacing={2}>
        {colors.map((el, index) => (
          <Grid key={index} item>
            <Paper
              sx={{
                my: 1,
                p: 1,
                minHeight: 200,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', my: 1 }}>
                <Box>
                  <Typography sx={{ fontSize: '0.8rem' }} textAlign="center">
                    Main
                  </Typography>
                  <Square
                    sx={{
                      // @ts-ignore
                      color: theme?.palette?.[el]?.main,
                      boxShadow: theme.shadows[5],
                      fontSize: '7.5rem',
                      mb: 2
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '0.5rem' }}>Light shape</Typography>
                    <Square
                      sx={{
                        // @ts-ignore
                        color: theme?.palette?.[el]?.light,
                        boxShadow: theme.shadows[5],
                        fontSize: '3rem'
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '0.5rem' }}>Dark shape</Typography>
                    <Square
                      sx={{
                        // @ts-ignore
                        color: theme?.palette?.[el]?.dark,
                        boxShadow: theme.shadows[5],
                        fontSize: '3rem'
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Typography variant="caption">
                {/* @ts-ignore */}
                Current {el}: {theme?.palette?.[el]?.main}
              </Typography>
              <TextField name={el} onChange={(e) => dispatch({ type: el, payload: e.target.value })} />
              <Button
                sx={{ my: 2 }}
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => {
                  changeTheme({
                    ...theme,
                    palette: {
                      ...theme.palette,
                      [el]: {
                        main: colorsState[el].main.startsWith('#') ? colorsState[el].main : `#${colorsState[el].main}`
                      }
                    }
                  });
                }}
              >
                Apply {el} color
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

function Components() {
  const themeContext = useContext(ThemeContext);
  const theme = useTheme();

  const handleToggleTheme = () => {
    themeContext.setThemeName(themeContext.themeName === 'dark' ? 'light' : 'dark');
  };

  return (
    <Container sx={{ '& > *': { marginBottom: 1 } }}>
      <Divider>Switch theme</Divider>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h5" textAlign="center">
            Toggle Theme
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {theme.palette.mode === 'light' ? <LightMode fontSize="large" /> : <DarkMode fontSize="large" />}
            <Switch onChange={handleToggleTheme} />
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="h5" textAlign="center" sx={{ my: 2 }}>
            Change theme colors
          </Typography>
          <CustomiseThemeColors />
        </Grid>
      </Grid>
      <Divider>Typography</Divider>
      <Box sx={{ width: '100%', maxWidth: 500, my: 2 }}>
        <Typography variant="h1" gutterBottom>
          h1. Heading
        </Typography>
        <Typography variant="h2" gutterBottom>
          h2. Heading
        </Typography>
        <Typography variant="h3" gutterBottom>
          h3. Heading
        </Typography>
        <Typography variant="h4" gutterBottom>
          h5. Heading
        </Typography>
        <Typography variant="h5" gutterBottom>
          h5. Heading
        </Typography>
        <Typography variant="h6" gutterBottom>
          h6. Heading
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
        </Typography>
        <Typography variant="body1" gutterBottom>
          body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
          beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti?
          Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="body2" gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam
          beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti?
          Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          button text
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          caption text
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          overline text
        </Typography>
      </Box>
      <Divider>Buttons</Divider>
      <Grid sx={{ '& .MuiButton-root': { m: 1 } }} container>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Primary
            </Typography>
            <Paper>
              <Box
                sx={{
                  py: 2,
                  '& .MuiBox-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Button size="large" color="primary">
                    Large
                  </Button>
                  <Button size="medium" color="primary">
                    Medium
                  </Button>
                  <Button size="small" color="primary">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" disabled color="primary">
                    Large
                  </Button>
                  <Button size="medium" disabled color="primary">
                    Medium
                  </Button>
                  <Button size="small" disabled color="primary">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="primary" variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="primary" variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="primary" variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="primary" disabled variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="primary" disabled variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="primary" disabled variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="primary" variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="primary" variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="primary" variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="primary" disabled variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="primary" disabled variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="primary" disabled variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="primary" variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="primary" variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="primary" variant="link">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="primary" disabled variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="primary" disabled variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="primary" disabled variant="link">
                    Small
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Secondary
            </Typography>
            <Paper>
              <Box
                sx={{
                  py: 2,
                  '& .MuiBox-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Button size="large" color="secondary">
                    Large
                  </Button>
                  <Button size="medium" color="secondary">
                    Medium
                  </Button>
                  <Button size="small" color="secondary">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" disabled color="secondary">
                    Large
                  </Button>
                  <Button size="medium" disabled color="secondary">
                    Medium
                  </Button>
                  <Button size="small" disabled color="secondary">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="secondary" variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="secondary" variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="secondary" variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="secondary" disabled variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="secondary" disabled variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="secondary" disabled variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="secondary" variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="secondary" variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="secondary" variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="secondary" disabled variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="secondary" disabled variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="secondary" disabled variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="secondary" variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="secondary" variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="secondary" variant="link">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="secondary" disabled variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="secondary" disabled variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="secondary" disabled variant="link">
                    Small
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Info
            </Typography>
            <Paper>
              <Box
                sx={{
                  py: 2,
                  '& .MuiBox-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Button size="large" color="info">
                    Large
                  </Button>
                  <Button size="medium" color="info">
                    Medium
                  </Button>
                  <Button size="small" color="info">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" disabled color="info">
                    Large
                  </Button>
                  <Button size="medium" disabled color="info">
                    Medium
                  </Button>
                  <Button size="small" disabled color="info">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="info" variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="info" variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="info" variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="info" disabled variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="info" disabled variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="info" disabled variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="info" variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="info" variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="info" variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="info" disabled variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="info" disabled variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="info" disabled variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="info" variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="info" variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="info" variant="link">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="info" disabled variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="info" disabled variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="info" disabled variant="link">
                    Small
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Success
            </Typography>
            <Paper>
              <Box
                sx={{
                  py: 2,
                  '& .MuiBox-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Button size="large" color="success">
                    Large
                  </Button>
                  <Button size="medium" color="success">
                    Medium
                  </Button>
                  <Button size="small" color="success">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" disabled color="success">
                    Large
                  </Button>
                  <Button size="medium" disabled color="success">
                    Medium
                  </Button>
                  <Button size="small" disabled color="success">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="success" variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="success" variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="success" variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="success" disabled variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="success" disabled variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="success" disabled variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="success" variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="success" variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="success" variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="success" disabled variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="success" disabled variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="success" disabled variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="success" variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="success" variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="success" variant="link">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="success" disabled variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="success" disabled variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="success" disabled variant="link">
                    Small
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Error
            </Typography>
            <Paper>
              <Box
                sx={{
                  py: 2,
                  '& .MuiBox-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Button size="large" color="error">
                    Large
                  </Button>
                  <Button size="medium" color="error">
                    Medium
                  </Button>
                  <Button size="small" color="error">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" disabled color="error">
                    Large
                  </Button>
                  <Button size="medium" disabled color="error">
                    Medium
                  </Button>
                  <Button size="small" disabled color="error">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="error" variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="error" variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="error" variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="error" disabled variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="error" disabled variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="error" disabled variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="error" variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="error" variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="error" variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="error" disabled variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="error" disabled variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="error" disabled variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="error" variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="error" variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="error" variant="link">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="error" disabled variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="error" disabled variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="error" disabled variant="link">
                    Small
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Warning
            </Typography>
            <Paper>
              <Box
                sx={{
                  py: 2,
                  '& .MuiBox-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Button size="large" color="warning">
                    Large
                  </Button>
                  <Button size="medium" color="warning">
                    Medium
                  </Button>
                  <Button size="small" color="warning">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" disabled color="warning">
                    Large
                  </Button>
                  <Button size="medium" disabled color="warning">
                    Medium
                  </Button>
                  <Button size="small" disabled color="warning">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="warning" variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="warning" variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="warning" variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="warning" disabled variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="warning" disabled variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="warning" disabled variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="warning" variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="warning" variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="warning" variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="warning" disabled variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="warning" disabled variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="warning" disabled variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="warning" variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="warning" variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="warning" variant="link">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="warning" disabled variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="warning" disabled variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="warning" disabled variant="link">
                    Small
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Inherit
            </Typography>
            <Paper>
              <Box
                sx={{
                  py: 2,
                  '& .MuiBox-root': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Button size="large" color="inherit">
                    Large
                  </Button>
                  <Button size="medium" color="inherit">
                    Medium
                  </Button>
                  <Button size="small" color="inherit">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" disabled color="inherit">
                    Large
                  </Button>
                  <Button size="medium" disabled color="inherit">
                    Medium
                  </Button>
                  <Button size="small" disabled color="inherit">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="inherit" variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="inherit" variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="inherit" variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="inherit" disabled variant="outlined">
                    Large
                  </Button>
                  <Button size="medium" color="inherit" disabled variant="outlined">
                    Medium
                  </Button>
                  <Button size="small" color="inherit" disabled variant="outlined">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="inherit" variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="inherit" variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="inherit" variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="inherit" disabled variant="text">
                    Large
                  </Button>
                  <Button size="medium" color="inherit" disabled variant="text">
                    Medium
                  </Button>
                  <Button size="small" color="inherit" disabled variant="text">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="inherit" variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="inherit" variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="inherit" variant="link">
                    Small
                  </Button>
                </Box>
                <Box>
                  <Button size="large" color="inherit" disabled variant="link">
                    Large
                  </Button>
                  <Button size="medium" color="inherit" disabled variant="link">
                    Medium
                  </Button>
                  <Button size="small" color="inherit" disabled variant="link">
                    Small
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Divider>Alerts</Divider>
      <Grid
        container
        spacing={2}
        sx={{
          '& .MuiAlert-root': { width: '100%', padding: 0 },
          '& .MuiPaper-root': {
            p: 1
          }
        }}
      >
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Default
            </Typography>
            <Paper>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <PinataAlert
                  alert={{ alert: { type: 'info', timeOut: 0, message: 'Here is something you might like to know.' } }}
                />
                <PinataAlert
                  alert={{ alert: { type: 'success', timeOut: 0, message: 'Here is something that went well.' } }}
                />
                <PinataAlert
                  alert={{ alert: { type: 'warning', timeOut: 0, message: 'Here is something that went wrong.' } }}
                />
                <PinataAlert
                  alert={{ alert: { type: 'error', timeOut: 0, message: 'Here is something that failed.' } }}
                />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              OutLined
            </Typography>
            <Paper>
              <Stack sx={{ '& .MuiAlert-root': { width: '100%' }, width: '100%' }} spacing={2}>
                <PinataAlert
                  variant="outlined"
                  alert={{ alert: { type: 'info', timeOut: 0, message: 'Here is something you might like to know.' } }}
                />
                <PinataAlert
                  variant="outlined"
                  alert={{ alert: { type: 'success', timeOut: 0, message: 'Here is something that went well.' } }}
                />
                <PinataAlert
                  variant="outlined"
                  alert={{ alert: { type: 'warning', timeOut: 0, message: 'Here is something that went wrong.' } }}
                />
                <PinataAlert
                  variant="outlined"
                  alert={{ alert: { type: 'error', timeOut: 0, message: 'Here is something that failed.' } }}
                />
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Filled
            </Typography>
            <Paper>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <PinataAlert
                  variant="filled"
                  alert={{ alert: { type: 'info', timeOut: 0, message: 'Here is something you might like to know.' } }}
                />
                <PinataAlert
                  variant="filled"
                  alert={{ alert: { type: 'success', timeOut: 0, message: 'Here is something that went well.' } }}
                />
                <PinataAlert
                  variant="filled"
                  alert={{ alert: { type: 'warning', timeOut: 0, message: 'Here is something that went wrong.' } }}
                />
                <PinataAlert
                  variant="filled"
                  alert={{ alert: { type: 'error', timeOut: 0, message: 'Here is something that failed.' } }}
                />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Other
            </Typography>
            <Paper>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <TransitionAlerts />
                <SimpleSnackbar />
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Divider>Inputs</Divider>
      <Grid container direction="column" sx={{ my: 2 }} spacing={2}>
        <Grid item>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content">
              <Typography textAlign="center">Outlined</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <TextField placeholder="Input" />
                <TextField placeholder="Disabled Input" disabled />
                <TextField placeholder="Error" error />
                <TextField placeholder="Error with helper text" error helperText={'Something went wrong'} />
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={''}
                    label="Test label"
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText>With label + helper text</FormHelperText>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select value={''} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText>Without label</FormHelperText>
                </FormControl>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content">
              <Typography textAlign="center">Filled</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <TextField placeholder="Input" variant="filled" />
                <TextField placeholder="Disabled Input" disabled variant="filled" />
                <TextField placeholder="Error" error variant="filled" />
                <TextField
                  placeholder="Error with helper text"
                  error
                  helperText={'Something went wrong'}
                  variant="filled"
                />
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    variant="filled"
                    value={''}
                    label="Test label"
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText>With label + helper text</FormHelperText>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select variant="filled" value={''} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText>Without label</FormHelperText>
                </FormControl>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel3a-content">
              <Typography textAlign="center">Standart</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <TextField placeholder="Input" variant="standard" />
                <TextField placeholder="Disabled Input" disabled variant="standard" />
                <TextField placeholder="Error" error variant="standard" />
                <TextField
                  placeholder="Error with helper text"
                  error
                  helperText={'Something went wrong'}
                  variant="standard"
                />
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">Age</InputLabel>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={''}
                    label="Test label"
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText>With label + helper text</FormHelperText>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select variant="standard" value={''} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <FormHelperText>Without label</FormHelperText>
                </FormControl>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Divider>Sliders</Divider>
      <Grid container direction="column" sx={{ my: 2 }}>
        <Grid item>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content">
              <Typography textAlign="center">Sliders</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={1}>
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ mr: 2 }}>Small</Typography>
                  <Slider size="small" valueLabelDisplay="auto" />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ mr: 2 }}>Medium</Typography>
                  <Slider size="medium" valueLabelDisplay="auto" />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ mr: 2 }}>Discrete</Typography>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={30}
                    getAriaValueText={(value: number) => `${value}°C`}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={10}
                    max={110}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ mr: 2 }}>Disabled</Typography>
                  <Slider defaultValue={30} step={10} marks min={10} max={110} disabled />
                </Box>
                <RangeSlider />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Divider>Loaders</Divider>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Backdrop
            </Typography>
            <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150 }}>
              <SimpleBackdrop />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Spinners
            </Typography>
            <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150 }}>
              <Stack spacing={2} direction="row">
                <CircularProgress color="secondary" />
                <CircularProgress color="success" />
                <CircularProgress color="inherit" />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Linear
            </Typography>
            <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150 }}>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <LinearProgress color="secondary" />
                <LinearProgress color="success" />
                <LinearProgress color="inherit" />
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Divider>Skeleton</Divider>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Text
            </Typography>
            <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, p: 1 }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Circular
            </Typography>
            <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, p: 1 }}>
              <Skeleton width={40} height={40} variant="circular" />
            </Paper>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Rectangular
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, px: 1 }}>
                <Skeleton sx={{ width: '100%' }} height={40} variant="rectangular" />
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Rounded
            </Typography>
            <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, px: 1 }}>
              <Skeleton sx={{ width: '100%' }} height={40} variant="rounded" />
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
              Example
            </Typography>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 150,
                width: '100%',
                p: 1
              }}
            >
              <Stack
                spacing={1}
                sx={{
                  width: '100%',
                  '& .MuiSkeleton-root': {
                    width: '100%'
                  }
                }}
              >
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />

                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" height={60} />
                <Skeleton variant="rounded" height={80} />
              </Stack>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Divider>Paper</Divider>
      <Grid container spacing={6} sx={{ my: 2 }}>
        {theme.shadows.map((_, index) => (
          <Grid item xs={3} key={index}>
            <Paper
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, minWidth: 150 }}
              elevation={index}
            >
              <Typography variant="body1">{index + 1}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Divider>Tabs</Divider>
      <Grid container display="flex" flexDirection="column" sx={{ my: 2 }} spacing={2}>
        <Grid item>
          <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
            Default
          </Typography>
          <Paper>
            <BasicTabs />
          </Paper>
        </Grid>
        <Grid item display="flex" flexDirection="column">
          <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
            Colored
          </Typography>
          <Paper>
            <Stack spacing={2}>
              <ColorTabs textColor="primary" indicatorColor="secondary" />
              <ColorTabs textColor="secondary" indicatorColor="primary" />
              <ColorTabs textColor="primary" indicatorColor="primary" />
              <ColorTabs textColor="secondary" indicatorColor="secondary" />
            </Stack>
          </Paper>
        </Grid>
        <Grid item display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
            Scrolable
          </Typography>
          <Paper sx={{ maxWidth: { xs: 320, sm: 650 } }}>
            <BasicTabs tabsCount={15} variant="scrollable" scrollButtons="auto" />
          </Paper>
        </Grid>
      </Grid>
      <Divider>Stepper</Divider>
      <Grid container display="flex" flexDirection="column" sx={{ my: 2 }} spacing={2}>
        <Grid item>
          <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
            Visual Stepper (without actions)
          </Typography>
          <Paper>
            <HorizontalLabelPositionBelowStepper />
          </Paper>
        </Grid>
      </Grid>
      <Divider>Dialog</Divider>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
            Simple Dialog
          </Typography>
          <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, p: 1 }}>
            <AlertDialog />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
            Form Dialog
          </Typography>
          <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, p: 1 }}>
            <FormDialog />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ my: 1 }} variant="h5" textAlign="center">
            Customized Dialog
          </Typography>
          <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 150, p: 1 }}>
            <CustomizedDialogs />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Components;
