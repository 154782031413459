import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { neutral } from '../../themes/colors';

interface Props {
  brandComponent: React.ReactElement;
  menuComponent: React.ReactElement;
  children?: React.ReactNode | React.ReactNode[];
}

// PrimaryNav is always a column, where we have brandLogo first, then Menu section, then other children

function PrimaryNavigation({ children, brandComponent, menuComponent }: Props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        px: theme.spacing(2.25),
        pt: theme.spacing(1.25),
        pb: theme.spacing(2.25),
        backgroundColor: theme.palette.mode === 'dark' ? neutral[900] : neutral[100]
      }}
    >
      {brandComponent}
      {menuComponent}
      {children}
    </Box>
  );
}

export default PrimaryNavigation;
