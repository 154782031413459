import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { neutral } from '../../../themes/colors';
import BasicDatePicker from './RangeDatePicker';
import { GatewayRow } from 'store/gateways/types';
import { useEffect } from 'react';
import { useGetGateways } from 'hooks';

type Props = {
  setDateStartFilter: any;
  setDateEndFilter: any;
  dateStartFilter: Date;
  dateEndFilter: Date;
  gatewayFilter: string;
  setGatewayFilter: any;
  setGatewaysList: any;
}

function Filters(
  {
    setDateStartFilter,
    setDateEndFilter,
    dateStartFilter,
    dateEndFilter,
    setGatewayFilter,
    gatewayFilter,
    setGatewaysList
  }: Props
) {
  const theme = useTheme();

  const { isLoadingGetGateways, errorGetGateways, dataGetGateways } = useGetGateways();


  const gateways = dataGetGateways?.data?.items?.rows;

  const handleGatewayChange = ({target: {value}}: SelectChangeEvent<string>) => {
    setGatewayFilter(value);
  };

  useEffect(() => {
    if(gateways)
      setGatewayFilter(`${gateways[0]?.domain}.${process.env.REACT_APP_PINATA_GATEWAY_BASE}`);
      setGatewaysList(gateways);      
  }, [gateways])
  
  if (isLoadingGetGateways) return <></>
  return (
    <Box>
      <Grid container spacing={3} justifyContent='flex-start'>
        {
          gateways && (
            <Grid item>
              <FormControl>
                <InputLabel id="gateway-filter">Gateway</InputLabel>
                <Select
                  size="small"
                  labelId="gateway-filter"
                  onChange={handleGatewayChange}
                  value={gatewayFilter}
                  label="Gateways"
                  sx={{ backgroundColor: theme.palette.mode === 'light' ? neutral[50] : '' }}
                >
                  {
                    gateways.map((gateway: GatewayRow) => 
                      <MenuItem
                        key={gateway.id}
                        value={`${gateway.domain}.${process.env.REACT_APP_PINATA_GATEWAY_BASE}`}
                      >
                        {`${gateway.domain}.${process.env.REACT_APP_PINATA_GATEWAY_BASE}`}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>          
            </Grid>
          )
        }
        <Grid item>
          <BasicDatePicker
            setDateStartFilter={setDateStartFilter}
            setDateEndFilter={setDateEndFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Filters;
