import React from "react";
import {useTheme} from "@mui/material";

function SubmarineIcon() {
    const theme = useTheme();
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
          <g stroke={theme.palette.primary.main} opacity="1">
              <path
                fill={theme.palette.primary.main}
                strokeWidth="1.5"
                d="M4 14.875H2.718v-1.437a.36.36 0 00-.359-.359.36.36 0 00-.359.36v3.59a.36.36 0 00.36.359.36.36 0 00.358-.359v-1.436H4v-.718z"
              ></path>
              <path
                strokeWidth="1.5"
                d="M10 11.286c0-1.188 1.25-2.438 2.438-2.438h2.873c1.188 0 2.154.966 2.154 2.154M8.25 11.838h0-.008a3.388 3.388 0 00-2.255 1.021c-.69.708-.765 1.688-.765 2.372 0 .685.075 1.667.766 2.375.583.597 1.38.974 2.253 1.02h.009l10.71.438c1.867.095 3.79-1.377 3.79-3.396v-.88a3.4 3.4 0 00-3.573-3.388l-9.351.375-1.576.063z"
              ></path>
              <path
                fill="#fff"
                d="M18 15.156a.5.5 0 11-1 0 .5.5 0 011 0zM14.667 15.156a.5.5 0 11-1 0 .5.5 0 011 0z"
              ></path>
              <path
                fill={theme.palette.primary.main}
                d="M13.167 7.154C13.167 5 14.133 5 15.321 5h.937a.5.5 0 010 1H15c-.5 0-.5.204-.5.5v1.372h-1.333v-.718z"
              ></path>
              <path fill="#fff" d="M11.077 15.156a.5.5 0 11-1 0 .5.5 0 011 0z"></path>
          </g>
      </svg>
    );
}

export default SubmarineIcon;