import { useContext } from 'react';
import { Switch, Typography, Box, Paper } from '@mui/material';
import { ThemeContext } from '../../services/globalContext';
import { useAnalytics } from 'use-analytics';
import { ANALYTICS } from 'common/constants';

interface AccountUISettingsProps {}

export const AccountUISettingsCard = (props: AccountUISettingsProps) => {
  const themeContext = useContext(ThemeContext);
  const { track } = useAnalytics();

  const handleToggleTheme = () => {
    const nextThemeName = themeContext.themeName === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', nextThemeName);
    track(ANALYTICS.UI.THEME, {
      newTheme: nextThemeName
    });
    themeContext.setThemeName(nextThemeName);
  };

  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant={'h4'}>Theme Options</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex', my: 1, alignItems: 'center' }}>
          Light
          <Switch checked={themeContext.themeName === 'dark'} onChange={handleToggleTheme} />
          Dark
        </Box>
      </Box>
    </Paper>
  );
};
