import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import analytics from '../services/googleAnalytics';

export default function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      analytics.init();
    }
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    // every time the path changes, send a pageview event
    analytics.sendPageview(currentPath);
  }, [location]);
}
