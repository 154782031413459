import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store';
import analytics from './analytics';
import { AnalyticsProvider } from 'use-analytics';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

import './pinataCustomStyles.css';

Sentry.init({
  dsn: 'https://ae3bf52394614b08803ad2d64be94c0a@o179825.ingest.sentry.io/6115620',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate:
    (process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE && parseInt(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE, 10)) ||
    0.1,
  environment: process.env.NODE_ENV || 'development'
});

const app = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AnalyticsProvider instance={analytics}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AnalyticsProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
