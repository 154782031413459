import { useState } from 'react';
import { connect } from 'react-redux';
import {Box, Button, Menu, MenuItem, useTheme, useMediaQuery, Toolbar, Typography} from '@mui/material';

import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import { HelpOutline, StarOutlineRounded, AutoStoriesOutlined, SchoolOutlined, ExpandMore } from '@mui/icons-material';
import UpgradePlanButton from 'components/Common/UpgradePlanButton';
import { Plan } from 'store/billing/types';
import { PinataState } from 'store/types';
import { planTypes } from 'helpers/enums';
import { removeAlert } from 'store/alert/alert.actions';
import { AlertState } from 'store/alert/types';
import { PinataAlert } from 'components/Common/VerticalAlert';
import pinnieIcon from "../../assets/images/Pinnie.svg";
import {neutral} from "../../themes/colors";
import Drawer from "@mui/material/Drawer";
import pinataSub from "../../assets/images/Submarine.svg";

interface HeaderProps {
  currentPlan?: Plan | null;
  alert: AlertState;
  removeAlert: () => void;
}

const Header = ({ currentPlan, alert, removeAlert }: HeaderProps) => {
  const [helpMenuOpen, setHelpMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const moreThanMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <>
        {alert?.alert && (
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <PinataAlert
              sx={{
                position: 'fixed'
              }}
              variant="filled"
              removeAlert={removeAlert}
              alert={alert}
            />
          </Box>
        )}
      </>
      <Toolbar
        disableGutters
        sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', height: theme.spacing(9) }}
      >
        <Box
          onClick={() => setDrawerOpen(!drawerOpen)}
          sx={{border: '2px solid', padding: 0.5, borderRadius: theme.spacing(0.8), minWidth: '20px',
            marginBottom: 1, borderColor: 'white', backgroundColor: theme.palette.mode === 'dark' ? neutral[900] : 'black',
            cursor: 'pointer', width: '40px', height: '40px', marginRight: 'auto'
          }}
        >
          <img src={pinnieIcon} alt="submarine" style={{width: '100%', height: '100%'}}/>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {moreThanMediumScreen &&
            currentPlan?.type !== planTypes.ENTERPRISE.type &&
            currentPlan?.type !== planTypes.CARNIVAL.type && <UpgradePlanButton />}
          <Button
            onClick={(evt) => {
              setHelpMenuOpen(true);
              setAnchorEl(evt.currentTarget);
            }}
            variant={'text'}
            sx={{ color: 'gray' }}
          >
            <HelpOutline />
            <ExpandMore fontSize={'small'} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={helpMenuOpen}
            onClose={() => setHelpMenuOpen(false)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => window.open('https://medium.com/pinata', '_blank')}>
              <SchoolOutlined sx={{ marginRight: 2 }} fontSize={'medium'} /> Tutorials
            </MenuItem>
            <MenuItem onClick={() => window.open('https://pinata.canny.io/feature-requests', '_blank')}>
              <StarOutlineRounded sx={{ marginRight: 2 }} fontSize={'medium'} /> Feature Request
            </MenuItem>
            <MenuItem onClick={() => window.open('https://docs.pinata.cloud', '_blank')}>
              <AutoStoriesOutlined sx={{ marginRight: 2 }} fontSize={'small'} />
              Documentation
            </MenuItem>
          </Menu>
          <ProfileMenu />
        </Box>
      </Toolbar>
      <Drawer
        anchor={'left'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{p: 2, width: 200, height: '100%', backgroundColor: theme.palette.mode === 'dark' ? 'none' : neutral[200]}}>
          <Typography variant={'h4'} sx={{mb: 2}}>Apps</Typography>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 2, mb: 2}}>
            <Box
              onClick={() => {window.location.replace(`${process.env.REACT_APP_SUBMARINE_ORIGIN || ''}?signinRedirect=false`)}}
              sx={{padding: 0.5, borderRadius: theme.spacing(0.8), minWidth: '20px',
                backgroundColor: theme.palette.mode === 'dark' ? neutral[900] : 'black',
                cursor: 'pointer', width: '40px', height: '40px'
              }}
            >
              <img src={pinataSub} alt="submarine" style={{width: '100%', height: '100%'}}/>
            </Box>
            <Typography>Submarine</Typography>
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
            <Box
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{border: '2px solid', padding: 0.5, borderRadius: theme.spacing(0.8), minWidth: '20px',
                marginBottom: 1, borderColor: 'white', backgroundColor: theme.palette.mode === 'dark' ? neutral[900] : 'black',
                cursor: 'pointer', width: '40px', height: '40px'
              }}
            >
              <img src={pinnieIcon} alt="submarine" style={{width: '100%', height: '100%'}}/>
            </Box>
            <Typography>Pinata</Typography>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    currentPlan: state?.billing?.activePricingPlan,
    alert: state?.alert
  }
};

export default connect(mapStateToProps, { removeAlert })(Header);
