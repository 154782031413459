import { FC } from 'react';
import BillingAddressForm from './BillingAddressForm';
import {Dialog, DialogContent} from "@mui/material";
import {PinataDialogTitle} from "../../components/Common/MuiComponents";

type Props = {
  modalIsOpen: boolean;
  setModalOpen: any;
  mainActionHandler?: any;
};

const BillingAddressModal: FC<Props> = ({ modalIsOpen, setModalOpen, mainActionHandler = () => {} }) => {
  return (
    <Dialog open={modalIsOpen} onClose={() => setModalOpen(false)}>
      <PinataDialogTitle onClose={() => setModalOpen(false)}>
        Billing Address
      </PinataDialogTitle>
      <DialogContent dividers>
        <BillingAddressForm
          setModalOpen={setModalOpen}
          mainActionHandler={mainActionHandler}
        />
      </DialogContent>
    </Dialog>
  );
};

export default BillingAddressModal;
