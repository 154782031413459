import {useQuery} from "@tanstack/react-query";
import dayjs from "dayjs";
import { api } from "services/api";
import { metrics, metricsBy } from './../../pages/Analytics/analyticsTypes'

const QUERY_KEYS = ['analytics', 'gateway'];

type Params = {
  gateway: string;
  metric: metrics;
  by: metricsBy;
  startDate: Date;
  endDate: Date;
  top: number;
};

export const useGetAnalyticsGateway = ({
  gateway,
  metric,
  by,
  startDate,
  endDate,
  top
}: Params
) => {
  const {
    isLoading: isLoadingGetAnalyticsGateway,
    error: errorGetAnalyticsGateway,
    data: dataGetAnalyticsGateway
  } = useQuery({
    queryKey: [...QUERY_KEYS, gateway, metric, by, startDate, endDate],
    queryFn: () =>
    api.get(`api/v2/analytics/gateway/${gateway}/${metric}/${by}?startDate=${dayjs(startDate).toISOString()}&endDate=${dayjs(endDate).toISOString()}&top=${top}`, {
      withCredentials: false
    })
  });
  return {isLoadingGetAnalyticsGateway, errorGetAnalyticsGateway, dataGetAnalyticsGateway};
};

