import { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import ConfirmationModal from '../../Gateway/ConfirmationModal';
import { makeDatePretty } from 'helpers/pretty';

interface GatewaySettingsDataTableProps {
  content: Array<{ value: string; createdAt: string; id: string }>;
  removeAction: (id: string) => void;
  rowsData: string[];
  label: string;
}

function GatewaySettingsDataTable(props: GatewaySettingsDataTableProps) {
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedElementIndex, setSelectedElementIndex] = useState<number>(0);
  const { content, rowsData, removeAction, label } = props;

  function handleRowRemove(id: string) {
    setIsRevokeModalOpen(false);
    removeAction(id);
  }

  const isMenuOpen = Boolean(menuAnchor);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            {rowsData?.map((el) => (
              <TableCell key={el}>{el}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {content?.map((el, index) => (
            <TableRow key={index}>
              <TableCell style={{ width: 250 }}>{el.value}</TableCell>
              <TableCell style={{ width: 250 }}>{makeDatePretty(el.createdAt)}</TableCell>
              <TableCell align="right">
                <>
                  <IconButton
                    onClick={(e) => {
                      setMenuAnchor(e.currentTarget);
                      setSelectedElementIndex(index);
                    }}
                    aria-label="settings"
                  >
                    <MoreVert fontSize="small" />
                  </IconButton>
                </>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {menuAnchor && (
        <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={() => setMenuAnchor(null)}>
          <MenuItem
            dense
            onClick={() => {
              setMenuAnchor(null);
              setIsRevokeModalOpen(true);
            }}
          >
            Revoke
          </MenuItem>
        </Menu>
      )}
      {isRevokeModalOpen && (
        <ConfirmationModal
          title={`Revoke ${label}?`}
          content={
            <Typography variant="body2">
              Are you sure you want to revoke <strong>{content[selectedElementIndex]?.value}</strong>?
            </Typography>
          }
          modalOpen={isRevokeModalOpen}
          toggleModal={setIsRevokeModalOpen}
          action={() => handleRowRemove(content[selectedElementIndex]?.id)}
          loading={false}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
    </>
  );
}

export default GatewaySettingsDataTable;
