import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Button, useTheme } from '@mui/material';
import { getErrorMessage } from '../../helpers/error.helper';
import { StripeCardElement } from '@stripe/stripe-js/types/stripe-js/elements/card';

interface CheckoutFormProps {
  setAlert: (message: string, type: string) => void;
  handleAddCard: (token: string) => void;
}

const CheckoutForm = ({ setAlert, handleAddCard }: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      if (!stripe || !elements) return;
      const cardElement: StripeCardElement | null = elements?.getElement(CardElement);
      // Use your card Element with other Stripe.js APIs
      if (!cardElement) {
        setAlert('Something went wrong', 'error');
        return;
      }

      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        const message = getErrorMessage(error);
        setAlert(message, 'error');
        return;
      }

      if (token.id) {
        handleAddCard(token.id);
      } else {
        setAlert('Invalid Payment Method Submitted', 'error');
      }
    } catch (error) {
      setAlert(e.message, 'error');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              color: theme.palette.getContrastText(
                theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black
              ),
              '::placeholder': {
                color: theme.palette.grey[500]
              },
              iconColor: theme.palette.grey[500]
            }
          }
        }}
      />
      <Button type="submit" disabled={!stripe} sx={{ marginTop: 2 }}>
        Add Credit Card
      </Button>
    </Box>
  );
};

// @ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

interface PaymentMethodProps {
  handleAddCard: (token: string) => void;
  setAlert: (message: string, type: string) => void;
}
const PaymentMethod = ({ setAlert, handleAddCard }: PaymentMethodProps) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm handleAddCard={handleAddCard} setAlert={setAlert} />
  </Elements>
);

export default PaymentMethod;
