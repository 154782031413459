import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GatewaysTable from './GatewaysTable';
import TableNav from './TableNav';
import GatewayWizard from './GatewayWizard';
import BandwidthUsageChart from './BandwidthUsageChart';
import { Typography, Box, Button, Link as MuiLink, Tooltip } from '@mui/material';
import { setAlert } from '../../store/alert/alert.actions';
import {
  checkSubdomain,
  createGateway,
  getAllGateways,
  deleteGateway,
  updateGateway,
  createCustomDomain,
  getCustomDomainDNSStatus,
  deleteCustomDomain
} from '../../store/gateways/gateway.actions';
import type { BillingState } from '../../store/billing/types';
import type { GatewayRow, GatewaysState } from '../../store/gateways/types';
import type { MetricsState } from '../../store/metrics/types';
import type { PinataState } from '../../store/types';
import { planTypes } from 'helpers/enums';
import { HelpOutlineOutlined } from '@mui/icons-material';

interface GatewayProps {
  setAlert: (message: any, type: any, time?: number | undefined, close?: any) => void;
  checkSubdomain: (subdomain: string) => any;
  createGateway: (gatewayInfo: { subdomain: string; restricted: boolean }, update?: boolean) => any;
  getAllGateways: (page: number) => void;
  billing: BillingState;
  gateways: GatewaysState;
  deleteGateway: (gatewayId: string | number, update: boolean) => void;
  createCustomDomain: (gatewayId: string | number, customDomainId: string) => void;
  getCustomDomainDNSStatus: (gatewayId: string | number, customDomainId: string) => void;
  deleteCustomDomain: (gatewayId: string | number, customDomainId: string) => void;
  metrics: MetricsState;
}

const Gateway = ({
  setAlert,
  checkSubdomain,
  createGateway,
  getAllGateways,
  billing,
  gateways,
  deleteGateway,
  createCustomDomain,
  getCustomDomainDNSStatus,
  deleteCustomDomain,
  metrics
}: GatewayProps) => {
  const [existingGateways, setGateways] = useState<GatewayRow[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [wizardModalOpen, setWizardModalOpen] = useState(false);
  const gatewaysRowsPerPage = 10;

  useEffect(() => {
    getAllGateways(currentPage);
  }, [currentPage]);

  const canAccessGateways = useMemo(() => {
    return (
      (billing?.activePricingPlan && billing?.activePricingPlan?.type !== planTypes.FREE.type) ||
      billing?.activePricingPlan?.isLegacy
    );
  }, [billing]);

  return (
    <>
      {canAccessGateways ? (
        <>
          <Box sx={{ p: (theme) => theme.spacing(4.25, 0) }}>
            <Typography variant="h3">
              Gateways
              <Tooltip
                title={
                  <Typography textAlign="center">
                    Want to learn more about Pinata Gateways?{' '}
                    <MuiLink
                      href="https://knowledge.pinata.cloud/en/collections/3090434-gateways"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Check out the docs!
                    </MuiLink>
                  </Typography>
                }
              >
                <HelpOutlineOutlined fontSize="small" />
              </Tooltip>
            </Typography>
            <BandwidthUsageChart
              bandwidth={metrics?.metrics?.metricsMonthly?.transferBytes.value}
              percentage={metrics?.metrics.metricsMonthly?.transferBytes.percentage}
              limit={metrics?.metrics.metricsMonthly?.transferBytes.limit}
              currentPlan={billing?.activePricingPlan}
            />
            <TableNav
              setWizardModalOpen={setWizardModalOpen}
              metrics={metrics?.metrics}
              setAlert={setAlert}
              billing={billing}
            />
          </Box>
          <GatewaysTable
            setAlert={setAlert}
            createCustomDomain={createCustomDomain}
            deleteGateway={deleteGateway}
            totalRecords={gateways && gateways.gateways ? gateways.gateways.count : 0}
            currentPage={currentPage}
            gatewaysRowsPerPage={gatewaysRowsPerPage}
            setCurrentPage={setCurrentPage}
            getCustomDomainDNSStatus={getCustomDomainDNSStatus}
            deleteCustomDomain={deleteCustomDomain}
            metrics={metrics}
            billing={billing}
          />
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', my: 2 }}>
          <Typography variant="h5">Pinata Gateways are only available on the Picnic Plan</Typography>
          <Button sx={{ my: 2 }} to="/billing" color="primary" id="gateways-upgrade-item" component={Link}>
            Upgrade today!
          </Button>
        </Box>
      )}

      {wizardModalOpen && (
        <GatewayWizard
          checkSubdomain={checkSubdomain}
          setWizardModalOpen={setWizardModalOpen}
          wizardModalOpen={wizardModalOpen}
          createGateway={createGateway}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    gateways: state.gateways,
    billing: state.billing,
    metrics: state?.metrics,
    user: state.user
  };
};
export default connect(mapStateToProps, {
  setAlert,
  checkSubdomain,
  createGateway,
  getAllGateways,
  deleteGateway,
  updateGateway,
  createCustomDomain,
  getCustomDomainDNSStatus,
  deleteCustomDomain
})(Gateway);
