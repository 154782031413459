import { SyntheticEvent, useEffect, useState, ReactNode } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {Box, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme} from '@mui/material';
import APIKeys from '../APIKeys';
import V2APIKeys from '../V2APIKeys';
import GatewaySettings from './GatewaySettings/GatewaySettings';
import type { PinataState } from '../../store/types';
import { GatewayRow } from 'store/gateways/types';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const tabsMapping = ['api-keys', 'v2-api-keys', 'gateway-settings'];
const tabsTitles = ['API Keys', 'Submarine Keys', 'Access Controls'];

function Developers({ gateways }: { gateways: GatewayRow[] }) {
  const [tabValue, setTabValue] = useState(0);
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();

  const handleChange = (event: SyntheticEvent, newTabIndex: number) => {
    history.push(`/developers/${tabsMapping[newTabIndex]}`);
    setTabValue(newTabIndex);
  };

  useEffect(() => {
    tabsMapping.forEach((tab, index) => {
      if (pathname.includes(tab)) {
        setTabValue(index);
      }
    });
  }, [pathname]);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid container display="flex" flexDirection="column">
        {
          isMobile && <Grid item xs={12} md={6} sx={{ py: 2 }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab sx={{ flexGrow: 1 }} label="Api Keys" />
              <Tab sx={{ flexGrow: 1 }} label="Submarine Keys" />
              <Tab sx={{ flexGrow: 1 }} label="Access Controls" />
            </Tabs>
          </Grid>
        }
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <APIKeys />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <V2APIKeys />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <GatewaySettings gateways={gateways} />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state: PinataState) => {
  return {
    gateways: state.gateways.gateways.rows
  };
};

export default connect(mapStateToProps, null)(Developers);
