import { Box, FormControl, MenuItem, Select, Typography, Grid } from '@mui/material';
import { makeDatePretty } from 'helpers/pretty';
import { styled } from '@mui/material/styles';

interface UsageNavProps {
  defaultDates: { past: Date; today: Date } | null;
  range: number;
  handleDateRangeChange: (e: any) => void;
}

const NoLegendSelect = styled(Select)(({ theme }) => ({
  '& legend': {
    display: 'none'
  }
}));

const UsageNav = ({ defaultDates, handleDateRangeChange, range }: UsageNavProps) => {
  return (
    <Box>
      <Typography variant="body2">Date Range</Typography>
      {defaultDates && (
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={10}>
            <strong>
              {makeDatePretty(defaultDates.past)} - {makeDatePretty(defaultDates.today)} - Last {range > 1 && range} day
              {range > 1 && 's'}
            </strong>
          </Grid>
          <Grid xs={2} item>
            <FormControl>
              <NoLegendSelect
                labelId="usage-range-select"
                id="usage-range"
                value={range}
                onChange={handleDateRangeChange}
              >
                <MenuItem value="1">Last 24 Hours</MenuItem>
                <MenuItem value="7">Last 7 Days</MenuItem>
                <MenuItem value="14">Last 14 Days</MenuItem>
                <MenuItem value="30">Last 30 Days</MenuItem>
              </NoLegendSelect>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default UsageNav;
