import { useState, useEffect, useRef } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import ConfirmationModal from 'pages/Gateway/ConfirmationModal';
import { deleteUserProfilePicture, setUserProfilePicture } from '../../store/user/user.actions';
import { getErrorMessage } from '../../helpers/error.helper';
import { MAX_THUMBNAIL_SIZE } from '../../common/constants';
import { connect } from 'react-redux';
import { PinataState } from 'store/types';
import { UserState, User } from 'store/user/types';
import UserProfileAvatar from 'components/Common/UserProfileAvatar';
import { loadUserInfo } from '../../store/user/user.actions';


interface AccountProfilePictureProps {
  setAlert: (message: string, type: string) => void;
  user: UserState;
  loadUserInfo: any;
}

const AccountProfilePicture = ({ setAlert, user, loadUserInfo }: AccountProfilePictureProps) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const fileInput = useRef<any>(null);

  const handleSave = () => {
    setSaving(true);
    if (!thumbnail) {
      setAlert('Picture file is required', 'error');
      setSaving(false);
      return;
    }
    setUserProfilePicture({ file: thumbnail[0] }, user?.user?.sub || '')
      .then(async (res) => {
        setAlert('Profile picture has been set!', 'success');
      })
      .catch((e) => {
        setAlert(getErrorMessage(e), 'error');
        console.error(e);
      })
      .finally(async () => {
        setSaving(false);
        setEditing(false);
        await loadUserInfo(user?.user);        
      });
  };

  const deleteProfilePicture = () => {
    setSaving(true);
    deleteUserProfilePicture(user?.user?.profilePicture || '')
      .then(() => {
        setAlert('Profile picture has been deleted.', 'success');        
      })
      .catch((e) => {
        setAlert(getErrorMessage(e), 'error');
        console.error(e);
      })
      .finally(async () => {
        setSaving(false);
        setEditing(false);
        setModalOpen(false);
        await loadUserInfo(user?.user);
      });
  };

  const handleFileInput = async (e: any) => {
    setUploading(true);
    const files = e.target.files;
    if (files && files[0].size < MAX_THUMBNAIL_SIZE) {
      setThumbnail(files);
      setUploading(false);
    } else {
      setAlert('File too big', 'error');
      setUploading(false);
    }
  };

  return (
    <>
      <Paper sx={{ p: 4, flexGrow: 1 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Profile Picture
        </Typography>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: user?.user?.profilePicture ? 'center': 'baseline' }}>
          {editing ? (
            <Box sx={{mb: '1rem'}}>
              <input
                accept="image/*"
                id="thumbnail"
                ref={fileInput}
                style={{ display: 'none' }}
                type="file"
                onChange={handleFileInput}
              />
              <Box>
                <Button onClick={(e) => fileInput.current && fileInput.current?.click()} color={'info'} size="small">
                  {uploading ? 'Uploading...' : 'Upload a file'}
                </Button>

                <Typography variant={'caption'} sx={{ ml: 1 }}>
                  {thumbnail?.length > 0 && thumbnail[0].name}
                </Typography>
              </Box>
            </Box>
          ) : (
            <UserProfileAvatar user={user?.user} size={60} />
          )}
          <Box>
            {editing ? (
              <>
                <Button onClick={() => handleSave()} color="primary">
                  {saving ? 'Saving...' : 'Save'}
                </Button>&nbsp;
                {
                  !saving && (
                    <Button onClick={() => setEditing(false)} color="error">
                      Cancel
                    </Button>
                  )
                }
              </>
            ) : user?.user?.profilePicture ? (
              <Button onClick={() => setModalOpen(true)} color={'error'} variant="outlined">
                Delete profile picture
              </Button>
            ) : (
              <Button onClick={() => setEditing(true)}>Set Profile Picture</Button>
            )}
          </Box>
        </Box>
      </Paper>
      {modalOpen && (
        <ConfirmationModal
          title="Delete Profile Picture"
          content="Are you sure that you want to remove profile picture?"
          modalOpen={modalOpen}
          toggleModal={setModalOpen}
          action={deleteProfilePicture}
          cancelButtonColor="error"
          confirmButtonColor="primary"
        />
      )}
    </>
  );
};

const mapStateToProps = (state: PinataState) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {loadUserInfo})(AccountProfilePicture);
