import { Card, Grid, Typography } from '@mui/material';
import { prettySize } from 'helpers/pretty';
import { ProgressBarChart } from '../../Charts';

type Props = {
  title: string;
  currentValue: number;
  maxValue: number;
  unit: boolean;
}

const MIN_PERCENTAGE_SHOW_BAR = 2;

function TotalItem({title, currentValue, maxValue, unit}: Props) {
  
  const progressCalculation = () => {
    if (currentValue === 0 || maxValue === 0) return 0;
    let calculation = currentValue/maxValue*100;
    if (calculation < MIN_PERCENTAGE_SHOW_BAR) calculation = MIN_PERCENTAGE_SHOW_BAR;
    if(calculation > 100) {
      return 100;
    } else {
      return calculation;
    }
  };

  return (
    <Card sx={{padding: 3, boxShadow: 'none'}}>
      <Grid container spacing={2} alignItems="flex-start" direction="row">
        <Grid item xs={6} sm={4} md={6} lg={4}>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="body1">this billing cycle</Typography>
        </Grid>
        <Grid item xs={6} sm={8} md={6} lg={8}>
          <ProgressBarChart value={progressCalculation()}/>
          <Typography sx={{fontSize: 14, textAlign: 'center'}} variant="body1">
            {
              unit ? (
                `${prettySize(currentValue)} / ${prettySize(maxValue)}`
              ) : (
                `${currentValue} / ${maxValue}`
              )
            }
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default TotalItem;
